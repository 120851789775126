const GalleryContent =  [
    {
        id: 1,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1PsRYkyq9W2nKrtkJdqSPmW_rWk2laWTP',
        name: 'Sept Cohort 2021 Valedictory'
    },
    {
        id: 2,
        imgSrc:'https://drive.google.com/uc?export=view&id=1iutwzXADTbFCLFEC7fZ5BDQKfmYUMKrC' ,
        name: 'Sept Cohort 2021 Valedictory'
    },
    {
        id: 3,
        imgSrc: 'https://drive.google.com/uc?export=view&id=15OmN9LItNv8cyfNViV1J1mVuImP8Clj4',
        name: 'Sept Cohort 2021 Valedictory'
    },
    {
        id: 4,
        imgSrc: 'https://drive.google.com/uc?export=view&id=119uJmvAW_SDbWrA8KR6xgUzO6_RhoGQr',
        name: 'DSA Mentors'
    },
    {
        id: 5,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1-PHfw5Vr4fGaPLfxbXNgEilqYb5cFR2P' ,
        name: 'Sept Cohort 2021 Valedictory'
    },
    {
        id: 6,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1KBLXR_1k3e373UndC5i6MK-R-Z0rtl3e',
        name: 'IP Mentors'
    },
    {
        id: 7,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1kNW4vFhWhiOgiZUK6xotUczvWFd83qI5',
        name: 'Core Team making certis'
    },
    {
        id: 8,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1Zpt_0OaVIZVnK_itStDqdSnCEbv2g41W',
        name: 'Sept Cohort 2021 Valedictory'
    },
    {
        id: 9,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1T09nfax9eVUStxdSkSpYNS-3wfv_oDq0',
        name: 'Offline session 2020'
    },
    {
        id: 10,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1-bCMguReAdebfqiUYhxQXG8WfYDglVBj',
        name: 'Lean In Hacks Session 2020'
    },
    {
        id: 11,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1c30DdK-Wl54Q3mpZ_eeFMBuaNTIBexuh',
        name: 'Lean In Hacks 2020'
    },
    {
        id: 12,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1M587D4jMeHuSGID61A4T2pshGbZHUfjX',
        name: 'Lean In Hacks 2020'
    },
    {
        id: 13,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1IUIxSHsM7eYpYO0UqgPq4LmPTi_oaKQu',
        name: 'Lean In Hacks 2020'
    },
    {
        id: 14,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1Q--RVJBT2laOVMXpms1VcTunayZttJNs',
        name: 'Lean In Hacks 2020'
    },
    {
        id: 15,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1DqujlF0lvMlPPjdvztTd3X86L5rOVXDh',
        name: 'Lean In Hacks 2020'
    },
    {
        id: 16,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1pG646pJAAWqMFYE6RqReOsbghNQYtZL7',
        name: 'Sheryl Sandberg wisdom'
    },
    {
        id: 17,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1v0Dv70qdUDxU5BACN0JpYCEbQhQMOxVI',
        name: 'Lean In Hacks 2020'
    },
    {
        id: 18,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1VJp2hc09mFoYXQiMrYf9tecLzs9kQtx8',
        name: 'Session in 2017'
    },
    {
        id: 19,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1e1U2HXdjw1onYFbDNtD8oXblSSoUckqd',
        name: 'Session in 2017'
    },
    {
        id: 20,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1WLN691nDo3290t6DR0nd7qfFHqcwbcyU',
        name: 'Session in 2017'
    },
    {
        id: 21,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1eTYMW3ws4Oo3EucNdD9WraM-wDKlSsma',
        name: 'Lean In Hacks 2020 Swags'
    },
    {
        id: 22,
        imgSrc: 'https://drive.google.com/uc?export=view&id=141FXWYrrjXlDwAhZ-5FT7ZNG-1Ic2MpU',
        name: 'Offline session 2020'
    },
    {
        id: 23,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1ku6qhZwO_CVgp8u_ONvFN1hnINy4x276',
        name: 'Offline session 2020'
    },
    {
        id: 24,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1lMSsX4C6BSX2xMrybxYHeLv1xjFH9l4R',
        name: 'Lean In Hacks 2020'
    },
    {
        id: 25,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1AGnAeTS3Ty6UqI-dM6vOqZoklEW__e7h',
        name: 'Amazon Alexa Session'
    },
    {
        id: 26,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1EmSpYLFXcb-3tppBgzdjeC5XaFRIFNsp',
        name: 'Sept Cohort 2021 Valedictory'
    },
    {
        id: 27,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1d3Flr0ffES_B82p9Qeat70EJn2-l2ibN',
        name: 'Core Team 2021-2022'
    },
    {
        id: 28,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1139PVPkT2VHSBnO-YhGtumRA4Id251EE',
        name: 'Core Team 2021-2022'
    },
    {
        id: 29,
        imgSrc: 'https://drive.google.com/uc?export=view&id=1TPonxDad2zOom605pif459qO_fyv-dfE',
        name: 'Core Team 2021-2022'
    },
]

export default GalleryContent;