import React from "react";
import "./Timeline.css";

export default function Timeline({ timeline }) {
    const TimelineItem = ({ data }) => (
        <div className="timeline-item">
            <div className="timeline-item-content">
                <span className="tag" style={{ background: data.category.color }}>
                    {data.category.tag}
                </span>
                <time>{data.date}</time>
                <p>{data.text}</p>
                {/* {data.link && (
                    <a
                        href={data.link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {data.link.text}
                    </a>
                )} */}
                <span className="circle" />
            </div>
        </div>
    );

    const Timeline = () =>
        timeline.length > 0 && (
            <div className="timeline-container">
                {timeline.map((data, idx) => (
                    <TimelineItem data={data} key={idx} />
                ))}
            </div>
        )

    return (
        <>
            <Timeline />
        </>
    );
}