import React from "react";
import "./chronicleGirlSVG.css";

export default function ChronicleGirlSVG() {
    return (
        <svg className="chronicle-girl-svg" viewBox="0 0 500 386" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                {/* <path opacity="0.18" d="M493.68 319.75C488.43 331.651 480.862 342.388 471.42 351.335C461.977 360.282 450.847 367.259 438.68 371.86C422.68 377.86 405.38 379.42 388.33 380.72C323.119 385.666 257.675 386.812 192.33 384.15C141 382.06 104.92 363.15 59.15 342.55C52.8062 339.88 46.6635 336.755 40.77 333.2C15.03 316.77 2.76999 285.45 0.389993 254.75C0.209993 252.43 0.0999935 250.12 0.0299935 247.81C-0.200006 238.54 0.319993 228.95 3.67999 220.24C16.19 187.71 62.8 176.92 71.76 143.24C76.39 125.8 69.24 106.6 74.99 89.51C80.34 73.63 95.51 63.34 110.39 55.66C172.57 23.54 247.52 17.11 314.28 38.12C357.57 51.73 400.98 81.73 408.75 126.44C413.19 152 405.55 180.05 417.34 203.16C430.08 228.16 461.71 239.67 481.86 258.11C490.09 265.63 496.4 274.31 498.71 285.52C501.08 297 498.38 309 493.68 319.75Z" fill="red" /> */}
                {/* <path d="M493.68 319.75C488.43 331.651 480.862 342.388 471.419 351.335C461.977 360.282 450.847 367.259 438.68 371.86C422.68 377.86 405.38 379.42 388.33 380.72C323.118 385.666 257.674 386.812 192.33 384.15C141 382.06 104.92 363.15 59.1499 342.55C52.8061 339.88 46.6634 336.755 40.7699 333.2C15.0299 316.77 2.76989 285.45 0.389893 254.75C1.33628 254.424 2.31543 254.203 3.30989 254.09C18.2099 251.96 33.3999 249.85 48.3099 252.24C57.5699 253.75 66.4799 256.98 75.7099 258.67C101.71 263.49 127.76 255.88 153.84 257.55C175.89 258.96 197.61 263.69 219.95 264.36C243.47 265.08 267.02 264.26 290.53 263.19C337.53 261.04 384.86 257.67 431.81 262.85C438.32 263.56 444.81 264.44 451.37 263.85C456.63 263.36 461.75 261.91 466.91 260.74C471.862 259.611 476.868 258.736 481.91 258.12C490.14 265.64 496.45 274.32 498.76 285.53C501.08 297 498.38 309 493.68 319.75Z" fill="url(#paint0_linear)" /> */}
                {/* <path d="M93.9201 264.15C94.7101 263.04 95.5301 261.69 95.1201 260.39C94.9361 259.941 94.6645 259.532 94.3211 259.189C93.9778 258.846 93.5695 258.574 93.1201 258.39C88.3701 256.06 82.3901 258.46 78.9901 262.51C75.5901 266.56 74.1401 271.85 72.7901 276.97C73.1301 263.85 73.3201 250.07 67.2001 238.46C66.0306 235.943 64.2201 233.777 61.9501 232.18C59.6001 230.72 56.4001 230.38 54.1201 231.94C51.8401 233.5 51.1201 236.28 51.1201 238.94C51.1201 243.33 53.1201 247.44 54.3801 251.63C56.6001 258.84 56.9301 266.47 57.6301 273.99C58.8801 287.49 60.3101 301.18 64.0401 314.22C63.9001 313.74 69.4701 309.98 70.0401 308.84C72.2601 304.55 72.3101 298.42 74.4301 293.77C79.3301 282.96 87.1201 273.77 93.9201 264.15Z" fill="#4CAF50" />
                            <path d="M93.9201 264.15C94.7101 263.04 95.5301 261.69 95.1201 260.39C94.9361 259.941 94.6645 259.532 94.3211 259.189C93.9778 258.846 93.5695 258.574 93.1201 258.39C88.3701 256.06 82.3901 258.46 78.9901 262.51C75.5901 266.56 74.1401 271.85 72.7901 276.97C73.1301 263.85 73.3201 250.07 67.2001 238.46C66.0306 235.943 64.2201 233.777 61.9501 232.18C59.6001 230.72 56.4001 230.38 54.1201 231.94C51.8401 233.5 51.1201 236.28 51.1201 238.94C51.1201 243.33 53.1201 247.44 54.3801 251.63C56.6001 258.84 56.9301 266.47 57.6301 273.99C58.8801 287.49 60.3101 301.18 64.0401 314.22C63.9001 313.74 69.4701 309.98 70.0401 308.84C72.2601 304.55 72.3101 298.42 74.4301 293.77C79.3301 282.96 87.1201 273.77 93.9201 264.15Z" fill="url(#paint1_linear)" /> */}

                <path className="girl-hover" d="M255.32 1.36001C262.02 -0.939987 269.53 -0.159987 276.1 2.52001C281.458 4.57902 286.101 8.15474 289.46 12.81C291.11 15.1451 292.25 17.8018 292.805 20.6069C293.36 23.412 293.317 26.3025 292.68 29.09C291.68 32.82 289.49 36.45 290.18 40.25C290.78 43.59 293.55 46.11 296.44 47.89C299.33 49.67 302.55 50.98 305.08 53.25C307.264 55.2756 308.8 57.9025 309.494 60.7996C310.188 63.6967 310.009 66.7345 308.98 69.53C308.16 71.64 306.83 73.75 307.2 75.99C307.57 78.23 309.66 79.9 311.81 80.74C313.96 81.58 316.3 81.86 318.45 82.74C320.482 83.551 322.276 84.8618 323.667 86.5508C325.058 88.2398 326 90.2525 326.405 92.4024C326.811 94.5523 326.668 96.7698 325.989 98.8496C325.309 100.929 324.116 102.804 322.52 104.3C319.59 107.06 315.57 108.3 311.68 109.3C296.592 113.091 280.971 114.299 265.48 112.87C253.55 111.76 241.6 109.08 229.69 110.46C222.26 111.32 215.08 113.75 207.69 114.72C200.3 115.69 192.15 114.98 186.3 110.31C183.365 107.792 180.996 104.68 179.35 101.18C176.43 95.43 174.6 88.89 175.67 82.54C176.74 76.19 181.2 70.16 187.45 68.54C190.23 67.83 193.16 68.02 196.03 67.87C201.706 67.556 207.225 65.8934 212.13 63.02C213.61 62.15 215.13 61.02 215.53 59.37C216.19 56.63 213.6 54.37 212.22 51.89C209.46 46.97 212.51 39.82 217.97 38.4C221.17 37.57 225.09 38.3 227.34 35.88C230.16 32.88 228.73 28.1 231.77 24.94C234.49 22.12 238.29 21.06 240.47 17.55C244.74 10.63 246.68 4.34001 255.32 1.36001Z" fill="black">

                    <animate dur="3s" attributeName="d"
                        values="M255.32 1.36001C262.02 -0.939987 269.53 -0.159987 276.1 2.52001C281.458 4.57902 286.101 8.15474 289.46 12.81C291.11 15.1451 292.25 17.8018 292.805 20.6069C293.36 23.412 293.317 26.3025 292.68 29.09C291.68 32.82 289.49 36.45 290.18 40.25C290.78 43.59 293.55 46.11 296.44 47.89C299.33 49.67 302.55 50.98 305.08 53.25C307.264 55.2756 308.8 57.9025 309.494 60.7996C310.188 63.6967 310.009 66.7345 308.98 69.53C308.16 71.64 306.83 73.75 307.2 75.99C307.57 78.23 309.66 79.9 311.81 80.74C313.96 81.58 316.3 81.86 318.45 82.74C320.482 83.551 322.276 84.8618 323.667 86.5508C325.058 88.2398 326 90.2525 326.405 92.4024C326.811 94.5523 326.668 96.7698 325.989 98.8496C325.309 100.929 324.116 102.804 322.52 104.3C319.59 107.06 315.57 108.3 311.68 109.3C296.592 113.091 280.971 114.299 265.48 112.87C253.55 111.76 241.6 109.08 229.69 110.46C222.26 111.32 215.08 113.75 207.69 114.72C200.3 115.69 192.15 114.98 186.3 110.31C183.365 107.792 180.996 104.68 179.35 101.18C176.43 95.43 174.6 88.89 175.67 82.54C176.74 76.19 181.2 70.16 187.45 68.54C190.23 67.83 193.16 68.02 196.03 67.87C201.706 67.556 207.225 65.8934 212.13 63.02C213.61 62.15 215.13 61.02 215.53 59.37C216.19 56.63 213.6 54.37 212.22 51.89C209.46 46.97 212.51 39.82 217.97 38.4C221.17 37.57 225.09 38.3 227.34 35.88C230.16 32.88 228.73 28.1 231.77 24.94C234.49 22.12 238.29 21.06 240.47 17.55C244.74 10.63 246.68 4.34001 255.32 1.36001Z;
                   M255.32 1.35998C262.02 -0.940017 269.53 -0.160018 276.1 2.51998C281.458 4.57899 286.101 8.15471 289.46 12.81C291.11 15.1451 292.25 17.8017 292.805 20.6068C293.36 23.4119 293.317 26.3025 292.68 29.09C291.68 32.82 288.77 38.2 289.46 42C290.06 45.34 293.11 49.22 296 51C298.89 52.78 302.97 53.73 305.5 56C307.684 58.0256 309.306 60.6028 310 63.5C310.694 66.3971 307.529 68.7044 306.5 71.5C305.68 73.61 304.611 77.2406 306.5 78.5C308 79.5 311.35 80.16 313.5 81C315.65 81.84 316.3 81.86 318.45 82.74C320.482 83.551 322.521 85.0431 323.5 87C324.5 89 325.094 90.8501 325.5 93C325.906 95.1499 325.679 96.9202 325 99C324.321 101.08 323.096 102.504 321.5 104C318.57 106.76 315.57 108.3 311.68 109.3C296.592 113.091 280.971 114.298 265.48 112.87C253.55 111.76 241.6 109.08 229.69 110.46C222.26 111.32 215.08 113.75 207.69 114.72C200.3 115.69 196.35 122.17 190.5 117.5C184.65 112.83 183.858 112.767 173 110.31C165 108.5 163 99.9395 163 93.5C163 85 164.5 70.7652 175.67 67.87C186 65.1924 197 68.06 200 69.53C203 71 207.315 70.7433 212.22 67.87C213.7 67 216.5 64.5 215.53 59.37C215.006 56.6007 213.6 54.37 212.22 51.89C209.46 46.97 210.07 41.67 215.53 40.25C218.73 39.42 225.09 38.3 227.34 35.88C230.16 32.88 226.96 29.16 230 26C232.72 23.18 237 23.1736 240.47 17.55C244.74 10.63 244 5.24597 255.32 1.35998Z;
                   
                   M255.32 1.36001C262.02 -0.939987 269.53 -0.159987 276.1 2.52001C281.458 4.57902 286.101 8.15474 289.46 12.81C291.11 15.1451 292.25 17.8018 292.805 20.6069C293.36 23.412 293.317 26.3025 292.68 29.09C291.68 32.82 289.49 36.45 290.18 40.25C290.78 43.59 293.55 46.11 296.44 47.89C299.33 49.67 302.55 50.98 305.08 53.25C307.264 55.2756 308.8 57.9025 309.494 60.7996C310.188 63.6967 310.009 66.7345 308.98 69.53C308.16 71.64 306.83 73.75 307.2 75.99C307.57 78.23 309.66 79.9 311.81 80.74C313.96 81.58 316.3 81.86 318.45 82.74C320.482 83.551 322.276 84.8618 323.667 86.5508C325.058 88.2398 326 90.2525 326.405 92.4024C326.811 94.5523 326.668 96.7698 325.989 98.8496C325.309 100.929 324.116 102.804 322.52 104.3C319.59 107.06 315.57 108.3 311.68 109.3C296.592 113.091 280.971 114.299 265.48 112.87C253.55 111.76 241.6 109.08 229.69 110.46C222.26 111.32 215.08 113.75 207.69 114.72C200.3 115.69 192.15 114.98 186.3 110.31C183.365 107.792 180.996 104.68 179.35 101.18C176.43 95.43 174.6 88.89 175.67 82.54C176.74 76.19 181.2 70.16 187.45 68.54C190.23 67.83 193.16 68.02 196.03 67.87C201.706 67.556 207.225 65.8934 212.13 63.02C213.61 62.15 215.13 61.02 215.53 59.37C216.19 56.63 213.6 54.37 212.22 51.89C209.46 46.97 212.51 39.82 217.97 38.4C221.17 37.57 225.09 38.3 227.34 35.88C230.16 32.88 228.73 28.1 231.77 24.94C234.49 22.12 238.29 21.06 240.47 17.55C244.74 10.63 246.68 4.34001 255.32 1.36001Z"
                        repeatCount="indefinite"
                        begin="0s"
                    />
                </path>


                {/* <path d="M56.49 317.98C73.87 341.61 104.85 351.18 133.94 354.91C430.6 392.91 471.99 320.32 473.34 318.75C478.22 313.1 406.18 249.45 194.15 263.82C179.32 264.82 47.07 305.17 56.49 317.98Z" fill="#4CAF50" /> */}
                {/* <path  d="M132.81 281.43C130.715 282.388 128.885 283.844 127.48 285.67C126.21 287.58 125.93 290.34 127.42 292.08C128.48 293.33 130.17 293.81 131.77 294.21C155.794 300.12 180.88 300.22 204.95 294.5C214.69 292.17 224.24 288.89 234.17 287.58C242.17 286.53 250.32 286.78 258.41 287.14C288.18 288.46 317.89 291.14 347.5 294.5C364.3 296.41 381.23 298.5 398.06 296.93C399.73 296.77 401.54 296.51 402.69 295.29C405.35 292.48 402.12 287.9 398.61 286.29C393.42 283.88 386.36 282.87 384.61 277.41C384.448 276.487 384.108 275.604 383.61 274.81C382.704 273.972 381.514 273.508 380.28 273.51C312.34 265.61 243.788 264.391 175.61 269.87C159.73 271.13 146.83 273.83 132.81 281.43Z" fill="url(#paint3_linear)" /> */}
                <g className="girl-hover">
                    <path d="M121.16 215.12C121.16 215.12 133.51 191.55 187.4 210.07C241.29 228.59 318.2 261.72 318.2 261.72L312.03 285.3C312.03 285.3 185.16 270.14 167.19 260.03C149.22 249.92 117.79 248.81 121.16 215.12Z" fill="black" />
                    <path d="M121.16 215.12C121.16 215.12 133.51 191.55 187.4 210.07C241.29 228.59 318.2 261.72 318.2 261.72L312.03 285.3C312.03 285.3 185.16 270.14 167.19 260.03C149.22 249.92 117.79 248.81 121.16 215.12Z" fill="url(#paint4_linear)" fill-opacity="0.5" />
                    <path d="M210.42 263.96C210.42 263.96 304.17 218.54 349.68 213.44C395.19 208.34 398.52 218.49 400.21 220.74C401.9 222.99 418.73 252.17 357.54 266.21C296.35 280.25 208.78 288.66 208.78 288.66C208.78 288.66 204.24 283.05 210.42 263.96Z" fill="black" />
                    <path d="M210.42 263.96C210.42 263.96 304.17 218.54 349.68 213.44C395.19 208.34 398.52 218.49 400.21 220.74C401.9 222.99 418.73 252.17 357.54 266.21C296.35 280.25 208.78 288.66 208.78 288.66C208.78 288.66 204.24 283.05 210.42 263.96Z" fill="url(#paint5_linear)" fill-opacity="0.5" />
                    <path d="M307.1 3.18999L308.39 1.89999" stroke="#3CB6D5" stroke-width="3.43" stroke-miterlimit="10" />
                    <path d="M248.68 77.82C244.795 80.2109 240.656 82.1628 236.34 83.64C238.628 87.3801 241.74 90.5476 245.44 92.9C250.83 96.16 257.33 97.05 263.63 97.11C267.192 97.2515 270.754 96.8228 274.18 95.84C278.77 94.36 282.7 91.35 286.37 88.22C288.01 86.8 289.79 84.91 289.37 82.79C286.012 82.9868 282.674 82.1564 279.8 80.4093C276.926 78.6622 274.651 76.0813 273.28 73.01C272.728 71.6081 272.28 70.1676 271.94 68.7C271.78 68.08 271.17 64.16 270.74 63.7C270.217 63.1953 269.582 62.821 268.887 62.6073C268.192 62.3936 267.457 62.3466 266.74 62.47C263.52 63.05 259.05 61.95 256.74 64.58C254.04 67.59 252.29 75.54 248.68 77.82Z" fill="url(#paint6_linear)" />
                    <path d="M259.02 75.42C260.694 76.512 262.69 77.0022 264.68 76.81C266.68 76.58 268.24 71.81 269.2 70.04C269.483 69.4858 269.724 68.9108 269.92 68.32C270.774 66.22 271.297 64.0003 271.47 61.74C271.47 62.37 267.3 63.03 266.76 63.11C265.02 63.37 263.25 63.46 261.5 63.64C259.07 63.89 254.21 64 255.29 67.51C255.83 69.3 257.45 74.44 259.02 75.42Z" fill="url(#paint7_linear)" />
                    <path d="M236.35 83.64C236.35 83.64 222.77 85.16 219.12 92.74C215.47 100.32 162.12 169.37 161.86 173.86C161.6 178.35 187.12 204.18 187.12 204.18L198.91 178.91L195.26 171.06L218 139.62L217.1 220.82L264.93 239.92L308.68 223.1L310.3 139.34C310.3 139.34 329.39 166.28 329.95 173.02C330.51 179.76 327.71 183.69 327.71 183.69L343.99 207.26C343.99 207.26 369.25 180.88 367.57 168.53C365.89 156.18 313.68 93.3 308.68 90.54C303.68 87.78 289.36 82.83 289.36 82.83L236.35 83.64Z" fill="red" />
                    <path d="M208.6 218.08C204.526 218.523 200.489 219.258 196.52 220.28C195.16 220.63 193.61 221.19 193.18 222.53C192.59 224.35 194.51 225.97 196.26 226.75C207.11 231.58 219.81 227.57 231.55 229.45C241.61 231.07 250.46 236.93 260.21 239.92C260.673 240.096 261.165 240.184 261.66 240.18C262.756 240.199 263.851 240.112 264.93 239.92C269.59 238.31 273.51 236.4 278.63 235.82L297.89 233.65C305.94 232.74 312.55 228.84 320.56 227.65C323.97 227.14 327.56 227.46 330.73 226.04C331.89 225.51 333.07 224.16 332.33 223.13C332.014 222.773 331.594 222.525 331.13 222.42C327.66 221.29 323.94 221.37 320.3 221.42C283.13 222.08 245.57 214.08 208.6 218.08Z" fill="red" />
                    <path d="M208.6 218.08C204.526 218.523 200.489 219.258 196.52 220.28C195.16 220.63 193.61 221.19 193.18 222.53C192.59 224.35 194.51 225.97 196.26 226.75C207.11 231.58 219.81 227.57 231.55 229.45C241.61 231.07 250.46 236.93 260.21 239.92C260.673 240.096 261.165 240.184 261.66 240.18C262.756 240.199 263.851 240.112 264.93 239.92C269.59 238.31 273.51 236.4 278.63 235.82L297.89 233.65C305.94 232.74 312.55 228.84 320.56 227.65C323.97 227.14 327.56 227.46 330.73 226.04C331.89 225.51 333.07 224.16 332.33 223.13C332.014 222.773 331.594 222.525 331.13 222.42C327.66 221.29 323.94 221.37 320.3 221.42C283.13 222.08 245.57 214.08 208.6 218.08Z" fill="url(#paint8_linear)" />
                    <path d="M309.78 125.17L310.72 138.92L327.72 165.31C329.174 167.57 330.053 170.151 330.279 172.828C330.506 175.506 330.074 178.198 329.02 180.67L327.73 183.67L336.5 191.2L345.23 171.92L309.78 125.17Z" fill="url(#paint9_linear)" fill-opacity="0.3" />
                    <path d="M219.28 192.74C221.011 193.344 222.65 194.186 224.15 195.24C227.95 198.15 229.2 203.3 229.51 208.08C229.633 208.961 229.519 209.858 229.18 210.68C228.78 211.292 228.219 211.782 227.56 212.097C226.901 212.413 226.167 212.542 225.44 212.47C221.92 212.47 218.78 210.09 215.27 209.75C213.47 209.57 211.63 209.93 209.86 209.57C205.7 208.72 203.38 204.44 200.86 201.05C198.533 197.99 195.637 195.41 192.33 193.45C185.88 189.64 189.33 182.33 196.33 183.75C204.07 185.34 211.91 189.75 219.28 192.74Z" fill="url(#paint10_linear)" />
                    <path d="M308.87 190.69C307.249 190.973 305.687 191.528 304.25 192.33C302.19 193.64 300.92 195.87 299.25 197.61C297.83 199.04 296.01 200.29 295.5 202.24C295.236 203.441 295.388 204.696 295.93 205.8C296.773 207.543 298.187 208.946 299.937 209.775C301.686 210.605 303.667 210.811 305.55 210.36C307.09 209.94 308.48 209.08 310.01 208.65C314.76 207.3 320.33 210.09 324.56 207.56C325.897 206.617 327.086 205.481 328.09 204.19C330.365 201.667 333.016 199.512 335.95 197.8C336.692 197.456 337.343 196.942 337.85 196.3C338.85 194.72 337.76 192.66 336.56 191.22C333.05 187.02 331.03 185.55 325.74 186.76C320.08 188.04 314.48 189.38 308.87 190.69Z" fill="url(#paint11_linear)" />
                    <path d="M197.96 269.15C188.53 270.09 179.08 266.6 169.64 267.49C166.236 267.854 162.895 268.661 159.7 269.89C158.148 270.365 156.716 271.166 155.5 272.24C153.4 274.31 152.99 277.6 153.5 280.5C154.5 286.16 158.66 290.99 163.8 293.56C166.976 295.188 170.543 295.898 174.1 295.61C175.79 295.406 177.462 295.072 179.1 294.61C182.789 293.838 186.378 292.65 189.8 291.07C191.96 289.97 193.95 288.57 196.12 287.51C199.2 286.01 202.59 285.24 205.62 283.65C205.856 283.548 206.07 283.402 206.25 283.22C206.437 282.986 206.576 282.718 206.66 282.43C208.167 278.185 208.902 273.704 208.83 269.2C208.83 268.56 209.04 267.2 208.38 266.85C207.87 266.59 205.67 267.59 205.08 267.75C202.749 268.41 200.368 268.879 197.96 269.15Z" fill="url(#paint12_linear)" />
                    <path d="M329.37 282.33C333.91 283.48 338.03 285.9 342.53 287.17C346.396 288.162 350.379 288.622 354.37 288.54C360.37 288.54 366.68 288.08 371.88 285.03C372.718 284.588 373.452 283.971 374.03 283.22C375.37 281.31 374.78 278.67 373.89 276.51C372.35 272.72 369.82 269.03 366.01 267.51C361.51 265.76 356.5 267.51 351.82 268.64C340.3 271.53 328.19 271.09 316.54 273.39C315.918 273.378 315.314 273.604 314.853 274.022C314.392 274.441 314.108 275.019 314.06 275.64C313.73 277.04 312.87 279.35 313.4 280.79C314.01 282.47 315.67 281.87 317.13 281.79C321.212 281.289 325.348 281.471 329.37 282.33Z" fill="url(#paint13_linear)" />
                    <path d="M338.83 277.01C338.419 277.821 337.937 278.594 337.39 279.32C336.562 280.509 336.206 281.963 336.39 283.4C336.623 284.245 337.023 285.035 337.568 285.723C338.113 286.41 338.79 286.98 339.56 287.4C343.94 290.26 349.41 290.79 354.64 290.97C357.442 291.142 360.254 291.018 363.03 290.6C365.009 290.211 366.96 289.69 368.87 289.04C370.807 288.563 372.632 287.711 374.24 286.53C375.468 285.442 376.409 284.068 376.98 282.53C377.753 280.777 377.963 278.827 377.58 276.95C377.149 275.513 376.435 274.177 375.48 273.02C374.498 271.706 373.429 270.46 372.28 269.29C371.441 268.354 370.42 267.599 369.28 267.07C367.909 266.63 366.463 266.476 365.03 266.62C356.811 266.82 348.621 267.679 340.54 269.19C339.908 269.263 339.306 269.497 338.79 269.87C337.74 270.78 338.79 271.99 338.99 273.04C339.347 274.348 339.291 275.735 338.83 277.01Z" fill="red" />
                    <path d="M193.88 280.3C194.82 280.95 195.88 281.4 196.78 282.1C197.253 282.421 197.64 282.853 197.907 283.359C198.174 283.865 198.312 284.428 198.31 285C198.18 286.38 196.89 287.33 195.71 288C190.283 291.282 184.409 293.757 178.27 295.35C175.591 296.154 172.794 296.493 170 296.35C167.719 296.079 165.491 295.469 163.39 294.54C159.7 293.02 156.12 290.97 153.64 287.85C151.16 284.73 149.96 280.38 151.37 276.65C152.56 273.49 155.37 271.26 158.05 269.23C159.418 268.087 160.961 267.172 162.62 266.52C164.246 266.063 165.932 265.861 167.62 265.92C172.05 265.85 176.47 265.86 180.9 265.86C183.9 265.86 189.85 265.81 190.54 269.72C190.86 271.51 190.1 273.31 190.54 275.17C190.97 277.238 192.162 279.07 193.88 280.3Z" fill="red" />
                    <path d="M338.83 277.01C338.419 277.821 337.937 278.594 337.39 279.32C336.562 280.509 336.206 281.963 336.39 283.4C336.623 284.245 337.023 285.035 337.568 285.723C338.113 286.41 338.79 286.98 339.56 287.4C343.94 290.26 349.41 290.79 354.64 290.97C357.442 291.142 360.254 291.018 363.03 290.6C365.009 290.211 366.96 289.69 368.87 289.04C370.807 288.563 372.632 287.711 374.24 286.53C375.468 285.442 376.409 284.068 376.98 282.53C377.753 280.777 377.963 278.827 377.58 276.95C377.149 275.513 376.435 274.177 375.48 273.02C374.498 271.706 373.429 270.46 372.28 269.29C371.441 268.354 370.42 267.599 369.28 267.07C367.909 266.63 366.463 266.476 365.03 266.62C356.811 266.82 348.621 267.679 340.54 269.19C339.908 269.263 339.306 269.497 338.79 269.87C337.74 270.78 338.79 271.99 338.99 273.04C339.347 274.348 339.291 275.735 338.83 277.01Z" fill="url(#paint14_linear)" fill-opacity="0.35" />
                    <path d="M193.88 280.3C194.82 280.95 195.88 281.4 196.78 282.1C197.253 282.421 197.64 282.853 197.907 283.359C198.174 283.865 198.312 284.428 198.31 285C198.18 286.38 196.89 287.33 195.71 288C190.283 291.282 184.409 293.757 178.27 295.35C175.591 296.154 172.794 296.493 170 296.35C167.719 296.079 165.491 295.469 163.39 294.54C159.7 293.02 156.12 290.97 153.64 287.85C151.16 284.73 149.96 280.38 151.37 276.65C152.56 273.49 155.37 271.26 158.05 269.23C159.418 268.087 160.961 267.172 162.62 266.52C164.246 266.063 165.932 265.861 167.62 265.92C172.05 265.85 176.47 265.86 180.9 265.86C183.9 265.86 189.85 265.81 190.54 269.72C190.86 271.51 190.1 273.31 190.54 275.17C190.97 277.238 192.162 279.07 193.88 280.3Z" fill="url(#paint15_linear)" fill-opacity="0.35" />
                    <path d="M244.94 34.64C244.94 34.64 244.1 70.01 262.63 68.04C281.16 66.07 282 33.54 282 33.54C282 33.54 274.42 14.45 258.42 17.26C242.42 20.07 244.94 34.64 244.94 34.64Z" fill="url(#paint16_linear)" />
                    <path d="M254.23 19.26C256.77 29.88 267.77 37.76 278.64 36.77C280.51 36.59 282.64 35.97 283.36 34.24C283.628 33.446 283.714 32.6017 283.61 31.77C283.371 26.3481 281.591 21.1067 278.48 16.66C276.914 14.4638 274.907 12.6181 272.588 11.2406C270.269 9.86321 267.688 8.98432 265.01 8.66001C257.25 7.93001 250.01 12.75 244.74 18.53C241.45 22.16 231.5 38.7 242.35 39.22C245.82 39.38 249.18 36.86 250.85 34C253.49 29.43 251.21 23.54 254.23 19.26Z" fill="black" />
                    <path d="M218.94 125.87L218 139.62L195.27 171.05L198.91 178.91L196.27 183.75L183.47 172.64L218.94 125.87Z" fill="url(#paint17_linear)" fill-opacity="0.3" />
                    <path d="M237.39 86.6C238.14 88.6 240.65 89.16 242.74 89.67C246.74 90.67 250.52 92.67 254.55 93.67C258.763 94.5874 263.07 94.997 267.38 94.89C272.82 94.98 278.27 95.07 283.7 94.68C290.98 94.17 298.27 92.83 305.53 93.56C306.02 93.2 305.45 92.47 304.92 92.17C301.14 90.03 297.18 88.24 293.27 86.35C290.03 84.77 287.27 84.96 283.67 85.17C273.82 85.73 263.95 85.17 254.09 85.27C248.498 85.2976 242.916 85.7421 237.39 86.6Z" fill="url(#paint18_linear)" />
                    <path d="M324.31 135.9H207.92V224.36H324.31V135.9Z" fill="#C9F5FF" />
                    <path d="M324.83 135.9H208.44V142.09H324.83V135.9Z" fill="url(#paint19_linear)" />
                    <path d="M325 142H208V224H325V142Z" fill="url(#paint20_linear)" />
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="243.49" y1="341.44" x2="255.05" y2="224.77" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="0.76" stop-color="white" stop-opacity="0.76" />
                    <stop offset="0.99" stop-color="white" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="71.7301" y1="271.19" x2="66.8301" y2="345.12" gradientUnits="userSpaceOnUse">
                    <stop stop-opacity="0" />
                    <stop offset="0.99" />
                </linearGradient>
                <linearGradient id="paint2_linear" x1="251" y1="6" x2="175" y2="251" gradientUnits="userSpaceOnUse">
                    <stop stop-color="black" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient id="paint3_linear" x1="265" y1="350.5" x2="265" y2="267" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292929" />
                    <stop offset="1" stop-color="#292929" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear" x1="265.5" y1="162" x2="201" y2="331.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#2D2D2D" />
                    <stop offset="1" stop-color="#2D2D2D" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint5_linear" x1="272" y1="163.5" x2="324" y2="307.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1A1A1A" />
                    <stop offset="1" stop-color="#1A1A1A" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint6_linear" x1="236.35" y1="79.75" x2="289.41" y2="79.75" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECC4D7" />
                    <stop offset="0.42" stop-color="#EFD4D1" />
                    <stop offset="1" stop-color="#F2EAC9" />
                </linearGradient>
                <linearGradient id="paint7_linear" x1="4626.19" y1="1779.69" x2="5537.44" y2="3547.46" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECC4D7" />
                    <stop offset="0.42" stop-color="#EFD4D1" />
                    <stop offset="1" stop-color="#F2EAC9" />
                </linearGradient>
                <linearGradient id="paint8_linear" x1="266.5" y1="199.5" x2="263" y2="260.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#131313" />
                    <stop offset="1" stop-color="#131313" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint9_linear" x1="327.505" y1="125.17" x2="327.505" y2="191.2" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#303030" />
                    <stop offset="1" stop-color="#303030" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint10_linear" x1="8203.8" y1="7514.16" x2="9856.36" y2="7514.16" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECC4D7" />
                    <stop offset="0.42" stop-color="#EFD4D1" />
                    <stop offset="1" stop-color="#F2EAC9" />
                </linearGradient>
                <linearGradient id="paint11_linear" x1="13312" y1="6342.51" x2="15150.2" y2="6342.51" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECC4D7" />
                    <stop offset="0.42" stop-color="#EFD4D1" />
                    <stop offset="1" stop-color="#F2EAC9" />
                </linearGradient>
                <linearGradient id="paint12_linear" x1="9133.42" y1="9986.36" x2="12220.9" y2="9986.36" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECC4D7" />
                    <stop offset="0.42" stop-color="#EFD4D1" />
                    <stop offset="1" stop-color="#F2EAC9" />
                </linearGradient>
                <linearGradient id="paint13_linear" x1="20116.3" y1="7521.51" x2="23909.4" y2="7521.51" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECC4D7" />
                    <stop offset="0.42" stop-color="#EFD4D1" />
                    <stop offset="1" stop-color="#F2EAC9" />
                </linearGradient>
                <linearGradient id="paint14_linear" x1="357.054" y1="266.568" x2="357.054" y2="291.045" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#313131" />
                    <stop offset="1" stop-color="#313131" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint15_linear" x1="174.54" y1="265.859" x2="174.54" y2="296.382" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#313131" />
                    <stop offset="1" stop-color="#313131" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint16_linear" x1="9662.03" y1="5028.86" x2="11046" y2="5028.86" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ECC4D7" />
                    <stop offset="0.42" stop-color="#EFD4D1" />
                    <stop offset="1" stop-color="#F2EAC9" />
                </linearGradient>
                <linearGradient id="paint17_linear" x1="201.205" y1="125.87" x2="201.205" y2="183.75" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#303030" />
                    <stop offset="1" stop-color="#303030" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint18_linear" x1="269.5" y1="58" x2="271.553" y2="94.968" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F5F5F5" />
                    <stop offset="1" stop-color="#F5F5F5" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint19_linear" x1="31189.1" y1="1062.11" x2="31189.5" y2="912.154" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="0.76" stop-color="white" stop-opacity="0.76" />
                    <stop offset="0.99" stop-color="white" />
                </linearGradient>
                <linearGradient id="paint20_linear" x1="266.5" y1="142" x2="266.5" y2="224" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C4C4C4" />
                    <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="499.59" height="385.54" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}