const MemberDetail = [
    {
        year: "2022-23",
        president: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1kgWw7BcIDJkuT_PfYGHh_9ZcJiA6yXor',
                name: 'Sukriti Dawar',
                about: "It's been a wonderful experience at Lean In IGDTUW. I have made a lot of memories till now and have learnt a lot of new skills. Really grateful to all my seniors for helping me throughout and giving me this amazing opportunity!",
                socialMedia: 'https://www.linkedin.com/in/sukriti-dawar/',
                year: "2022-23",
                position: 'president',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=13jQxzKJcYVuunBBKkqvUAp62fnFRTIRg',
                name: 'Rashmitha ',
                about: "I am a 4th year BTech IT Student . I have been in Lean in since past two years and it has played a pivotal role in shaping my personality.",
                socialMedia: 'https://www.linkedin.com/in/rashmitha-mohan-636234111/',
                year: "2022-23",
                position: 'president',
            },
        ],
        core_team_member: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1IGc1z_XNPDQ8PBPFhUe0EeeQXDGDcHDT',
                name: 'Nishi',
                about: "I'm a CSAI Undergrad at IGDTUW and a Core member of this amazing society, which is all about encouraging and empowering our peers.",
                socialMedia: 'https://www.linkedin.com/in/nishi-a414541bb/',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1SJuObpHKmjSDPQeEojX63T2RIi6K1Lib',
                name: 'Simran Vishrant',
                about: "CSE Undergrad | Grateful to be the part of this wonderful family- Lean In IGDTUW. It's been an amazing experience, full of new learnings and great experiences.",
                socialMedia: 'https://www.linkedin.com/in/simran-vishrant-a70911207/',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 3,
                profilePic: 'https://drive.google.com/uc?export=view&id=1dxBBspUURarR7_ehtlJeD609HXYQUCMk',
                name: 'Ishita Chandra',
                about: "I feel privileged to be a part of Lean In IGDTUW. It's been an incredibly rewarding learning experience.",
                socialMedia: 'https://www.linkedin.com/in/ishita-chandra/',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=12BerlNfOVYa7bS2wvx7EJ33J6QraHtVW',
                name: 'Riddhi Chaudhary',
                about: "My experience at Lean In has helped me grow into a better version of myself. It brings me great joy to play a part in the smooth operation of society.",
                socialMedia: 'https://www.linkedin.com/in/riddhi-chaudhary/',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=1AhOHHJeyfF0qHCs_JuV_XVLYxUr5hbO_',
                name: 'Harsika Sunil Agarwal',
                about: "I am a BTech IT Undergrad. Lean In has been instrumental in enriching my skills and I feel grateful to be a part of such an all-embracing empowering society.",
                socialMedia: 'http://www.linkedin.com/in/harsika-agarwal',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 6,
                profilePic: 'https://drive.google.com/uc?export=view&id=1b7QeXpab0J6WrubdeDiQxhKw2NwzUno1',
                name: 'Deekshita Verma',
                about: "SWE Intern @ Google | I am a Btech IT final year student and a core member of this amazing society. I love juggling a thousand things on my plate, being an AR Developer and a mentor are a few of them.",
                socialMedia: 'https://www.linkedin.com/in/DeekshitaV',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 7,
                profilePic: 'https://drive.google.com/uc?export=view&id=1hBaPl1mXJBSx65tIEaRcjf0XdznbUJsj',
                name: 'Riya Garg',
                about: "I am an ECE undergrad. Really enthusiastic about Technology and grateful to be a part of this wonderful society that promotes learning and growing together.",
                socialMedia: 'https://www.linkedin.com/in/riya-garg-a310b922a/',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 8,
                profilePic: 'https://drive.google.com/uc?export=view&id=1gHO7VXqwsfn5NSKYFbJilrHeroM59N2F',
                name: 'Anushka Gupta',
                about: "I am an ECE-AI undergrad. I am excited and grateful to be a core member of this fantastic society, which I have always wanted to be a part of.",
                socialMedia: 'https://www.linkedin.com/in/anushkagupta22/',
                year: "2022-23",
                position: 'core-team-member',
            },
            {
                id: 9,
                profilePic: 'https://drive.google.com/uc?export=view&id=1OoSBUFgPo_2wsQ0_2Jl-gAM_kNQ-6aSZ',
                name: 'Chayanika Basak',
                about: "I am an CSAI undergrad. Hearing about leanin was like hitting the jackpot. A society completely dedicated towards exploring various Computer Science fields was music to my ears. Coding has always been my thing and I am really glad to be a core member here. ",
                socialMedia: 'https://www.linkedin.com/in/chayanika-basak-183394239',
                year: "2022-23",
                position: 'core-team-member',
            },
        ],
        website_team: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1kgWw7BcIDJkuT_PfYGHh_9ZcJiA6yXor',
                name: 'Sukriti Dawar',
                about: "It feels great to work on the website of Lean In IGDTUW and see the website developing so beautifully.",
                socialMedia: 'https://www.linkedin.com/in/sukriti-dawar/',
                year: "2022-23",
                position: 'website-team',
                circle: '',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1gHO7VXqwsfn5NSKYFbJilrHeroM59N2F',
                name: 'Anushka Gupta',
                about: "It was a wonderful experience to work on the website of LeanIn. It not only helped me learn so many new & different things but seeing the website completed, it just feels so amazing.",
                socialMedia: 'https://www.linkedin.com/in/anushkagupta22/',
                year: "2022-23",
                position: 'website-team',
                circle: '',
            },
            {
                id: 3,
                profilePic: 'https://drive.google.com/uc?export=view&id=1hBaPl1mXJBSx65tIEaRcjf0XdznbUJsj',
                name: 'Riya Garg',
                socialMedia: 'https://www.linkedin.com/in/riya-garg-a310b922a/',
                year: "2022-23",
                position: 'website-team',
                circle: '',
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=1OoSBUFgPo_2wsQ0_2Jl-gAM_kNQ-6aSZ',
                name: 'Chayanika Basak',
                about: "I am an CSAI undergrad. Hearing about leanin was like hitting the jackpot. A society completely dedicated towards exploring various Computer Science fields was music to my ears. ",
                socialMedia: 'https://www.linkedin.com/in/chayanika-basak-183394239/',
                year: "2022-23",
                position: 'website-team',
                circle: '',
            },
            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=1AhOHHJeyfF0qHCs_JuV_XVLYxUr5hbO_',
                name: 'Harsika Sunil Agarwal',
                about: "I am a BTech IT Undergrad. Lean In has been instrumental in enriching my skills and I feel grateful to be a part of such an all-embracing empowering society.",
                socialMedia: 'http://www.linkedin.com/in/harsika-agarwal',
                year: "2022-23",
                position: 'website-team',
                circle: '',
            },
        ]
    },
    {
        year: "2021-22",
        president: [
            {
                id: 1,
                profilePic: 'https://media-exp2.licdn.com/dms/image/C4D03AQHclusSPyWyqA/profile-displayphoto-shrink_400_400/0/1655317771973?e=1662595200&v=beta&t=cHM2gHQmNGgRjZso2dzX8R8NrT1krIx09X_Yy7mKB0E',
                name: 'Apoorva Gupta',
                about: "Software Engineer | CSE Graduate. Best decision of my college life was to join Lean In, it will always be close to my heart!",
                socialMedia: 'https://www.linkedin.com/in/apoorvagupta28/',
                year: "2021-22",
                position: 'president',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1j3lkOh7jCVMIq6w2ZOKU9V7tumJpLry1',
                name: 'Divya Pant',
                about: "ASIC Digital Design Engineer | I am an ECE graduate from batch 2018-22. During the tenure of four years, being part of Lean In was the best experience. These years were full of learning, networking, and collaborating. Glad to be a part of this amazing family.",
                socialMedia: 'https://www.linkedin.com/in/divya-pant-094a0819a/',
                year: "2021-22",
                position: 'president',
            },
        ],
        core_team_member: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1AhOHHJeyfF0qHCs_JuV_XVLYxUr5hbO_',
                name: 'Harsika Sunil Agarwal',
                about: "I am a BTech IT Undergrad. Lean In has been instrumental in enriching my skills and I feel grateful to be a part of such an all-embracing empowering society.",
                socialMedia: 'http://www.linkedin.com/in/harsika-agarwal',
                year: "2021-22",
                position: 'core-team-member',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1dxBBspUURarR7_ehtlJeD609HXYQUCMk',
                name: 'Ishita Chandra',
                about: "I feel privileged to be a part of Lean In IGDTUW. It's been an incredibly rewarding learning experience.",
                socialMedia: 'https://www.linkedin.com/in/ishita-chandra/',
                year: "2021-22",
                position: 'core-team-member',
            },
            {
                id: 3,
                profilePic: 'https://drive.google.com/uc?export=view&id=1IGc1z_XNPDQ8PBPFhUe0EeeQXDGDcHDT',
                name: 'Nishi',
                about: "I'm a CSAI Undergrad at IGDTUW and a Core member of this amazing society, which is all about encouraging and empowering our peers.",
                socialMedia: 'https://www.linkedin.com/in/nishi-a414541bb/',
                year: "2021-22",
                position: 'core-team-member',
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=13jQxzKJcYVuunBBKkqvUAp62fnFRTIRg',
                name: 'Rashmitha',
                about: "I am a 4th year BTech IT Student . I have been in Lean in since past two years and it has played a pivotal role in shaping my personality.",
                socialMedia: 'https://www.linkedin.com/in/rashmitha-mohan-636234111/',
                year: "2021-22",
                position: 'core-team-member',
            },
            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=12BerlNfOVYa7bS2wvx7EJ33J6QraHtVW',
                name: 'Riddhi Chaudhary',
                about: "My experience at Lean In has helped me grow into a better version of myself. It brings me great joy to play a part in the smooth operation of society.",
                socialMedia: 'https://www.linkedin.com/in/riddhi-chaudhary/',
                year: "2021-22",
                position: 'core-team-member',
            },
            {
                id: 6,
                profilePic: 'https://drive.google.com/uc?export=view&id=1SJuObpHKmjSDPQeEojX63T2RIi6K1Lib',
                name: 'Simran Vishrant',
                about: "CSE Undergrad | Grateful to be the part of this wonderful family- Lean In IGDTUW. It's been an amazing experience, full of new learnings and great experiences.",
                socialMedia: 'https://www.linkedin.com/in/simran-vishrant-a70911207/',
                year: "2021-22",
                position: 'core-team-member',
            },
            {
                id: 7,
                profilePic: 'https://drive.google.com/uc?export=view&id=1kgWw7BcIDJkuT_PfYGHh_9ZcJiA6yXor',
                name: 'Sukriti Dawar',
                about: "It's been a wonderful experience at Lean In IGDTUW. I have made a lot of memories till now and have learnt a lot of new skills. Really grateful to all my seniors for helping me throughout and giving me this amazing opportunity!",
                socialMedia: 'https://www.linkedin.com/in/sukriti-dawar/',
                year: "2021-22",
                position: 'core-team-member',
            },
        ],
        website_team: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1HQvHa2UgoECWw-GdsGMxju3jiF5CpS3x',
                name: 'Punerva Singh',
                about: "Working on the official website of Lean In helped me broaden my horizon. Although the journey was difficult, the outcome was worthwhile.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2021-22",
                position: 'website-team',
            },
        ]
    },
    {
        year: "2020-21",
        president: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1GIH8EAAmQZB4O5jRd1o6qJVBveWxFNWy',
                name: 'Jap leen Kaur Jolly',
                about: "Leaning In has become a lifestyle for me, and Lean IN IGDTUW, a family!",
                socialMedia: 'https://www.linkedin.com/in/jap-leen/',
                year: "2020-21",
                position: 'president',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1EtQkuU7pECH2EvCOMSEVRGQ73crQRMRe',
                name: 'Prateeksha Gupta',
                about: "The best part about Lean IN is it is not just a technical society, but a community where everybody learns and grows together!",
                socialMedia: 'https://www.linkedin.com/in/prateeksha-gupta18',
                year: "2020-21",
                position: 'president',
            },
        ],
        core_team_member: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1kgWw7BcIDJkuT_PfYGHh_9ZcJiA6yXor',
                name: 'Sukriti Dawar',
                about: "It's been a wonderful experience at Lean In IGDTUW. I have made a lot of memories till now and have learnt a lot of new skills. Really grateful to all my seniors for helping me throughout and giving me this amazing opportunity!",
                socialMedia: 'https://www.linkedin.com/in/sukriti-dawar/',
                year: "2020-21",
                position: 'core-team-member',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1XFzkeiT8kqnLnlIJUi71x_fLzbzwCzCw',
                name: 'Karishma Singh Chauhan',
                about: "Currently a software engineer, I am a CSE undergrad from the class of 2022. I had a meaningful learing experience at Lean In, IGDTUW - both as a core member and a mentee.",
                socialMedia: 'https://www.linkedin.com/in/karishma-singh-chauhan/',
                year: "2020-21",
                position: 'core-team-member',
            },
            {
                id: 3,
                profilePic: 'https://media-exp2.licdn.com/dms/image/C4D03AQHclusSPyWyqA/profile-displayphoto-shrink_400_400/0/1655317771973?e=1662595200&v=beta&t=cHM2gHQmNGgRjZso2dzX8R8NrT1krIx09X_Yy7mKB0E',
                name: 'Apoorva Gupta',
                about: "Software Engineer | CSE Graduate. Best decision of my college life was to join Lean In, it will always be close to my heart!",
                socialMedia: 'https://www.linkedin.com/in/apoorvagupta28/',
                year: "2020-21",
                position: 'core-team-member',
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=1j3lkOh7jCVMIq6w2ZOKU9V7tumJpLry1',
                name: 'Divya Pant',
                about: "ASIC Digital Design Engineer | I am an ECE graduate from batch 2018-22. During the tenure of four years, being part of Lean In was the best experience. These years were full of learning, networking, and collaborating. Glad to be a part of this amazing family.",
                socialMedia: 'https://www.linkedin.com/in/divya-pant-094a0819a/',
                year: "2020-21",
                position: 'core-team-member',
            },
            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=19PcLLV56nLXKFfkzpUzhu0K_eYU74VfN',
                name: 'Hrizul Gaur',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/hrizul-gaur-6a1370169/',
                year: "2020-21",
                position: 'core-team-member',
            },
            {
                id: 6,
                profilePic: 'https://drive.google.com/uc?export=view&id=13jQxzKJcYVuunBBKkqvUAp62fnFRTIRg',
                name: 'Rashmitha',
                about: "I am a 4th year BTech IT Student . I have been in Lean in since past two years and it has played a pivotal role in shaping my personality.",
                socialMedia: 'https://www.linkedin.com/in/rashmitha-mohan-636234111/',
                year: "2020-21",
                position: 'core-team-member',
            },
            {
                id: 7,
                profilePic: 'https://drive.google.com/uc?export=view&id=1oKh7ftNGgtsXvNkV6bqIWm0aBuLU-KEU',
                name: 'Suchi Jha',
                socialMedia: 'https://www.linkedin.com/in/suchi-jha-798091176/',
                year: "2020-21",
                position: 'core-team-member',
            },
        ],
        website_team: [],
    },
    {
        year: "2019-20",
        president: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1mQnftqjLoc5INMVKN4-jqHGyElrf0btR',
                name: 'Apoorva Singh',
                about: "A dreamer who is perpetually curious about products, design and pshychology. It was a beautiful coincidence when I discovered the Lean In Chapter of my university just a couple of months after reading the book. Albeit in its infancy, joining Lean In as a Core Member at that stage opened me up to a whole new world of learning, unlearning, mentorship and the best of all, sisterhood.",
                socialMedia: 'https://www.linkedin.com/in/apoorvasingh26/',
                year: "2019-20",
                position: 'president',
            },
        ],
        core_team_member: [
            {
                id: 1,
                profilePic: 'https://media-exp2.licdn.com/dms/image/C4D03AQHclusSPyWyqA/profile-displayphoto-shrink_400_400/0/1655317771973?e=1662595200&v=beta&t=cHM2gHQmNGgRjZso2dzX8R8NrT1krIx09X_Yy7mKB0E',
                name: 'Apoorva Gupta',
                about: "Software Engineer | CSE Graduate. Best decision of my college life was to join Lean In, it will always be close to my heart!",
                socialMedia: 'https://www.linkedin.com/in/apoorvagupta28/',
                year: "2019-20",
                position: 'core-team-member',
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1j3lkOh7jCVMIq6w2ZOKU9V7tumJpLry1',
                name: 'Divya Pant',
                about: "ASIC Digital Design Engineer | I am an ECE graduate from batch 2018-22. During the tenure of four years, being part of Lean In was the best experience. These years were full of learning, networking, and collaborating. Glad to be a part of this amazing family.",
                socialMedia: 'https://www.linkedin.com/in/divya-pant-094a0819a/',
                year: "2019-20",
                position: 'core-team-member',
            },
            {
                id: 3,
                profilePic: 'https://drive.google.com/uc?export=view&id=1tdZ7CiP9yxdgu6CNpsO5p5E0hulptNFE',
                name: 'Stuti Chauhan',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/dulcetsoul/',
                year: "2019-20",
                position: 'core-team-member',
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=1oKh7ftNGgtsXvNkV6bqIWm0aBuLU-KEU',
                name: 'Suchi Jha',
                socialMedia: 'https://www.linkedin.com/in/suchi-jha-798091176/',
                year: "2019-20",
                position: 'core-team-member',
            },
            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=19PcLLV56nLXKFfkzpUzhu0K_eYU74VfN',
                name: 'Hrizul Gaur',
                socialMedia: 'https://www.linkedin.com/in/hrizul-gaur-6a1370169/',
                year: "2019-20",
                position: 'core-team-member',
            },
            {
                id: 6,
                profilePic: 'https://drive.google.com/uc?export=view&id=1GIH8EAAmQZB4O5jRd1o6qJVBveWxFNWy',
                name: 'Jap leen Kaur Jolly',
                about: "Leaning In has become a lifestyle for me, and Lean IN IGDTUW, a family!",
                socialMedia: 'https://www.linkedin.com/in/jap-leen/',
                year: "2019-20",
                position: 'core-team-member',
            },
            {
                id: 7,
                profilePic: 'https://drive.google.com/uc?export=view&id=1EtQkuU7pECH2EvCOMSEVRGQ73crQRMRe',
                name: 'Prateeksha Gupta',
                about: "The best part about Lean IN is it is not just a technical society, but a community where everybody learns and grows together!",
                socialMedia: 'https://www.linkedin.com/in/prateeksha-gupta18',
                year: "2019-20",
                position: 'core-team-member',
            },
        ],
        website_team: [],
    },
    {
        year: "2018-19",
        president: [],
        core_team_member: [],
        website_team: [],
    },
    {
        year: "2017-18",
        president: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1vTK8PgvOs8okeG22FSAVcxkJ-tfEvrHJ',
                name: 'Navya Singh',
                about: "Diversity Propagator by passion and a Big Data Developer by profession. I had the fortune of leading Lean In during its re-modelling phase in 2017-18 and the memories and stories still brighten my days! ",
                socialMedia: 'https://www.linkedin.com/in/navya-singh/',
                year: "2017-18",
                position: 'president',
            },
        ],
        core_team_member: [],
        website_team: [],
    },
    {
        year: "2016-17",
        president: [],
        core_team_member: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1vTK8PgvOs8okeG22FSAVcxkJ-tfEvrHJ',
                name: 'Navya Singh',
                about: "Diversity Propagator by passion and a Big Data Developer by profession. I had the fortune of leading Lean In during its re-modelling phase in 2017-18 and the memories and stories still brighten my days! ",
                socialMedia: 'https://www.linkedin.com/in/navya-singh/',
                year: "2016-17",
                position: 'core-team-member',
            },
        ],
        website_team: [],
    },
]

export default MemberDetail;