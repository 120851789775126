import React from "react";
import "./singleBlog.css";
import { useLocation } from "react-router";
import blogData from "../../Data/blogData";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export default function SingleBlog() {
    const location = useLocation();
    const path = location.pathname.split("/")[2];

    return (
        <>
            {blogData.map((blog) => {
                if (blog.id === path) {
                    return (
                        <>
                            <div className="single-blog-page">
                                <div className="close-icon">
                                    <Link to={'/alumni'}>
                                        <CloseIcon />
                                    </Link>
                                </div>
                                <p>{blog.category}</p>
                                <h1>{blog.author}</h1>
                                <img className="blog-author-img" src={blog.img} alt="blog-author-img" />
                                <p>{blog.heading}</p>
                                <div className="flex">
                                    <div className="flex-left blog-date">
                                        {blog.publish_date}
                                    </div>
                                    <div className="flex-right blog-read-time">
                                        {blog.reading_time}
                                    </div>
                                </div>
                                <div className="blog-content">
                                    {blog.content.map((b) => {
                                        return(
                                            <>
                                            <p>{b.ques}</p>
                                            <p>{b.ans}</p>
                                            <br />
                                            <br />
                                            </>
                                        )}
                                    )}
                                </div>
                            </div>
                        </>
                    )
                } else {
                    return <></>
                }
            })}
        </>
    );
}
