import React, { useState } from "react";
import { useLocation } from "react-router";
import "./SingleCircle.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import Timeline from "../../components/timeline/Timeline";
import Macbook from "../../SVG/SingleCircle/Mackbook";
import CircleInfo from "../../Data/CircleInfo";
import Slider from "../../components/feedback-slider/Slider";

export default function SingleCircle() {
    const location = useLocation();
    const path = location.pathname.split("/")[2];

    const [search, setNewSearch] = useState("");

    const handleSearchChange = (e) => {
        setNewSearch(e.target.value);
    };

    return (
        <>
            <Header />
            {CircleInfo.map((circle) => {
                if (circle.id === path) {
                    let feedback_len = circle.feedback.length;

                    return (
                        <div className="single-page">
                            <div className="flex">
                                <div className="flex-left">
                                    <Macbook />
                                </div>
                                <div className="flex-right">
                                    <div className="circle-header">
                                        <h1 className="underline-txt">{circle.name}</h1>
                                        <p className="circle-header-desc">{circle.tagline}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="scroll">
                                <p class="ctn-icon">
                                    <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                                </p>
                            </div>
                            <div className="container-circle">
                                <div className="tabs">
                                    <a href="#about">About</a>
                                    <a href="#dates">Important Dates</a>
                                    <a href="#timeline">Course Timeline</a>
                                    <a href="#mentor-list">Circle Mentors</a>
                                    {feedback_len > 0 &&
                                        <a href="#feedback">Feedback</a>
                                    }
                                    <span className="glider"></span>
                                </div>
                            </div>
                            <div id="about" className="circle-about">
                                <h2 className="single-circle-heading heading-underline">About</h2>
                                <p>{circle.about}</p>
                                <h2 className="single-circle-heading heading-underline">Why join this circle?</h2>
                                <p>{circle.reason}</p>
                            </div>
                            <div id="dates" className="dates">
                                <h2 className="single-circle-heading heading-underline">Important Dates</h2>
                                <div className="dates-cards">
                                    {circle.dates.map((d) => {
                                        return (
                                            <div className="dates-card">
                                                <div className="img-wrapper">
                                                    <img className="date-card-img" src={d.img} alt="registration dates" />
                                                </div>
                                                <div className="inner-div-card">
                                                    <p className="circle-card-title">{d.name}</p>
                                                    <p className="card-duration-para">{d.startdate} - {d.enddate}</p>
                                                    <div className="card-button">
                                                        <div className="card-button-text mt-4 btn custom-btn custom-btn-bg ">

                                                            {/* If the status is closed then button will show closed, 
                                                            If the status is open, button will show apply now And
                                                            If the status is check, it means it is of course duration,
                                                            If the status is none of the above, it means it is upcoming, button will show coming soon.  */}
                                                            {d.status === 'closed' ?
                                                                (
                                                                    <button className="circle-link" style={{ cursor: "not-allowed", background: "none", border: "none", fontSize: "18px", fontFamily: 'Titillium Web', letterSpacing: "0.8px" }}>
                                                                        Closed Now
                                                                    </button>
                                                                )
                                                                : (
                                                                    d.status === 'open' ?
                                                                (
                                                                    <a className="circle-link" href={d.link}>Apply Now
                                                                        <i class="fas fa-fw fa-arrow-right fa-2x hackathon-arrow"></i>
                                                                    </a>
                                                                )
                                                                : (
                                                                    d.status === 'check' ?
                                                                (
                                                                    <a className="circle-link" href={d.link}>Check Here
                                                                        <i class="fas fa-fw fa-arrow-right fa-2x hackathon-arrow"></i>
                                                                    </a>
                                                                )
                                                                : (
                                                                    <button className="circle-link" style={{ cursor: "not-allowed", background: "none", border: "none", fontSize: "18px", fontFamily: 'Titillium Web', letterSpacing: "0.8px"  }}>
                                                                        Coming Soon
                                                                    </button>
                                                                )
                                                                )
                                                                )}
                                                        </div>
                                                        <div className="card-button-icon"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div id="timeline" className="timeline">
                                <h2 className="single-circle-heading heading-underline">Course Timeline</h2>
                                <Timeline timeline={circle.timeline} />
                            </div>
                            <div id="mentor-list" className="mentor">
                                <h2 className="single-circle-heading heading-underline">Mentors</h2>
                                <form className="circle-mentor-year" action="">
                                    <label for="year">Year:</label>
                                    <select className="select-content" name="year" id="year" form="yearform" onChange={handleSearchChange}>
                                        {/* <option value="2022-23">2022-23</option> */}
                                        <option value="2021-22">2021-22</option>
                                        {/* <option value="2020-21">2020-21</option>
                                        <option value="2019-20">2019-20</option>
                                        <option value="2018-19">2018-19</option> */}
                                    </select>
                                </form>
                                <div class="circle-member-div">
                                    <div class="circle-members">
                                        {circle.mentor.map((m) => {
                                            if (!search) {
                                                if (m.year === "2021-22") {
                                                    return (
                                                        <div class="circle-member-img-div">
                                                            <div class="circle-member-img">
                                                                <img class="cmember-img" src={m.profilePic} alt="member-img" />
                                                            </div>
                                                            <div class="circle-hidden-div">
                                                                <div class="circle-member-content">
                                                                    <h3>{m.name}</h3>
                                                                    <p class="about-circle-member">{m.about}</p>
                                                                    <div className="social-icons" style={{ zIndex: "100" }}>
                                                                        <a target="_blank" rel="noreferrer" href={m.socialMedia} style={{ cursor: "pointer" }}>
                                                                            <i className="member-social-icon fab fa-linkedin-in"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return <></>
                                                }
                                            } else if (m.year === search) {
                                                return (
                                                    <div class="circle-member-img-div">
                                                        <div class="circle-member-img">
                                                            <img class="cmember-img" src={m.profilePic} alt="member-img" />
                                                        </div>
                                                        <div class="circle-hidden-div">
                                                            <div class="circle-member-content">
                                                                <h3>{m.name}</h3>
                                                                <p class="about-circle-member">{m.about}</p>
                                                                <div className="social-icons" style={{ zIndex: "100" }}>
                                                                    <a target="_blank" rel="noreferrer" href={m.socialMedia} style={{ cursor: "pointer" }}>
                                                                        <i className="member-social-icon fab fa-linkedin-in"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                            {/* Feedback section */}
                            {feedback_len > 0 &&
                                <div id="feedback" className="circle-feedback">
                                    <h2 className="single-circle-heading heading-underline circle-feedback-heading">Feedback</h2>

                                    <Slider slides={circle.feedback} />
                                    <div className="arrow-div"></div>
                                </div>
                            }
                        </div>
                    )
                } else {
                    return <></>
                }
            })}
            <Footer />
        </>
    );
}
