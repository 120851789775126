import React from "react";

export default function SingleSlide({prop}) {
    return (
        <div className="white-bg"><div className="flex-row">
            <div className="left-div-top-carousel">
                <p className="top-carousel-heading">{prop.heading}</p>
                <p className="top-carousel-content">{prop.desc}</p>
                {/* <Link className="link" to={prop.link}><button className="top-carousel-button">{prop.linkName}</button></Link> */}
            </div>
            <div className="right-div">
                <img className="top-carousel-img" style={{ objectFit: "cover" }} src={prop.img} alt="top-carousel-img" />
            </div>
        </div>
        </div>
    );
}