import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

export default function Navbar() {
    const [open, setOpen] = useState(false);

    return (
        <div className="container">
            <input type="checkbox" id="navi-toggle" className="checkbox" checked={open} onChange={() => setOpen(!open)} />
            <label for="navi-toggle" className="button">
                <span className="icon"></span>
            </label>
            <div className="overlay"></div>
            <div className="background"></div>

            <nav className="nav">
                <ul className="list">
                    <li className="item"> <Link className="link" to="/" onClick={() => setOpen(!open)}> Home <i className="fas fa-home navbar-icon"></i> </Link> </li>
                    <li className="item"> <Link className="link" to="/circles" onClick={() => setOpen(!open)}> Circles <i className="fas fa-layer-group navbar-icon"></i> </Link> </li>
                    <li className="item"> <Link className="link" to="/hackathon" onClick={() => setOpen(!open)}> Hackathon <i className="fas fa-laptop fa-2x navbar-icon"></i> </Link> </li>
                    <li className="item"> <Link className="link" to="/alumni" onClick={() => setOpen(!open)}> Alumni Insights<i className="fas fa-graduation-cap navbar-icon"></i> </Link> </li>
                    <li className="item"> <Link className="link" to="/chronicles" onClick={() => setOpen(!open)}> Chronicles<i className="fas fa-folder-open fa-2x navbar-icon"></i> </Link> </li>
                    <li className="item"> <Link className="link" to="/opportunity" onClick={() => setOpen(!open)}> Opportunities<i className="fas fa-list fa-2x navbar-icon"></i>  </Link> </li>
                    {/* <li className="item"> <Link className="link" to="/gallery" onClick={() => setOpen(!open)}> Gallery <i className="fas fa-images navbar-icon"></i> </Link> </li> */}
                    <li className="item"> <Link className="link" to="/team" onClick={() => setOpen(!open)}> Team <i className="fas fa-users navbar-icon"></i> </Link> </li>
                    {/* <li className="item"> <Link className="link" to="/contact" onClick={() => setOpen(!open)}> Contact <i className="fas fa-envelope navbar-icon"></i> </Link> </li> */}
                </ul>
            </nav>

        </div>
    );
}
