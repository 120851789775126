const eventsData = [
    {
        img: "https://drive.google.com/uc?export=view&id=1AwCInZ93QLZkqnCGSvhBmCRbvqkytW4B",
        date: "5 September 2020",
        category: "Completed",
        name: "CodeMantra, Path for Interview Preparation",
        company: "Lean In IGDTUW",
        desc: "Mock test on Interview Preparation in collaboration with Coding Ninjas.",
        link: "https://www.instagram.com/p/CEejRGLMolP/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=11U-Bs8Y8MBWeUKTYvSd9CKGm9Eto-DjK",
        date: "26 September 2020",
        category: "Completed",
        name: "AptiTalent, Let’s Show Off your “APTITUDE”",
        company: "Lean In IGDTUW",
        desc: "Online Aptitude event in collaboration with Coding Ninjas.",
        link: "https://www.instagram.com/p/CFZnwUMMm32/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1dtdebX1zoQzwD3twKgtEwZdzknKiC_f0",
        date: "27 August 2021",
        category: "Completed",
        name: "Coders Assemble!",
        company: "Lean In IGDTUW",
        desc: "Grand Coding Contest in collaboration with Newton School.",
        link: "https://www.instagram.com/p/CSlmYyXJ45T/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=14RY0eGcN3I26rcNU7OLBmGS6d2WtM-eY",
        date: "3 January 2022",
        category: "Completed",
        name: "6 Companies, 30 Days Challenge",
        company: "Lean In IGDTUW",
        desc: "Solve 90 questions of 6 companies in 30 days challenge in collaboration with Arsh Goyal.",
        link: "https://www.instagram.com/p/CYRDVdhJqcO/?utm_source=ig_web_copy_link",
    },
]

export default eventsData;