import "./Card.css";
import { Link } from "react-router-dom";

export default function Card({ prop }) {
  return (
    <div className="card-main-container">
      <div className="card-img">
        <img className="hackathon-img" src={prop.img} alt="hackathon-img" />
        <p className="hackathon-card-duration">{prop.start_date} - {prop.end_date}</p>
      </div>
      <div className="card-title">{prop.name}</div>
      <div className="card-content">
        {prop.theme}
      </div>
      <div className="card-button">
        <Link className="hackathon-link" to={`/SingleHackathon/${prop.id}`}>
          <div className="card-button-text custom-btn-group mt-4 btn custom-btn custom-btn-bg custom-btn-link">
          Know More<i class="fas fa-fw fa-arrow-right fa-2x hackathon-arrow"></i>
          </div>
        </Link>
        <div className="card-button-icon"></div>
      </div>
    </div>
  );
}
