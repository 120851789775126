const TopCarouselData = [
    {
        img: "https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
        heading: "We rise by lifting others ",
        desc: "Lean In IGDTUW has been instrumental in nurturing technical skills amongst the girls of IGDTUW. Where able seniors volunteer as mentors by sharing their experience and knowledge to their juniors , lean in redefines the junior-senior relationship in the university.",
        link: "#",
        linkName: "Circles",
    },
    {
        img: "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
        heading: "First Lean In Chapter in India",
        desc: "Our seniors started the first ever Lean In chapter in India. Since then , Lean In IGDTUW remains one of the most prominent and popular societies in the college. Providing 700+ mentees a place to learn and grow each year.",
        link: "#",
        linkName: "Alumni Insights",
    },
    {
        img: "https://images.unsplash.com/photo-1630344745884-9c93c4593f70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80",
        heading: "Lean In Hacks",
        desc: "Our signature hackathon Lean In Hacks is held every year and attracts 100s of participants across the college to submit their innovative ideas , shortlisted teams are mentored and winners are declared. Along with enhancement in technical skills, it also provide an opportunity for individual contributors to strengthen soft skills like leadership, mentoring and communication.",
        link: "#",
        linkName: "Opportunity",
    },
    {
        img: "https://images.unsplash.com/photo-1573165067541-4cd6d9837902?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80",
        heading: "There’s power in women coming together",
        desc: "Leaning in is not a solo sport. Lean In IGDTUW provides a tight knit support group made of friends, seniors and alumni that guide you throughout your college life. From informative sessions with guest speakers, all embracing events, insightful discussions and interactive workshops, Lean In helps you to step outside your comfort zone and upskill.",
        link: "#",
        linkName: "#",
    },
    {
        img: "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80",
        heading: "Breaking the bias",
        desc: "Lean In provides a platform where women's potential can be identified, their accomplishments promoted, and their successes celebrated, inspiring the next generation of young women students to follow in their footsteps and achieve their dreams, thus bringing about a positive change for women and forging equality.",
        link: "#",
        linkName: "Hackathons",
    },
]

export default TopCarouselData;