import React, { useState } from "react";
import * as Components from './Components';
import Header from "../../components/Header/Header";

export default function Contact() {
    const [signIn, toggle] = React.useState(true);

    const [data, setData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const { name, email, message } = data

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://v1.nocodeapi.com/leaninigdtuw/google_sheets/GCYxSbVeCOEAwfDV?tabId=Sheet1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[name, email, message, new Date().toLocaleString()]])
            });
            await response.json();
            setData({ ...data, name: "", email: "", message: "" })
        } catch (err) {
            console.log(err)
        }
    }

    const [feed_data, setFeedbackData] = useState({
        feed_name: "",
        feed_email: "",
        feed_message: "",
    });

    const { feed_name, feed_email, feed_message } = feed_data

    const handleFeedbackChange = (e) => {
        setFeedbackData({ ...feed_data, [e.target.name]: e.target.value });
    };

    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://v1.nocodeapi.com/leaninigdtuw/google_sheets/KexVfilFpCQtURHI?tabId=Sheet1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[feed_name, feed_email, feed_message, new Date().toLocaleString()]])
            });
            await response.json();
            setFeedbackData({ ...data, feed_name: "", feed_email: "", feed_message: "" })
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <Header />
            {/* NOTE - ONLY 300 MESSAGES ALLOWED IN A MONTH FOR FREE, THROUGH NOCODEAPI */}
            {/* <FormComponent /> */}
            {/* <h1>Contact Page</h1> */}
            <div className="contact-div" >
                <Components.Container>
                    <Components.SignUpContainer signinIn={signIn}>
                        <Components.Form onSubmit={handleFeedbackSubmit}>
                            <Components.Title>Feedback</Components.Title>
                            <Components.Input type='text' placeholder='Name' name="feed_name" value={feed_name} onChange={handleFeedbackChange} />
                            <Components.Input type='email' placeholder='Email' name="feed_email" value={feed_email} onChange={handleFeedbackChange} />
                            <Components.Input type='text' placeholder='Feedback' name="feed_message" value={feed_message} onChange={handleFeedbackChange} />
                            <Components.Button>Submit</Components.Button>
                            <Components.SwitchButton onClick={() => toggle(true)}>
                                Contact Us
                            </Components.SwitchButton>
                        </Components.Form>
                    </Components.SignUpContainer>

                    <Components.SignInContainer signinIn={signIn}>
                        <Components.Form onSubmit={handleSubmit}>
                            <Components.Title>Contact Us</Components.Title>
                            <Components.Input type='text' placeholder='Name' name="name" value={name} onChange={handleChange} />
                            <Components.Input type='email' placeholder='Email' name="email" value={email} onChange={handleChange} />
                            <Components.Input type='text' placeholder='Message' name="message" value={message} onChange={handleChange} />
                            <Components.Button>Submit</Components.Button>
                            <Components.SwitchButton onClick={() => toggle(false)}>
                                Give Feedback
                            </Components.SwitchButton>
                        </Components.Form>
                    </Components.SignInContainer>

                    <Components.OverlayContainer signinIn={signIn}>
                        <Components.Overlay signinIn={signIn}>

                            <Components.LeftOverlayPanel signinIn={signIn}>
                                <Components.Title style={{ color: "white" }}>Thank You!</Components.Title>
                                <Components.Paragraph>
                                    We welcome your valuable feedback.
                                </Components.Paragraph>
                                <Components.GhostButton onClick={() => toggle(true)}>
                                    Contact Us
                                </Components.GhostButton>
                            </Components.LeftOverlayPanel>

                            <Components.RightOverlayPanel signinIn={signIn}>
                                <Components.Title style={{ color: "white" }}>Hello, Techies!</Components.Title>
                                <Components.Paragraph>
                                    Enter Your name, email and the message that you want to convey to LeanIn IGDTUW.
                                </Components.Paragraph>
                                <Components.GhostButton onClick={() => toggle(false)}>
                                    Give Feedback
                                </Components.GhostButton>
                            </Components.RightOverlayPanel>

                        </Components.Overlay>
                    </Components.OverlayContainer>

                </Components.Container>
            </div>
        </>
    );
}