import React, { useState, useEffect } from "react";
import Home from "./pages/home/Home";
import Circles from "./pages/circles/Circles";
import Hackathon from "./pages/hackathon/Hackathon";
import Alumni from "./pages/alumni/Alumni";
import Opportunity from "./pages/opportunity/opportunity";
import Team from "./pages/team/Team";
import Gallery from "./pages/gallery/Gallery";
import Chronicles from "./pages/chronicles/Chronicles";
import Contact from "./pages/contact/Contact";
import SingleCircle from "./pages/SingleCircle/SingleCircle";
import SingleHackathon from "./pages/SingleHackathon/SingleHackathon";
import SingleBlog from "./pages/SingleBlog/SingleBlog.jsx";
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import Sessions from "./pages/Sessions/Sessions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Popup from "./components/Popup/Popup";
import DarkMode from "./DarkMode";
// import DotRing from "./components/DotRing/DotRing";
// import { MouseContext } from "./context/mouse-context";

function App() {
  const [buttonPopup, setButtonPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setButtonPopup(true);
    }, 1000);
  }, []);

  // const { cursorType, cursorChangeHandler } = useContext(MouseContext);

  return (
    // <div className="App">Hello LeanIn</div>
    <Router>
      {/* <DotRing /> */}
      <ScrollToTop>
      <DarkMode />
      {/* <Popup trigger={buttonPopup} setTrigger={setButtonPopup}></Popup> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/circles" element={<Circles />} />
        <Route exact path="/SingleCircle/:circleId" element={<SingleCircle />} />
        <Route exact path="/hackathon" element={<Hackathon />} />
        <Route exact path="/SingleHackathon/:hackathonId" element={<SingleHackathon />} />
        <Route exact path="/SingleBlog/:blogId" element={<SingleBlog />} />
        <Route exact path="/alumni" element={<Alumni />} />
        <Route exact path="/chronicles" element={<Chronicles />} />
        <Route exact path="/sessions/:Id" element={<Sessions />} />
        <Route exact path="/opportunity" element={<Opportunity />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
