import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";

export default function Footer() {
    const [data, setData] = useState("");

    const handleChange = (e) => {
        setData(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://v1.nocodeapi.com/leaninigdtuw/google_sheets/VRQTGbstSoeeIGcj?tabId=Sheet1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[data, new Date().toLocaleString()]])
            });
            await response.json();
            setData("")
        } catch (err) {
            console.log(err)
        }
    }
    
    return (
        <div className="footer-div">
            <footer className="footer">
                <div className="newsletter-div">
                    <form className="nd" onSubmit={handleSubmit}>
                        <span>Subscribe to Lean In IGDTUW's Newsletter</span>
                        <input type="email" placeholder="Enter your email" name="email" value={data} onChange={handleChange} style={{ width: "40%", padding: "5px 10px", margin: "0 20px 10px" }} />
                        <button type="submit" className="opportunity-btn">Subscribe</button>
                    </form>
                </div>
                <hr className="footer-hr" />
            </footer>
            <div className="b-footer">
                <p className="footer-page-link">
                    <Link className="link" to="/contact"> Contact Us <i className="fas fa-envelope footer-icon"></i> </Link>
                    <Link className="link gallery-link" to="/gallery"> Gallery <i className="fas fa-images footer-icon"></i> </Link>
                </p>
                <div className="footer-div-inner"><p className="footer-div-inner-para"> All rights reserved by ©LeanInIGDTUW {new Date().getFullYear()} </p></div>
                <div className="social-icons">
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/leanin.igdtuw/"><i className=" social-icon fab fa-instagram"></i></a>
                    <a target="_blank" rel="noreferrer" className="footer-social-link" href="https://www.linkedin.com/company/lean-in-igdtuw/mycompany/"><i className="social-icon fab fa-linkedin-in"></i></a>
                    <a target="_blank" rel="noreferrer" className="footer-social-link" href="https://forms.gle/sZLPGFqsy73n3ruo7"><i className="social-icon fab fa-discord"></i></a>
                    <a target="_blank" rel="noreferrer" className="footer-social-link" href="https://twitter.com/Lean_In_IGDTUW"><i className="social-icon fab fa-twitter"></i></a>
                    <a target="_blank" rel="noreferrer" className="footer-social-link" href="https://www.youtube.com/channel/UCCLE63el4b2MmzTMsaTVKSg"><i className="social-icon fab fa-youtube"></i></a>
                </div>
            </div>
        </div>
    );
}