import React from "react";
import "./hackathonSVG.css";

export default function HackathonSVG() {
    return (
        <svg className="animatedsvg hackathon-computer-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 976.883 390.52">
            <defs>
                <clipPath id="a">
                    <path d="M155.769 332.538h99.106a2.293 2.293 0 0 1 2.293 2.293v41.064H153.477v-41.064a2.293 2.293 0 0 1 2.292-2.293z" fill="none" />
                </clipPath>
            </defs>
            <g data-name="Layer 2">
                <path fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 6" d="M807.918 45.769h35.504M878.145 203.27h-17.516a20.55 20.55 0 0 0-20.55 20.549V294.9" />
                <path fill="rgba(255, 255, 255, 0.293)" stroke="black" stroke-miterlimit="10" stroke-width="2" d="M429.815 353.202h122.226v22.692H429.815zM175.075 7.159h601.056a2.394 2.394 0 0 1 2.394 2.394v341.255a2.394 2.394 0 0 1-2.394 2.394H167.567a2.394 2.394 0 0 1-2.394-2.394V17.061a9.9 9.9 0 0 1 9.902-9.902z" />
                <path d="M204.468 7.159h593.548a9.9 9.9 0 0 1 9.9 9.9v333.749a2.394 2.394 0 0 1-2.394 2.394H196.961a2.394 2.394 0 0 1-2.394-2.394V17.061a9.9 9.9 0 0 1 9.901-9.902z" fill="rgba(255, 255, 255, 0.293)" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M64.022 375.894h839.46" />
                <path d="M636.506 302.632H239.634a.8.8 0 0 1-.8-.8V71.533a.8.8 0 0 1 .8-.8h396.872a.8.8 0 0 1 .8.8v132.1" fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                <path fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 6" d="M637.306 210.192v88.626" />
                <path d="M305.488 241.158L379 174.9a4.7 4.7 0 0 1 6.1-.157l40.808 33.139a4.7 4.7 0 0 0 6.2-.246l62.392-59.445a4.7 4.7 0 0 1 6.965.542l69.814 92.425z" fill="red" />
                <circle cx="333.808" cy="143.496" r="25.053" fill="black" />
                <circle cx="438.07" cy="186.683" r="38.934" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <path fill="black" d="M428.948 170.394v32.577l21.261-16.288-21.261-16.289z" />
                <rect x="652.71" y="70.733" width="110.941" height="64.564" rx=".8" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <rect x="652.71" y="154.401" width="110.941" height="64.564" rx=".8" fill="black" />
                <rect x="652.71" y="238.068" width="110.941" height="64.564" rx=".8" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <path d="M677.812 117.037l16.8-15.141a1.072 1.072 0 0 1 1.395-.036l9.325 7.572a1.072 1.072 0 0 0 1.417-.056L721 95.792a1.074 1.074 0 0 1 1.592.124l15.953 21.121z" fill="black" />
                <circle cx="684.283" cy="94.72" r="5.725" fill="black" />
                <path d="M677.812 200.7l16.8-15.141a1.073 1.073 0 0 1 1.395-.036l9.325 7.572a1.072 1.072 0 0 0 1.417-.056L721 179.459a1.074 1.074 0 0 1 1.592.124l15.957 21.117z" fill="#f1f1f2" />
                <circle cx="684.283" cy="178.387" r="5.725" fill="#f1f1f2" />
                <path d="M677.812 284.371l16.8-15.141a1.073 1.073 0 0 1 1.395-.036l9.325 7.573a1.073 1.073 0 0 0 1.417-.057L721 263.127a1.074 1.074 0 0 1 1.592.123l15.953 21.121z" fill="black" />
                <circle cx="684.283" cy="262.054" r="5.725" fill="black" />
                <rect x="238.834" y="38.366" width="361.462" height="15.681" rx="7.841" fill="#fff" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <rect x="611.299" y="38.366" width="26.007" height="15.681" rx="7.841" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="625.184" cy="45.037" r="2.913" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M619.794 50.427l3.33-3.33" />
                <path fill="black" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M277.828 270.35h322.468" />
                <path fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" d="M440.13 353.202h122.226v22.692H440.13zM807.151 321.419H194.566" />
                <circle cx="501.242" cy="337.391" r="5.945" fill="black" />
                <circle cx="501.242" cy="20.284" r="2.363" fill="black" />
                <circle cx="477.026" cy="270.365" r="5.177" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <rect x="741.004" y="39.256" width="22.647" height="12.06" rx="5.548" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="757.621" cy="45.286" r="6.03" fill="black" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M728.055 42.453h-17.722M728.055 48.8h-17.722" />
                <circle cx="250.979" cy="46.207" r="3.471" fill="black" />
                <circle cx="264.196" cy="46.207" r="3.471" fill="black" />
                <circle cx="277.414" cy="46.207" r="3.471" fill="black" />
                <circle cx="290.631" cy="46.207" r="3.471" fill="black" />
                <circle cx="303.849" cy="46.207" r="3.471" fill="black" />
                <rect x="87.544" y="197.038" width="209.017" height="148.697" rx="2.394" transform="rotate(-90 192.053 271.386)" fill="rgba(255, 255, 255, 0.293)" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <rect x="100.814" y="197.038" width="209.017" height="148.697" rx="2.394" transform="rotate(-90 205.322 271.386)" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <rect x="153.477" y="194.733" width="103.691" height="57.982" rx="2.293" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <rect x="153.477" y="263.635" width="103.691" height="57.982" rx="2.293" fill="black" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <path d="M155.769 332.538h99.106a2.293 2.293 0 0 1 2.293 2.293v41.064H153.477v-41.064a2.293 2.293 0 0 1 2.292-2.293z" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <path d="M171.227 252.714l26.694-24.06a1.7 1.7 0 0 1 2.216-.057l14.819 12.033a1.706 1.706 0 0 0 2.252-.089l22.656-21.586a1.705 1.705 0 0 1 2.529.2l14.775 19.26v10.207a4.1 4.1 0 0 1-4.095 4.095z" fill="black" />
                <circle cx="181.511" cy="217.251" r="9.097" fill="black" />
                <path d="M171.716 322.4l26.694-24.061a1.707 1.707 0 0 1 2.217-.057l14.819 12.034a1.706 1.706 0 0 0 2.251-.089l22.656-21.586a1.706 1.706 0 0 1 2.53.2l14.774 19.26v11.428a2.875 2.875 0 0 1-2.875 2.875z" fill="#f1f1f2" stroke="#f1f1f2" stroke-miterlimit="10" stroke-width="1.5" />
                <circle cx="181.511" cy="286.153" r="9.097" fill="#f1f1f2" stroke="#f1f1f2" stroke-miterlimit="10" stroke-width="1.5" />
                <g clip-path="url(#a)" fill="black">
                    <path d="M171.227 390.52l26.694-24.061a1.705 1.705 0 0 1 2.216-.057l14.819 12.034a1.707 1.707 0 0 0 2.252-.089l22.656-21.586a1.7 1.7 0 0 1 2.529.2l14.775 19.26v10.207a4.1 4.1 0 0 1-4.095 4.1z" />
                    <circle cx="181.511" cy="355.056" r="9.097" />
                </g>
                <circle cx="214.003" cy="179.074" r="4.259" fill="#fff" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <path d="M130.974 373.5v-16.407h148.7V373.5a2.394 2.394 0 0 1-2.394 2.394H133.368a2.394 2.394 0 0 1-2.394-2.394z" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <circle cx="205.322" cy="366.27" r="4.966" fill="black" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M203.131 178.936h-11.078" />
                <rect x="153.477" y="263.635" width="103.691" height="57.982" rx="2.293" fill="none" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <path d="M347.3 233.763v139.045c0 1.6 7.4 2.89 5.8 2.89h-83.809a2.89 2.89 0 0 1-2.89-2.89V233.763a2.89 2.89 0 0 1 2.89-2.891H353.1c1.6 0-5.8 1.294-5.8 2.891z" fill="black" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <rect x="243.633" y="262.835" width="144.825" height="80.9" rx="2.394" transform="rotate(-90 316.045 303.285)" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <circle cx="316.045" cy="358.078" r="6.293" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <circle cx="316.045" cy="358.078" r="6.293" fill="#fff" stroke="black" stroke-miterlimit="10" stroke-width="1.5" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M308.82 239.271h14.451" />
                <path d="M288.814 285.209l15.063-13.577a.961.961 0 0 1 1.25-.032l8.362 6.791a.963.963 0 0 0 1.271-.051l12.784-12.18a.963.963 0 0 1 1.428.111l14.305 18.938z" fill="black" />
                <circle cx="294.617" cy="265.198" r="5.134" fill="black" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M288.814 294.623h54.463M288.814 303.049h43.494M288.814 319.31h56.136M288.814 326.539h56.136M288.814 333.768h56.136M288.814 340.996h56.136M289.484 251.484h32.855" />
                <path d="M684.569 375.894h-31.44a7.106 7.106 0 0 1-7.106-7.106v-62.4a7.106 7.106 0 0 1 7.106-7.106h31.44v76.612z" fill="black" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <path fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" d="M693.819 299.278h133.383v76.616H693.819z" />
                <path d="M827.2 299.278h31.44a7.106 7.106 0 0 1 7.106 7.106v62.4a7.106 7.106 0 0 1-7.106 7.106H827.2v-76.612zM693.819 375.894h-31.44a7.106 7.106 0 0 1-7.106-7.106v-62.4a7.106 7.106 0 0 1 7.106-7.106h31.44v76.612z" fill="#f1f1f2" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="674.545" cy="319.397" r="8.043" fill="black" />
                <circle cx="846.475" cy="347.288" r="8.043" fill="#fff" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="674.545" cy="340.892" r="2.854" fill="black" />
                <circle cx="674.545" cy="353.683" r="2.854" fill="black" />
                <circle cx="680.941" cy="347.288" r="2.854" fill="none" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="668.15" cy="347.288" r="2.854" fill="none" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="846.475" cy="313.001" r="2.854" fill="none" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="846.475" cy="325.792" r="2.854" fill="none" stroke="black" stroke-miterlimit="10" stroke-width="2" />
                <circle cx="852.87" cy="319.397" r="2.854" fill="black" />
                <circle cx="840.079" cy="319.397" r="2.854" fill="black" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M843.621 365.464h5.708M671.691 365.464h5.708" />
                <path d="M719.463 358.2l22.7-20.465a1.451 1.451 0 0 1 1.886-.049l12.6 10.236a1.451 1.451 0 0 0 1.915-.076l19.271-18.361a1.451 1.451 0 0 1 2.151.168l21.571 28.547z" fill="black" />
                <circle cx="728.21" cy="328.031" r="7.738" fill="black" />
                <circle cx="889.19" cy="45.769" r="45.769" fill="#f1f1f2" />
                <path fill="#fff" d="M906.48 44.609v16.018h-37.999v-4.983l12.667-5.044 12.666 1.533 12.666-7.524z" />
                <path fill="none" stroke="#f47928" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M868.481 52.895v7.049M881.148 44.609v15.335M893.814 47.126v12.818M906.48 34.768v25.176" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M888.179 67.378h18.301" />
                <path fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 4" d="M868.481 67.378h19.698" />
                <path fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M867.256 44.609L881.148 33.3l12.666 3.829 12.666-12.305" />
                <circle cx="868.242" cy="44.483" r="3.973" fill="black" />
                <circle cx="881.148" cy="33.565" r="3.973" fill="black" />
                <circle cx="893.814" cy="37.538" r="3.973" fill="black" />
                <circle cx="906.48" cy="24.824" r="3.973" fill="black" />
                <circle cx="74.537" cy="45.769" r="45.769" fill="#f1f1f2" />
                <circle cx="931.114" cy="202.105" r="45.769" fill="#f1f1f2" />
                <path d="M941.422 186.225a13.942 13.942 0 0 1-6.4 15.487" fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 4" />
                <path d="M919.844 201.057a13.928 13.928 0 1 1 20.237-18.157" fill="none" stroke="#f47928" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
                <path d="M925.034 216.263v-24.581a3.11 3.11 0 0 1 3.111-3.11 3.11 3.11 0 0 1 3.111 3.11V204.6a3.109 3.109 0 0 0 1.744 2.791l15.915 5.312a3.112 3.112 0 0 1 1.741 2.793v9.5a2.261 2.261 0 0 1-2.261 2.261h-26.681a3.107 3.107 0 0 1-2.158-.871l-7.028-6.772a3.111 3.111 0 0 1-.185-4.287l.736-.842a3.112 3.112 0 0 1 4.461-.232l3.868 3.595a2.157 2.157 0 0 0 3.626-1.585z" fill="#fff" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
                <path fill="#f1f1f2" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M931.279 202.683v11.813M937.214 209.008v5.488M943.313 211v3.496" />
                <circle cx="45.769" cy="199.286" r="45.769" fill="#f1f1f2" />
                <path d="M72.691 204.447s-12.015 14.747-26.837 14.747-26.837-14.747-26.837-14.747S31.032 189.7 45.854 189.7s26.837 14.747 26.837 14.747z" fill="#fff" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
                <circle cx="45.854" cy="204.447" r="14.484" fill="#f1f1f2" />
                <path d="M59.248 209.165A19.659 19.659 0 0 0 59.3 198.8" fill="none" stroke="#f47928" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 4" />
                <path d="M58.832 197.444a14.747 14.747 0 1 0-5.975 19.981l-2.006.9a14.682 14.682 0 0 0 7.679-6.338" fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
                <circle cx="45.854" cy="204.447" r="9.99" fill="black" />
                <path fill="none" stroke="#f47928" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="M34.04 181.4l1.557 4.275M45.731 179.238v4.74M57.476 181.697l-1.611 3.978M67.575 187.158l-2.783 3.317M23.888 187.158l2.783 3.317" />
                <path d="M45.769 252.255v29.01a18.013 18.013 0 0 0 18.013 18.013H117.7" fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 6" />
                <path d="M66.007 32.141a13.835 13.835 0 0 1 25.154 1.966 9.416 9.416 0 1 1 2.261 18.556H80.874c4.7-5.655.842-14.824-6.337-12.892a14.424 14.424 0 0 0-8.53-7.63z" fill="#fff" />
                <path d="M87.942 52.663h5.48a9.421 9.421 0 1 0-2.261-18.556 13.835 13.835 0 0 0-25.154-1.966" fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
                <path d="M72.077 54.039h4.206A7.23 7.23 0 1 0 74.547 39.8a10.619 10.619 0 0 0-19.986 0 7.227 7.227 0 1 0-1.735 14.242h4.737" fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" />
                <path fill="#f1f1f2" stroke="#f47928" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 4" d="M64.183 49.936v17.759" />
                <path fill="none" stroke="#f47928" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M60.66 48.693l3.523-3.37 3.522 3.37" />
                <path fill="#f1f1f2" stroke="#f47928" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M79.99 58.414v7.699" />
                <path fill="none" stroke="#f47928" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M76.467 61.282l3.523-3.37 3.522 3.37" />
                <path fill="none" stroke="black" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="0 6" d="M127.505 45.769h37.668" />
            </g>
        </svg>
    );
}