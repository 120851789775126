import Navbar from "../navbar/Navbar";
import { Link } from "react-router-dom";
import "./Header.css";
const Header = () => {
  return (
    <div class="main-header">
      <Link className="link" to="/"><div class="main-header__logo"></div></Link>
      <Navbar />
    </div>
  );
};
export default Header;
