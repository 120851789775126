import React from "react";
import "./chronicles.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import ChronicleGearSVG from "../../SVG/chronicle/ChronicleGearSVG";
import ChronicleGirlSVG from "../../SVG/chronicle/ChronicleGirlSVG";
import ChronicleHandSVG from "../../SVG/chronicle/ChronicleHandSVG";

export default function Chronicles() {

    return (
        <>
            <Header />
            <div className="chronicle-div">
                <div className="chronicle-left-div">
                    <div class="col">
                        <div class="tk-blob">
                            <svg className="chronicle-blob" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440.7 428.7">
                                <path d="M410.6 78.8c36 52.5 36.1 126 19.2 194C412.9 340.7 379 403 330 421.9c-49 19-113.1-5.3-178.6-34C85.8 359.2 18.7 326.1 3.5 276.4-11.7 226.7 25 160.3 71.7 105.3 118.3 50.3 174.8 6.8 239 .7c64.1-6 135.7 25.5 171.6 78.1z" fill="#eb8f8f7a"></path>
                            </svg>
                        </div>
                    </div>
                    <ChronicleGearSVG />
                    <ChronicleGirlSVG />
                </div>
                <div className="chronicle-right-div">
                    <ChronicleHandSVG />
                    <h1 className="heading-underline underline-text chronicle-heading">Lean In chronicles</h1>
                    <p>Sneak-a-Peak into the fun activities organized by Lean In in the previous few years, ranging from informative sessions with guest speakers, insightful group discussions, amazing tips and tricks and resource giveinteractive hands-on workshops, opportunity guidance meetings, exciting hackathons and coding events, conducted in effective collaboration with other organizations with a shared sense of purpose, on various important technical and trending topics.</p>
                </div>
            </div>
            <div className="scroll">
                <p class="ctn-icon">
                    <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                </p>
            </div>
            <div id="container">
                <div class="chronicle-container">
                    <div class="card">
                        <Link to="/sessions/1">
                            <img src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" alt="opportunity-img"/>
                            <div class="card_title">Sessions</div>
                        </Link>
                    </div>
                    <div class="card">
                        <Link to="/sessions/2">
                            <img src="https://images.unsplash.com/photo-1523580494863-6f3031224c94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" alt="opportunity-img"/>
                            <div class="card_title">Events & Collaborations</div>
                        </Link>
                    </div>
                    <div class="card">
                        <Link to="/sessions/3">
                            <img src="https://images.unsplash.com/photo-1551836022-d5d88e9218df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" alt="opportunity-img"/>                        
                            <div class="card_title">Tips and Tricks</div>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}