import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";

export default function Blog({ blog }) {
    return (
        <>
            <div className="blog-container">
                {blog.map((b) => {
                    return (
                        <>
                            <Link to={`/SingleBlog/${b.id}`}>
                                <div className="single-blog" >
                                    <div className="center-blog-heading">
                                        <p className="blog-heading">{b.author}</p>
                                    </div>
                                    <div class="progress">
                                        <div class="color"></div>
                                    </div>
                                    <p className="blog-category">{b.category}</p>
                                    <img className="blog-author-img" src={b.img} alt="blog-author-img" />
                                    <div className="flex">
                                        <div className="flex-left blog-date">
                                            <p>{b.publish_date}</p>
                                        </div>
                                        <div className="flex-right blog-read-time">
                                            <p>{b.reading_time}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </>
                    )
                })}
            </div>
        </>
    );
}