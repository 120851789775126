import React from "react";
import "./gallery.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import GalleryContent from "../../Data/GalleryContent";

export default function Gallery() {
    return (
        <>
            <Header />
            <div className="gallery-div">
                <div className="gallery">
                    {GalleryContent.map((item, index) => {
                        return (
                            <div className="pic-div">
                                <div className="pics" key={index}>
                                    <img className="gallery-img" src={item.imgSrc} alt="gallery-img"/>
                                </div>
                                <div className="hide">{item.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Footer />
        </>
    );
}