const blogData =  [
    {
        id: '1',
        img: 'https://images.unsplash.com/photo-1547082299-de196ea013d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
        heading: 'Insight 1.0',
        category: 'Experience',
        author: 'Vidhi Jindal',
        publish_date: '16 Feb 2022',
        reading_time: '2 min 40 sec read',
        content: [
            {ques: '1. With around 3+ years industrial experience, what advice would you like to give to your juniors stepping into the industry?',
            ans: 'Ans: Do not hesitate. No question is right or wrong. Ask for help if you feel you are stuck. Rather than getting stuck in a problem for a long time, ask for help. Look for mentors in the company and be vocal about your career aspirations.'},
            {ques: '2. You have been a Software Engineer Intern at Microsoft India What tips would you like to share with your juniors, applying for such internships?',
            ans: 'Ans: Prepare well for the interviews, on campus opportunities are the best way to get it. Be confident in interviews. And ofcourse, prepare and practice coding questions as much as possible.'},
            {ques: '3. How has your journey so far at Microsoft been?',
            ans: 'Ans: I have been in four distinct roles at Microsoft in past years. So, Microsoft has a lot to offer and supports your career. I consider myself blessed to get great managers who could see my zeal. Work-life balance is amazing, and it feels good to work there.'},
            {ques: '4. How was your journey in Georgia Institute of Technology?',
            ans: 'Ans: GaTech journey was one hell of a ride. It gave me the right amount of exposure to newer technologies and the technical depth I was looking for. Learning from the people who are doing wonders in their field is an exceptional experience. Computer Vision was by far my favorite class.'},
            {ques: '5. Are there any expectations you had about this career path that you have found different from reality, in both a good or bad way?',
            ans: 'Ans: Yes, this career path exceeded my expectations in a good way. I never wanted to be a computer engineer, somehow landed in the right place though. But I have found this career path to be acceptable and full of accessible opportunities for aware souls. I have seen people coming from diverse backgrounds and excelling in this field so well.'},
            {ques: '6. When and why did you decide to pursue higher education?       ',
            ans: 'Ans: After hitting 2.5 years at Microsoft, I decided to pursue higher education to grow my technical expertise and get foreign exposure. My aim was clear that I want to remain in tech field and live in a different country to experience diversity/culture.'},
            {ques: '7. How did you manage to balance both college/work and preparation for admission exams to Georgia Institute of Technology?  ',
            ans: 'Ans: Definitely, it takes effort to manage both at the same time. But I was firm on my vision about my career. I used to effectively manage my time after office and plan well.'},
            {ques: '8. What tips would you like to give your juniors who aim to pursue Masters in the future?',
            ans: 'Ans: Prepare for exams like GRE, GMAT etc. in college time itself as they are valid for a longer time. You may not want to do MS/MBA at that point, but it is a worthwhile investment for opening future opportunities. Same goes for other exams like CAT, XAT etc. '},
            {ques: '9. How has your journey at IGDTUW shaped you into the person that you have grown into?',
            ans: 'Ans: IGDTUW gave me the right direction and opportunities which helped me reach where I am today. Getting an internship and full time job in Microsoft from the campus changed my career journey altogether. Apart from that, undergraduate research projects helped me establish my interest in research.'},
            {ques: '10. Did you feel college prepared you for the workforce and the real world?',
            ans: 'Ans: In a way, yes because it was the college which enabled me to apply for internships and then jobs. However, it depends on us to grab them and act right.'},
            {ques: '11. What advice would you like to give your juniors for handling rejections, stress and imposter syndrome and staying motivated.',
            ans: 'Ans: Nobody has it sorted, so focus on the next step. I got the Microsoft Internship, after I failed the Expedia & Intuit internship opportunity. Then I didn’t get the Microsoft PPO. So, I prepared again for full time, failed Amazon then got Microsoft later. It’s never a straight path. '},
            {ques: '12. You must have had a memorable time at IGDTUW. Do you miss being a part of it? Share a few good memories.',
            ans: 'Ans: I miss the pasta from the canteen and not so our “Stree-Shakti” food. Then our faculty, Jha sir, always pushed/motivated me to pursue MS. '},
            {ques: '13. What is your suggestion/guidance for your juniors?',
            ans: 'Ans: Keep exploring and learning, otherwise you will never know what you want or good at'}
        ]
    },
    {
        id: '2',
        img: 'https://images.unsplash.com/photo-1547082299-de196ea013d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
        heading: 'Insight 2.0',
        category: 'Experience',
        author: 'Shreya Chhabra',
        publish_date: '20 Jan 2020',
        reading_time: '1 min 20 sec read',
        content: [
            {ques: '1. With around 4 years+ industrial experience, what would you like to give to your juniors stepping into the industry?',
            ans: 'Ans: Be very open to learning and gaining new experiences. Don\'t limit yourselves to the kind of people you have always interacted with. Always ask questions and learn to lean on to people to help you out.'},
            {ques: '2. When and why did you decide to pursue higher education?',
            ans: 'Ans: I decided to pursue higher education when I felt that I needed to understand more about the business aspect of things and how I could contribute to the bigger picture.'},
            {ques: '3. You have been an intern at Microsoft and also have worked at Godrej. What tips would you like to share with your juniors, applying for industrial internships and jobs?',
            ans: 'Ans: When it comes to industrial internships, I can\'t emphasise more on the fact that this is the time you can make mistakes and learn from it. You can gauge whether you want to be a part of the organization or not. The end goal for most of us is to score the PPO, but more than that it is a way to figure out if you like that role or not.'},
            {ques: '4. How has your journey at IGDTUW shaped you into the person that you have grown into?',
            ans: 'Ans: IGDTUW offered me the opportunity to find a great circle of friends and mentors, most of whom I am still in touch with today. It also helped me polish my leadership skills as a part of the various societies we had on campus - Technoliterati, IEEE and so on.'},
            {ques: '5. How has your journey so far at Google been?',
            ans: 'Ans: My journey at Google has been great so far. I\'m still in the learning phase of my career as the direction I have undertaken is completely opposite to what I have done till date. People and the culture here are amazing and that\'s what been my biggest takeaway in my few months here.'},
            ]
    },
    {
        id: '3',
        img: 'https://images.unsplash.com/photo-1547082299-de196ea013d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
        heading: 'Insight 3.0',
        category: 'Experience',
        author: 'Swar Gujrania',
        publish_date: '28 Jan 2020',
        reading_time: '2 min 15 sec read',
        content: [
            {ques: '1. With around 3+ years of industrial experience what advice would you like to give to your juniors stepping into the industry?',
            ans: 'Ans: Advice: Know what you’re getting into. Get to know what your role will be, what the expectations are. Understand how your personal working style fits with that of the team’s and of the company’s. Also, assess honestly how this position may be placed in your future plans.'},
            {ques: '2. When and why did you decide to pursue higher education,i.e, MS in Human Computer Interaction?',
            ans: 'Ans: Over the course of 3 years of working as a Developer, I did a number of personal projects just because I was curious about the state of things around me. Going through these made me realize that I was more interested in people’s side of technology and in architecting the solutions to improve how people lived and interacted with things around them. It was a drastic shift from what I had studied before and whatever I had worked on, and hence I decided I needed to formally study the area.'},
            {ques: '3. You were a Graduate Research Assistant, so has that experience been different from industrial internships?',
            ans: 'Ans: One’s work as a GRA depends greatly on the institution and more so on the professor you’re working under. The nature of work varies from engagement to engagement. For me, I got the GRA because I had a rare combination of Computer Science and Performing Arts background, which my lab was looking for. Over the past year and a half, my experience has been of complete research - from extensive literature review to publishing research papers. It’s been very fulfilling for me because this intersection is personally very satisfying for me. For research, I think that’s really important - to do what you care about , and not what is ‘in’ right now.'},
            {ques: '4. You have been a Software Engineer intern as well as Software consultant at Microsoft. What tips would you like to share with your juniors applying for industrial internships?',
            ans: 'Ans: Get your basics solid, and right. It often feels like people around you know a lot or are doing a lot of fancy things. It’s not worth getting frustrated. At all! Have your knowledge of DS, Algorithms and all the basic concepts solid. Technologies you can learn on the go, other things you need to have a strong grip of. And practice coding - lots and lots of it. '},
            {ques: '5. How has your journey at IGDTUW/IGIT shaped you into the person that you have grown up to be?',
            ans: 'Ans: I received some excellent mentoring from professors at IGIT. My Bachelor’s thesis advisor (Mr. Vivekanand Jha) introduced me to the proper way of doing research and how to navigate academic choices. His advice has been really helpful for me. My other advisor (Dr. Shalini Arora) is one of the best mentors a student could ask for. She goes above and beyond for her students and I have personally grown a lot under her. '},
            {ques: '6. How has your journey been as a UX designer so far?',
            ans: 'Ans: It’s been great and very fulfilling. It’s exactly the kind of thing I have always wanted to do, it just took me a little while to figure it out. UX design is very different from one’s traditional idea of what design is about. I am excited for whatever comes next for me.'},
            ]
    },
    {
        id: '4',
        img: 'https://images.unsplash.com/photo-1547082299-de196ea013d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
        heading: 'Insight 4.0',
        category: 'Experience',
        author: 'Maanya Gupta',
        publish_date: '17 Feb 2020',
        reading_time: '2 min read',
        content: [
            {ques: '1. With around 3 years+ of industrial experience at big giants such as Microsoft, Nestle, Tata Sky Ltd., what has your experience been like?',
            ans: 'Ans: All the 3 companies were as different as they come. While Microsoft was all about building a great app for the users, Nestle made me sweat in the summer heat–interacting with shoppers, learning how they make their purchase decisions. Tata Sky gave me exposure to the world of entertainment–working with production houses, designers, top fashion brands, and technology vendors; bringing all of them together to make one brilliant product, which viewers loved–all in a day’s work.'},
            {ques: '2. When and why did you decide to pursue an MBA?',
            ans: 'Ans: I’ve always loved building things. It requires a deep understanding of man, money and machine. While engineering gave me a solid background in the machine aspect, conversations with people in industry, during my second year, quickly made it clear that an MBA was essential for the first two.'},
            {ques: '3. How did you manage to balance both college and preparation for CAT?',
            ans: 'Ans: My CAT preparation never interfered with my regular studies. I’d enrolled for online CAT coaching where classes would be held via webinar at 8 PM each night. Later on, I’d joined weekend classroom sessions. Being regular at both places was key.'},
            {ques: '4. How has your journey at IGDTUW shaped you into the person that you have grown into?',
            ans: 'Ans: IGIT helped me realize the importance of being a self-starter and actively seeking out opportunities to learn and excel. At the end of the day, it all comes down to how good you are at what you do.I also got timely advice and support from my professors, Mr. Devendra Tayal and Mr. Vivekanand Jha.'},
            {ques: '5. How was your experience at IIM Ahmedabad?',
            ans: 'Ans: In one word - Transformative. The constant pressure and academic rigour forced me to manage myself – my time and priorities – much better. This single learning has helped me in all my assignments after B-School.'},
            {ques: '6. What tips would you like to give your juniors who aim to pursue MBA in the future?',
            ans: 'Ans: While preparing, remain consistent. A little, each day, will go a long way in ensuring that you remain stress-free. It’s critical to put your best foot forward in each exam and interview. Stress doesn\'t let you do that. Once through, Explore! Explore! Explore! Do internships, speak with people working in your dream companies, read up online, and figure out the role and sector that you’d like to work in.'},    
            {ques: '7. What is your new venture about?',
            ans: 'Ans: I\'m really excited about the primary education space. We\'re living in times when an increasing number of parents are questioning how and what their kids are taught in school. The idea is to help 8-14 year old kids pick up critical life skills, like managing finances, handling relationships and emotions, and running a household – all this through stories.'},    
        ]
    },
    {
        id: '5',
        img: 'https://images.unsplash.com/photo-1547082299-de196ea013d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
        heading: 'Insight 5.0',
        category: 'Experience',
        author: 'Shruti Gandhi',
        publish_date: '24 Feb 2020',
        reading_time: '1 min 32 sec read',
        content: [
            {ques: '1. With around 4 years+ industrial experience, what advice would you like to give to your juniors stepping into the industry? ',
            ans: 'Ans: Avoid complacency. Once in the industry, it is very easy to fall into the humdrum routine of doing the same things day in and day out. Make it a habit to learn something new every day about the area you are working on, and be ready to adapt yourself to newer technologies. When things become difficult, stick to the fundamentals.'},
            {ques: '2. When and why did you decide to pursue higher education?',
            ans: 'Ans: I decided to pursue higher education in my third year of undergrad. In the summer of 2011, I took up two online courses, Linear Algebra by Dr. Gilbert Strang and Circuits and Electronics by Dr. Anant Agarwal. I was fascinated with the uncomplicated approach the faculty in the west had towards teaching the most complicated of concepts. Consequently, higher education felt like a great opportunity to strengthen my fundamentals with a more practical and research oriented approach.'},
            {ques: '3. You have been a research intern at IIT Delhi and software engineering intern at Cisco and IBM. What tips would you like to share with your juniors, applying for industrial internships?',
            ans: 'Ans: I would say, try to identify opportunities where you can actually contribute something technically. Try to start early when preparing and applying for internships. If you are not interning for the summer, invest that time in working on side projects, or contributing towards open source projects.'},
            {ques: '4. How has your journey at IGDTUW shaped you into the person that you have grown into?',
            ans: 'Ans: It was at IGDTUW that I first learnt to hustle and scout for opportunities. Volunteering with organizations such as IEEE, WISE India, helped me refine my leadership skills. In hindsight I realize what a privilege it was to be surrounded by so many inspiring and like-minded women! '},
            {ques: '5. How has your journey so far at North Carolina State University been?',
            ans: 'Ans: The journey at NC State University has been great so far. I am close to ending my stint here. I have been lucky to have had the opportunity to work with the experts in computer networking, and have grown a lot as a student and as a researcher in the last five years I spent here. '},
            ]
    },
]

export default blogData;