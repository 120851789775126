import React from "react";

export default function Winners({ hackathon }) {
    return (
        hackathon.winners.length > 0 && (
            <div id="winner" className="winner">
                <h2 className="single-hackathon-heading heading-underline">Winners</h2>
                <div className="dates-cards">

                    {hackathon.winners.map((w) => {
                        return (
                            <div className="hackathon-card">
                                <p className="hackathon-card-title">{w.position}</p>
                                <div className="img-wrapper">
                                    <img className="hackathon-card-img" src={w.img} alt="registration dates" />
                                </div>
                                <div className="inner-div-card">
                                    <p className="hackathon-card-title">{w.project_name}</p>
                                    <p className="card-desc-para" style={{ fontSize: "x-small" }}>{w.project_desc}</p>
                                    {w.project_link === '' ? 
                                    <></> 
                                    : (
                                    <div className="card-button">
                                        <div className="card-button-text mt-4 btn custom-btn custom-btn-bg ">
                                            <a className="hackathon-link" href={w.project_link} style={{ fontSize: "medium" }}>View project
                                                <i class="fas fa-fw fa-arrow-right fa-2x hackathon-arrow"></i>
                                            </a>
                                        </div>
                                        <div className="card-button-icon"></div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    );
}