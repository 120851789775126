const tipsNtricksData = [
    {
        img: "https://drive.google.com/uc?export=view&id=1mnNd_IUvWprwrlpPzkrGGKqVrFjgEqwE",
        date: "24 April 2022",
        category: "Tips and Tricks",
        name: "Tips and Tricks part 1",
        company: "Lean In IGDTUW",
        desc: "Tips from your seniors about anything and everything about college✨",
        link: "https://www.instagram.com/p/Ccu3gRBsfVa/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1vOBcEKmFXsNbAfUGRbOxYh7MghdDknK3",
        date: "4 July 2022",
        category: "Internship Journey Series",
        name: "Microsoft Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Gaurisha R Srivastava, SWE Intern'22 at Microsoft",
        link: "https://www.instagram.com/p/CflMETQJDAJ/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1BuqMaTSHXl5UChEQ4jmXBNASTUTT5quD",
        date: "6 July 2022",
        category: "Internship Journey Series",
        name: "Walmart Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Rashmitha Mohan,, SWE Intern'22 at Walmart",
        link: "https://www.instagram.com/p/CfqNKB4sdZX/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=13XDeoP8odbqOexgSHzzmGPzFOARez__J",
        date: "8 July 2022",
        category: "Internship Journey Series",
        name: "Wells Fargo Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Aiswarya Lenin, SWE Intern'22 at Wells Fargo",
        link: "https://www.instagram.com/p/CfvoaxuMCrN/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1MZCvXFHy1O8dhfN9HUump_3PlZEn5jjd",
        date: "10 July 2022",
        category: "Internship Journey Series",
        name: "Twilio Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Pragti Chauhan, SWE Intern'22 at Twilio",
        link: "https://www.instagram.com/p/Cf0vkU1pGtQ/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1MuOH8MAk5mfZn52EZYV6BKkoJ66yAvfv",
        date: "12 July 2022",
        category: "Internship Journey Series",
        name: "Google Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Deekshita Verma, SWE Intern'22 at Google",
        link: "https://www.instagram.com/p/Cf5sAJDMUce/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1PtTR_z3IWYI_3-T8k7KLlDjJvAjNxzXJ",
        date: "14 July 2022",
        category: "Internship Journey Series",
        name: "Atlassian Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Sukriti Dawar, SWE Intern'22 at Atlassian",
        link: "https://www.instagram.com/p/Cf_En3tJ07q/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=17Wrn8I7QotJ2HzSVFBR70c-ivMDYm38b",
        date: "16 July 2022",
        category: "Internship Journey Series",
        name: "Servicenow Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Shrishti, SWE Intern'22 at Servicenow",
        link: "https://www.instagram.com/p/CgEKdIYpn_N/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1UlNnXygNoWLzC9BWnL28OeCXdMxI7yNI",
        date: "18 July 2022",
        category: "Internship Journey Series",
        name: "Adobe Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Nidhi Sethi, SWE Intern'22 at Adobe",
        link: "https://www.instagram.com/p/CgJQ3VZMvH2/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1u-qqoLhN_aaWiVQaMg6dGgT1LSs5afE1",
        date: "20 July 2022",
        category: "Internship Journey Series",
        name: "Myntra Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Isha Dubey, SWE Intern'22 at Myntra",
        link: "https://www.instagram.com/p/CgOjb5HpXQ2/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1UuKP-xdS8TXGYiNunLYSQCgPrPKZ7h4V",
        date: "22 July 2022",
        category: "Internship Journey Series",
        name: "Intuit Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Siftee Ratra, SWE Intern'22 at Intuit",
        link: "https://www.instagram.com/p/CgTTJAeqFCq/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1bexPB1NssowFGm1EggyVNxdTjhcPN8PY",
        date: "25 July 2022",
        category: "Internship Journey Series",
        name: "JP Morgan Chase & Co Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Priyanshi Sharma, SWE Intern'22 at JP Morgan Chase & Co",
        link: "https://www.instagram.com/p/CgbNakdsPLn/?utm_source=ig_web_copy_link",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1Lxy4ik6YhkQB6cRe6jJ38M2jv-r0EZEy",
        date: "27 July 2022",
        category: "Internship Journey Series",
        name: "Deutsche Bank Interview Experience",
        company: "Lean In IGDTUW",
        desc: "Internship Interview Experience and Tips from Ekta Singh, SWE Intern'22 at Deutsche Bank",
        link: "https://www.instagram.com/p/CggNBVcs1A1/?utm_source=ig_web_copy_link",
    },
]

export default tipsNtricksData;