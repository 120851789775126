import React from "react";
import Footer from "../../components/footer/Footer";
import "./hackathon.css";
import HackGrid from "../../components/HackGrid/Cards";
import Header from "../../components/Header/Header";
import hackathonData from "../../Data/hackathonData";
import HackathonSVG from "../../SVG/hackathon/HackathonSVG";
import HackathonGiftSVG from "../../SVG/hackathon/hackathonGiftSVG";

function Hackathon() {
    return (
        <>
            <Header />
            <section className="Hackathon">
                <div className="container">
                    <div className="row">
                        <div class="col hackathon-col">
                            <div class="tk-blob">
                                <svg className="hackathon-blob" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440.7 428.7">
                                    <path d="M410.6 78.8c36 52.5 36.1 126 19.2 194C412.9 340.7 379 403 330 421.9c-49 19-113.1-5.3-178.6-34C85.8 359.2 18.7 326.1 3.5 276.4-11.7 226.7 25 160.3 71.7 105.3 118.3 50.3 174.8 6.8 239 .7c64.1-6 135.7 25.5 171.6 78.1z" fill="#eb8f8f7a"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="hackathon-img-div">
                            <HackathonSVG />
                        </div>
                        <div className="about-text">
                            <div className="hackathon-header-div">
                                <div>
                                    <HackathonGiftSVG />
                                </div>
                                <span className="mr-2 hackathon-img">
                                    <h1 className="underline-text hackathon-page-title">Hackathon</h1>
                                </span>
                                <p className="hackathon-header-div-para">
                                    Experiences where you{" "}
                                    <strong>Innovate, Collaborate and Develop</strong> solutions
                                    that upgrade your skills and win you prizes!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="scroll">
                <p class="ctn-icon">
                    <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                </p>
            </div>
            <HackGrid data={hackathonData} />
            <Footer />

        </>
    );
}

export default Hackathon;
