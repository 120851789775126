import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import SingleSlide from "./SingleSlide";
import Data from "../../Data/TopCarouselData"

export default function TopCarousel() {
    const [state, setState] = useState({
        goToSlide: 0,
        offsetRadius: 2,
        showNavigation: true,
        config: config.gentle
    });

    let slides = [
        {
            key: '1',
            content: <SingleSlide prop={Data[0]}/>
        },
        {
            key: '2',
            content: <SingleSlide prop={Data[1]}/>
        },
        {
            key: '3',
            content: <SingleSlide prop={Data[2]}/>
        },
        {
            key: '4',
            content: <SingleSlide prop={Data[3]}/>
        },
        {
            key: '5',
            content: <SingleSlide prop={Data[4]}/>
        }
    ].map((slide, index) => {
        return { ...slide, onClick: () => setState({ goToSlide: index }) };
    });

    let xDown = null;
    let yDown = null;

    const getTouches = (evt) => {
        return (
            evt.touches || evt.originalEvent.touches
        );
    };

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                setState({ goToSlide: state.goToSlide + 1 });
            } else {
                setState({ goToSlide: state.goToSlide - 1 });
            }
        } else {
            if (yDiff > 0) {
            } else {
            }
        }
        xDown = null;
        yDown = null;
    };

    return (
        <div
            className="top-carousel-main-div"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <Carousel
                slides={slides}
                goToSlide={state.goToSlide}
                offsetRadius={state.offsetRadius}
                showNavigation={state.showNavigation}
                animationConfig={state.config}
            />
        </div>
    );
};