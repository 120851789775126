import React from "react";
import "./chronicleHandSVG.css";

export default function ChronicleHandSVG() {
    return (
        <div>
            <svg id="icon-hand" className="chronicle-hand" xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88">
                <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M4 70.5l3.9-3.9c5.8-5.8 13.7-9.1 22-9.1H53c.9 0 1.7.7 1.7 1.7v1.9c0 3.4-2.8 6.2-6.2 6.2H29.3" />
                <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M18.5 86l7.1-7.1c1.8-1.8 4.2-2.8 6.7-2.8H51c3.6 0 7-1.4 9.5-3.9l22.9-22.9c.9-.9.9-2.3 0-3.2v0c-2.4-2.4-6.3-2.4-8.7 0L61.1 59.6" />
                <g id="star-4">
                    <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M48.9 7.2V16" />
                    <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M44.5 11.6h8.8" />
                </g>
                <g id="star-3">
                    <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M32.6 42.9v5.6" />
                    <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M29.8 45.7h5.6" />
                </g>
                <path id="star-2" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M54.4 31.4l3.4-8.6 3.4 8.6 8.6 3.4-8.6 3.4-3.4 8.6-3.4-8.6-8.6-3.4z" />
                <path id="star-1" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M18.5 17.5L23 6l4.6 11.5L39 22l-11.4 4.6L23 38l-4.5-11.4L7 22z" />
            </svg>
        </div>
    );
}