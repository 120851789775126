import React from "react";
import "./TeamMemberCard.css";

export default function TeamMemberCard({ item }) {
    return (
        <div className="team-member-div">
            <div className="team-members">
                {item.map((member) => {
                    return (
                        <div className="team-member-img-div">
                            <div className="team-member-img">
                                <img className="member-img" src={member.profilePic} alt="member-img" />
                            </div>
                            <div className="hidden-div">
                                <div className="team-member-content">
                                    <h3>{member.name}</h3>
                                    <p className="about-member">{member.about}</p>
                                    <div className="social-icons" style={{ zIndex: "100" }}>
                                        <a target="_blank" rel="noreferrer" href={member.socialMedia} style={{ cursor: "pointer" }}>
                                            <i className="member-social-icon fab fa-linkedin-in"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>

    );
}