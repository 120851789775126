import React, { useEffect, useState } from "react";
import SliderContent from "./SliderContent";
import Arrows from "./Arrows";
import "./slider.css";


function Slider({ slides }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const len = slides.length - 1;

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
        }, 4000);
        return () => clearInterval(interval);
    }, [activeIndex, len]);

    return (
        <div className="slider-container">
            <SliderContent activeIndex={activeIndex} sliderFeedback={slides} />
            <Arrows
                prevSlide={() =>
                    setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
                }
                nextSlide={() =>
                    setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
                }
            />
        </div>
    );
}

export default Slider;
