import React, { useState } from "react";
import "./opportunity.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import opportunity from "../../Data/OpportunityDetails";
import OpportunityCard from "../../components/OpportunityCard/OpportunityCard";
import OpportunitySVG from "../../SVG/opportunity/OpportunitySVG";
import OpportunityTargetSVG from "../../SVG/opportunity/OpportunityTragetSVG";

export default function Admin() {
    const [query, setQuery] = useState("");
    const [category, setCategory] = useState("");
    const search = (data) => {
        return data.filter((item) => item.company.toLowerCase().includes(query.toLowerCase()) || item.name.toLowerCase().includes(query.toLowerCase()));
    }
    const filtered = category ? opportunity.filter((opp) => opp.category.toLowerCase() === category.toLowerCase()) : opportunity;

    return (
        <>
            <Header />
            <div className="opportunity-header-div">
                <div className="opp-header-right-div">
                    <div class="intro">
                        <OpportunitySVG />
                    </div>
                </div>
                <div className="opp-header-left-div">
                    <div>
                        <OpportunityTargetSVG />
                    </div>
                    <h1 className="heading-underline underline-text opportunity-heading">Opportunities</h1>
                    <p>Explore the opportunities in STEM here! Get insights about various scholarships, mentorship programs and annual hackathons, open for university students. This is the go to place for keeping track and searching about new opportunities. Lean In will make sure that you never miss out on them again !!</p>
                </div>
            </div>
            <div className="scroll" style={{marginTop: "0px"}}>
                <p class="ctn-icon">
                    <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                </p>
            </div>
            <div class="opportunity-container">
                <div class="sidebar">
                    <div className="filter-search">
                        <div className="filter-radio-btn-search">
                        <div>
                            <input type="radio" id="radio3" name="radio" value="internship"
                                onChange={(e) => setCategory(e.target.value)}
                            />
                            <label for="age1">Internship</label>
                        </div>
                        <div>
                            <input type="radio" id="radio4" name="radio" value="scholarship"
                                onChange={(e) => setCategory(e.target.value)}
                            />
                            <label for="age2">Scholarship</label>
                        </div>
                        <div>
                            <input type="radio" id="radio5" name="radio" value="openSource"
                                onChange={(e) => setCategory(e.target.value)}
                            />
                            <label for="age4">Open source</label>
                        </div>
                        <div>
                            <input type="radio" id="radio6" name="radio" value="competition"
                                onChange={(e) => setCategory(e.target.value)}
                            />
                            <label for="age4">Competition</label>
                        </div>
                        <div>
                            <input type="radio" id="radio7" name="radio" value="program"
                                onChange={(e) => setCategory(e.target.value)}
                            />
                            <label for="age4">Program</label>
                        </div>
                        </div>
                        <div className="search">
                            <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Search by company name or opportunity name"
                                onChange={(e) => setQuery(e.target.value)}
                                style={{ width: "90%", padding: "5px 10px", right: "10px" }} />
                        </div>
                    </div>
                </div>
                {filtered ? <OpportunityCard data={search(filtered)} /> : <OpportunityCard data={search(opportunity)} />}
            </div>
            <Footer />
        </>
    );
}