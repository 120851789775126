import React from 'react';

export default function OpportunityCard({ data }) {
    return (
        <>
            <div class="opportunity-main-content">
                <div className="opp-flex-div">
                    {data.map((prop) => {
                        return (
                            <>
                                <div class="opp-card">
                                    <div class="img-box">
                                        <img src={prop.img} alt="opportunity-img" className="opportunity-img" />
                                    </div>
                                    <div class="opportunity-content">
                                        <h3>{prop.name}</h3>
                                        <h4 style={{margin: "5px 0", fontWeight: "lighter", fontSize:"15px"}}>{prop.company}</h4>
                                        <p style={{ height: "70%" }}>{prop.desc}</p>
                                    </div>
                                    <div class="card-action">
                                        <div class="name-container">
                                            <div class="name-box">
                                                <p>{prop.category}</p>
                                                <small>{prop.date}</small>
                                            </div>
                                        </div>
                                        <a className="card_link_opp" target="_black" href={prop.link}>
                                            <img className="opp-img" src="https://s2.svgbox.net/octicons.svg?ic=share" alt="opportunity-img" />
                                        </a>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                    {/* <DataTable /> */}
                    {/* <Cards data={requests} /> */}
                    {/* <Cards data={opportunity} /> */}
                </div>
            </div>
        </>
    );
}
