const sliderFeedback = [
    {
        id: 'id1',
        img: 'https://www.w3schools.com/howto/img_avatar2.png',
        name: 'Deekshita Verma',
        feedback: 'Leanin dsa circle helped me a lot last year had not it been for the regular classes and daily practice questions. I would not have been this regular in my dsa practice. I owe lean in a lot in gearing me up for the placement season 🥰',
        designation: 'Mentee',
    },
    {
        id: 'id2',
        img: 'https://www.w3schools.com/w3images/avatar6.png',
        name: 'Himani Khurana',
        feedback: 'Mentors really did put their best effort in mentoring all of us. And all their classes, be it doubt session or teaching sessions, are great.',
        designation: 'Mentee',
    },
    {
        id: 'id3',
        img: 'https://cdn.pixabay.com/photo/2021/02/12/07/03/icon-6007530_640.png',
        name: 'Harshita Khemani',
        feedback: 'I am very grateful to have been able to contribute towards encouraging my peers and instilling confidence in them regarding not just different DSA concepts but also everything the university has to offer to them. LeanIn offers a vibrant community and platform to those who believe in achieving their goals by uplifting others. Progress in its real sense can not be achieved by just one person excelling but will be achieved when masses do exceedingly well. And so, I propel you all to strive and be a part of LeanIn at least once during the tenure of your university life.',
        designation: 'Mentor',
    },

    {
        id: 'id4',
        img: 'https://www.w3schools.com/w3images/avatar5.png',
        name: 'Harsika Sunil Agarwal',
        feedback: 'As soon as I began my journey in college, Lean In became a constant companion, providing support, guiding my path and promoting my dreams, something for which I am immensely grateful. From conducting regular sessions about opportunities, annual hackathon, events where students from every year can participate in, providing resources to running mentorship circles where diverse technical fields are explored, hardworking mentees learning under selfless mentors who share their knowledge and experience, thus contributing to our culture of sisterhood, Lean In was so much more than a society. It was an all-embracing, empowering community where students themselves are the pillars, creating a positive impact on everyone that associates with it. Being a core member of Lean In has been instrumental in developing my personality, enriching my technical as well as leadership skills. My dream is to evolve it into a holistic environment, where we all can grow together, learn from each other’s mistakes, share knowledge, motivate and inspire each other to improve every day and become the best version of ourselves. I feel privileged to be a part of this amazing family, continuing its legacy and taking it to greater heights.',
        designation: 'Core Member',
    },
]

export default sliderFeedback;
