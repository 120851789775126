import React from "react";
import "./hackathonGiftSVG.css";

export default function HackathonGiftSVG() {
    return (
        <svg version="1.1" id="gift" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="80px" height="80px" viewBox="0 0 150 115" enable-background="new 0 0 150 115" xmlSpace="preserve">
            <g id="gift-lid">
                <g id="ribbon">
                    <path fill="none" stroke="red" stroke-width="7" d="M110.248,15.31c0,6.053-5.341,9.969-11.75,11.037
			c-38.096,7.477-17.801,0-11.749-11.037c2.849-5.34,5.341-11.037,11.749-11.037C105.264,4.272,110.604,9.257,110.248,15.31
			L110.248,15.31z"/>
                    <path fill="none" stroke="red" stroke-width="7" d="M42.601,15.31c0,6.053,5.341,9.969,11.749,11.037
			c38.096,7.477,17.802,0,11.75-11.037c-2.848-5.34-5.341-11.037-11.75-11.037C47.585,4.272,42.601,8.901,42.601,15.31L42.601,15.31z"
                    />
                </g>
                <path id="gift-top" fill="red" d="M121.285,33.111c2.136,0,3.561,1.424,3.561,3.561v14.242H85.682V33.111H121.285 M67.879,33.111v17.802
		H25.155V36.672c0-2.136,1.424-3.561,3.561-3.561H67.879 M121.285,25.991H78.561v32.043h53.405V36.672
		C131.966,30.975,127.338,25.991,121.285,25.991L121.285,25.991z M75,25.991H28.715c-6.053,0-10.681,4.628-10.681,10.681v21.362H75
		V25.991L75,25.991z"/>
            </g>
            <path id="gift-bottom" fill="red" d="M121.285,68.715v39.164H85.682V68.715H121.285 M67.879,68.715v39.164H28.715V68.715H67.879 M128.406,61.594
	H78.561v46.285c0,3.917,3.204,7.121,7.121,7.121h35.604c3.916,0,7.121-3.204,7.121-7.121V61.594L128.406,61.594z M75,61.594H21.594
	v46.285c0,3.917,3.205,7.121,7.121,7.121h39.164c3.917,0,7.121-3.204,7.121-7.121V61.594L75,61.594z"/>
        </svg>
    );
}