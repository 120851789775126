import React, { useState } from "react";
import "./team.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import TeamMemberCard from "../../components/TeamMember/TeamMemberCard";
import MemberDetail from "../../Data/MemberDetail";
import TeamSVG from "../../SVG/team/TeamSVG";

export default function Team() {

    const [search, setNewSearch] = useState("2022-23");

    const handleSearchChange = (e) => {
        setNewSearch(e.target.value);
    };

    return (
        <>
            <Header />
            <div className="team-header">
                <div className="flex-row">
                    <div className="left-div-team">
                        <div class="col team-col">
                            <div class="tk-blob">
                                <svg className="team-blob" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440.7 428.7">
                                    <path d="M410.6 78.8c36 52.5 36.1 126 19.2 194C412.9 340.7 379 403 330 421.9c-49 19-113.1-5.3-178.6-34C85.8 359.2 18.7 326.1 3.5 276.4-11.7 226.7 25 160.3 71.7 105.3 118.3 50.3 174.8 6.8 239 .7c64.1-6 135.7 25.5 171.6 78.1z" fill="#eb8f8f7a"></path>
                                </svg>
                            </div>
                        </div>
                        <TeamSVG />
                    </div>
                    <div className="team-right-div">
                        <div>
                            <svg width="64" height="64" viewBox="0 0 24 24">
                                <path class="people" fill="red" stroke="red" stroke-width="0.5" d="M12 12.75C13.63 12.75 15.07 13.14 16.24 13.65C17.32 14.13 18 15.21 18 16.38V18H6V16.39C6 15.21 6.68 14.13 7.76 13.66C8.93 13.14 10.37 12.75 12 12.75ZM4 13C5.1 13 6 12.1 6 11C6 9.9 5.1 9 4 9C2.9 9 2 9.9 2 11C2 12.1 2.9 13 4 13ZM5.13 14.1C4.76 14.04 4.39 14 4 14C3.01 14 2.07 14.21 1.22 14.58C0.48 14.9 0 15.62 0 16.43V18H4.5V16.39C4.5 15.56 4.73 14.78 5.13 14.1ZM20 13C21.1 13 22 12.1 22 11C22 9.9 21.1 9 20 9C18.9 9 18 9.9 18 11C18 12.1 18.9 13 20 13ZM24 16.43C24 15.62 23.52 14.9 22.78 14.58C21.93 14.21 20.99 14 20 14C19.61 14 19.24 14.04 18.87 14.1C19.27 14.78 19.5 15.56 19.5 16.39V18H24V16.43ZM12 6C13.66 6 15 7.34 15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6Z" />
                            </svg>
                        </div>
                        <h1 className="heading-underline underline-text team-top-heading">Lean In Team</h1>
                        <p>Meet our amazing team of makers, thinkers and explorers !! Core members of Lean In share the vision and values of our community. We’re driven by the idea that the best work is born from diligence, creativity and passion.</p>
                    </div>
                </div>
                <div className="scroll">
                    <p class="ctn-icon">
                        <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                    </p>
                </div>
            </div>

            <div className="main-team-div">
                <form className="member-year" action="">
                    <label for="year">Year:</label>
                    <select className="select-content" name="year" id="year" form="yearform" onChange={handleSearchChange}>
                        <option value="2022-23">2022-23</option>
                        <option value="2021-22">2021-22</option>
                        <option value="2020-21">2020-21</option>
                        <option value="2019-20">2019-20</option>
                        <option value="2017-18">2017-18</option>
                        <option value="2016-17">2016-17</option>
                    </select>
                </form>
                {MemberDetail.map((data) => {
                    console.log(search)
                    if(data.year === search) {
                        let president_len = data.president.length;
                        let core_len = data.core_team_member.length;
                        let web_len = data.website_team.length;

                        return (
                            <>
                            {president_len > 0 && (
                                <>
                                <h1 className="heading-underline">Presidents</h1>
                                <TeamMemberCard item={data.president}/>
                                </>
                            )
                            }
                            {core_len> 0 && (
                                <>
                                <h1 className="heading-underline">Core Team Members</h1>
                                <TeamMemberCard item={data.core_team_member}/>
                                </>
                            )}
                            {web_len > 0 && (
                                <>
                                <h1 className="heading-underline">Website Team</h1>
                                <TeamMemberCard item={data.website_team}/>
                                </>
                            )}
                            </>
                        )
                    } else {
                        return <></>
                    }
                })}
            </div>
            <Footer />
        </>
    );
}