import React from "react";

function SliderContent({ activeIndex, sliderFeedback }) {
    return (
        <section>
            {sliderFeedback.map((slide, index) => (
                <div
                    key={index}
                    className={index === activeIndex ? "slides active" : "inactive"}
                >
                    <div class="carousel-wrapper">
                        <div class="carousel-item">
                            <p className="start">"</p>
                            <div className="feedback-info-div">
                                <p className="feedback-info">{slide.feedback}</p>
                            </div>
                            <div>
                                <img className="feedback-img" src={slide.img} alt="feedback-img" />
                                <p className="feedback-info2">
                                    <b>{slide.name}</b> - {slide.designation}
                                </p>
                            </div>
                        </div>
                        <p className="end">"</p>
                    </div>
                </div>
            ))
            }
        </section >
    );
}

export default SliderContent;