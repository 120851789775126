import React from "react";
import "./Mackbook.css";

export default function Macbook() {
    return (
        <div class="macbook">
            <div class="inner">
                <div class="screen">
                    <div class="face-one">
                        <div class="camera"></div>
                        <div class="display">
                            <div class="shade"></div>
                        </div>
                        <span>MacBook Air</span>
                    </div>
                    <img src="http://www.clker.com/cliparts/i/s/H/f/4/T/apple-logo-white.svg" class="logo" alt="logo" />
                </div>
                <div class="body">
                    <div class="face-one">
                        <div class="touchpad">
                        </div>
                        <div class="keyboard">
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key space"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                            <div class="key f"></div>
                        </div>
                    </div>
                    <div class="pad one"></div>
                    <div class="pad two"></div>
                    <div class="pad three"></div>
                    <div class="pad four"></div>
                </div>
            </div>
            <div class="shadow"></div>
        </div>
    );
}