const CircleInfo = [
    {
        id: '-1',
    },
    {
        id: '-1',
    },
    {
        id: '-1',
    },
    {
        id: '-1',
    },
    {
        id: '5',
        name: 'DSA',
        tagline: 'A data structure is a named location that can be used to store and organize data. And, an algorithm is a collection of steps to solve a particular problem. Learning data structures and algorithms allow us to write efficient and optimized computer programs.',
        about: 'The Pre requisite for this circle is a basic knowledge of any programming language, preferably C++ or Java as the instructors will deal with these two languages. First mentors discuss the concept, then it’s code in C++ and Java and then resolve queries of mentees, along with solving some questions based on the given topic. Following would be covered in this circle: Array, String, Linked List, Searching and Sorting, Recursion, Backtracking, Stacks, Queues, Trees, Heaps, Graph, Dynamic Programming, Greedy.',
        reason: `
        If you love to solve real-world complex problems, crack the interviews and get into the product based
        companies and become a great coder, then this is the right place for you.
        `,
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: '#',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '01 Sep',
                enddate: '31 Dec',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: `Arrays,
                String,
                Searching and Sorting,`,
                date: 'Sep 01 2022',
                category: {
                    tag: 'Basics-1',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `
                Linked List,
                Stack,
                Queue,`,
                date: 'Oct 01 2022',
                category: {
                    tag: 'Basics-2',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `
                Hashmap,
                Recursion/Backtracking,
                Trees,
                Heaps`,
                date: 'Nov 01 2022',
                category: {
                    tag: 'Intermediate',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `
                Graphs
                Dynamic Programming
                Greedy`,
                date: 'Dec 01 2022',
                category: {
                    tag: 'Advanced',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 1,
                profilePic: 'https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=930&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2022-23",
            },
            {
                id: 2,
                profilePic: 'https://images.unsplash.com/photo-1516195851888-6f1a981a862e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=810&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2018-19",
            },
            {
                id: 3,
                profilePic: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2019-20",
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=1yVa8KWNZ9saxdCqYUBW1YxfyZ3Vssatn',
                name: 'Ojaswi Tyagi',
                about: `Lean In has the best mentors and provides excellent guidance to the mentees. I have received a lot from LeanIn- knowledge, wisdom, and a network of super generous seniors. I am really grateful for joining leanIn as a mentor this time😍. I aim to spread my learnings and experience to help girls around me grow and lead their way to success in Computers.`,
                socialMedia: '',
                year: "2020-21",
            },

            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=1lx_UIB99B7rZjn0GCwLEaaEWG6Z5e79u',
                name: 'Neha Pandey',
                about: `“A mentor empowers a person to see a possible future, and believe it can be obtained.” – Shawn Hitchcock

                I have always aimed at associating with a community like LeanIn and emerge as a leader who seeks to build a tight-knit community of young female programmers always there to support, motivate, and inspire each other.`,
                socialMedia: '',
                year: "2020-21",
            },

            {
                id: 6,
                profilePic: 'https://drive.google.com/uc?export=view&id=1xuGSeHk6TFAWhgwluSpFRLLDRJy49Kqj',
                name: 'Pakhi Bansal',
                about: `Lean In holds a very important place in my technical journey! ❤️
                In my second year, I became a part of the competive coding circle.
                And at that very point, I thought if my mentors had such a great impact on me, how would it feel to mentor others?😍
                Helping your peers to grow in the technical field is something that I really value✨`,
                socialMedia: '',
                year: "2020-21",
            },
            {
                id: 7,
                profilePic: 'https://drive.google.com/uc?export=view&id=1zpyagG_ggISjtwX2x04RV6yhzDzG-_0S',
                name: 'Nitasha Dhingra',
                about: `Joining the DSA Circle at Lean IN, I feel the power of growing together. While planning for mentees with fellow mentors, I have grown in conjunction with them!
                As a mentor, I try my best to push my mentees beyond their limits, always encouraging them once in a while to keep them going! I feel I'm building productive, synergetic relationships in the process.`,
                socialMedia: '',
                year: "2020-21",
            },
            {
                id: 8,
                profilePic: 'https://drive.google.com/uc?export=view&id=1RYNO_L2MN8n3_8Qa97RrMu_2Xf6fOWnF',
                name: 'Bhoomi Kohli',
                about: `Teaching and mentoring students is something that I am really passionate about. I feel that you learn more by sharing your knowledge with others. Lean In IGDTUW has given me this opportunity and I am really grateful for that.
                I have learnt alot from my seniors and now I feel is the time to give it back to my juniors.`,
                socialMedia: '',
                year: "2020-21",
            },
            {
                id: 9,
                profilePic: 'https://drive.google.com/uc?export=view&id=1TJOPFz0soCnYfJEGmyAwG7tG6ZiN0jrp',
                name: 'Sakshi Jain',
                about: `LeanIn is really a very prestigious society in IGDTUW. The reason for joining LeanIn as a DSA mentor this year is that earlier being a mentee myself, my mentors made sure that the mistakes they made should not be repeated by us and guided us in every possible way.✨ They paved a path for us, now I think it is our responsibility to carry forward the legacy.`,
                socialMedia: '',
                year: "2020-21",
            },
            {
                id: 10,
                profilePic: 'https://drive.google.com/uc?export=view&id=1i5n25B1u6KpRNxv9n0XiyzPO2wv53JAS',
                name: 'Anagha Jain',
                about: "Had an amazing time being a mentor at Lean In. Loved to be amidst mentors and mentees who are passionate about technology and wish to learn and grow. ",
                socialMedia: 'www.linkedin.com/in/anaghajain/',
                year: "2021-22",
            },
            {
                id: 11,
                profilePic: 'https://drive.google.com/uc?export=view&id=1fe7QcahdZH_YUP1aSqWQnWzrbUYBQNVb',
                name: 'Yogita Khurana',
                about: "Being a part of LeanIn as a mentor is one of the best thing I have experienced in college. ",
                socialMedia: 'https://www.linkedin.com/in/yogita10',
                year: "2021-22",
            },
            {
                id: 12,
                profilePic: 'https://drive.google.com/uc?export=view&id=1fh7ju_q9DCLT7lo7LUTfEGBG2Z-gC6Qq',
                name: 'Khushboo Agarwal',
                about: "Glad to be a part of team Lean In. The journey was full of new learnings in every session we took. It was fun to interact with our peers.",
                socialMedia: 'https://www.linkedin.com/in/khushbooagarwal09/',
                year: "2021-22",
            },
            {
                id: 13,
                profilePic: 'https://drive.google.com/uc?export=view&id=1Yvx9VTbzohA4keGwI5uJltUOJf22qgi0',
                name: 'Sachita Malhotra',
                about: "In my opinion, the best way to uitlize ones skills and experieces is to mentor and share them with your peers and juniors and Leanin helped me to achieve that. I had an amazing time :)",
                socialMedia: 'https://www.linkedin.com/in/sachitamalhotra/',
                year: "2021-22",
            },
            {
                id: 14,
                profilePic: 'https://drive.google.com/uc?export=view&id=1mYwvSqQbFNCs7SOAqgqynbW0tXqPU8Tk',
                name: 'Harshita Khemani',
                about: "I'm very grateful to have been able to contribute towards encouraging my peers and instilling confidence in them. LeanIn offers a vibrant community and platform to those who believe in achieving their goals by uplifting others.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2021-22",
            },
            {
                id: 15,
                profilePic: 'https://drive.google.com/uc?export=view&id=1p7HRDkLxtoiQ2HpkAFTilM2Y8I-9_JZM',
                name: 'Aditi Agrawal',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/aditi-agrawal27/',
                year: "2021-22",
            },
            {
                id: 16,
                profilePic: 'https://drive.google.com/uc?export=view&id=1IGc1z_XNPDQ8PBPFhUe0EeeQXDGDcHDT',
                name: 'Nishi',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/nishi-a414541bb/',
                year: "2021-22",
            },
            {
                id: 17,
                profilePic: 'https://drive.google.com/uc?export=view&id=1aifMQ1k3jmb3VuyRn0oOg6zSyY17bHTX',
                name: 'Nikita Garg',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/nikita-garg-252345202/',
                year: "2021-22",
            },
        ],
        feedback: [
            {
                id: 'id1',
                img: 'https://www.w3schools.com/w3images/avatar6.png',
                name: 'Anushka Dahiya',
                feedback: 'Learnt new data structures and algorithms, Learnt about various opportunities, Made connections, Discovered new things about technology, Gained knowledge and resources, Gained experience and confidence',
                designation: 'Mentee',
            },
            {
                id: 'id2',
                img: 'https://www.w3schools.com/howto/img_avatar2.png',
                name: 'Anonymous',
                feedback: 'Learnt a new tech Stack, Learnt new data structures and algorithms, Gained knowledge and resources, Participated in programs and events, Gained experience and confidence',
                designation: 'Mentee',
            },
            {
                id: 'id3',
                img: 'https://www.w3schools.com/w3images/avatar5.png',
                name: 'Avni Uplabdhee',
                feedback: 'Learnt new data structures and algorithms, Learnt about various opportunities, Made connections, Gained knowledge and resources, Participated in programs and events, Gained experience and confidence.',
                designation: 'Mentee',
            },
            {
                id: 'id4',
                img: 'https://cdn.pixabay.com/photo/2021/02/12/07/03/icon-6007530_640.png',
                name: 'Anonymous',
                feedback: 'Learnt new data structures and algorithms, Learnt about various opportunities, Participated in programs and events',
                designation: 'Mentee',
            },
            {
                id: 'id5',
                img: 'https://i.pinimg.com/originals/a6/58/32/a65832155622ac173337874f02b218fb.png',
                name: 'Plaksha',
                feedback: 'Learnt new data structures and algorithms',
                designation: 'Mentee',
            },
            {
                id: 'id6',
                img: 'https://www.maxpixel.net/static/photo/1x/Girl-Short-Hair-Icon-Girl-Icon-Vector-Art-5887113.png',
                name: 'Priyanshi sharma',
                feedback: 'Learnt new data structures and algorithms, Made connections, Gained knowledge and resources, Participated in programs and events, Gained experience and confidence.',
                designation: 'Mentee',
            },
            {
                id: 'id7',
                img: 'https://cdn1.vectorstock.com/i/1000x1000/94/70/brunette-girl-icon-flat-style-vector-12459470.jpg',
                name: 'Nishtha Agarwal',
                feedback: 'Learnt new data structures and algorithms',
                designation: 'Mentee',
            },
        ]
    },
    {
        id: '6',
        name: 'AR/VR',
        tagline: 'Augmented reality (AR) refers to computer-generated simulations which integrate digital information with the real world. It is an enhanced version of the real physical world that is achieved through the use of digital visual elements, sound, or other sensory stimuli delivered via technology.',
        about: `Our goal is to help the mentees become familiar with Unity and Spark AR so that they get skilled enough to build their own projects. Since there are a lot of beginners in the circle, we start with teaching the basics of Unity. After the mentees become familiar with the basic functionalities of Unity we proceed further in three steps. The first step is to introduce them to 2D games, followed by 3D games and ultimately AR apps. Mini projects are assigned to students after every step and eventually, each student is assigned a main project at the end. After working on Unity we introduce Spark AR to the mentees using which they can make Facebook and Instagram filters and share them with the world! 
        We have regular presentation sessions where the students can showcase their projects. During the entire cohort, the mentors keep sharing valuable resources and articles with the mentees. We also have doubt clearing sessions and chat doubt clearing support. 
        `,
        reason: `As we all know, the ‘Metaverse’ is the future and will transform society in the coming years. Augmented reality will be our gateway to the Metaverse.  As Satya Nadella rightly said, “I feel that Augmented Reality is perhaps the ultimate computer”, AR will eventually replace phones and desktops as our primary interface to digital content. 
        Joining this circle will help the mentees in connecting with like-minded people and dedicated mentors with whom they can explore this emerging technology and build amazing projects which will enhance their resumes.
        `,
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: '#',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '04 Sep',
                enddate: '31 Jan',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: 'Introduction to Unity',
                date: 'Sep 01 2022',
                category: {
                    tag: 'Intro',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: '2D Games, Mini Game with Presentation',
                date: 'Sep 15 2022',
                category: {
                    tag: '2D Basics',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: '3D Games, Mini Game with Presentation',
                date: 'Oct 01 2022',
                category: {
                    tag: '3D Basics',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'AR Application, Main Project Presentation',
                date: 'Oct 15 2022',
                category: {
                    tag: 'Hands-on learning',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Introduction to Spark AR',
                date: 'Oct 31 2022',
                category: {
                    tag: 'Intermediate-1',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Basic Filters',
                date: 'Nov 15 2022',
                category: {
                    tag: 'Intermediate-2',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Advanced Filters',
                date: 'Dec 01 2022',
                category: {
                    tag: 'Advanced-1',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Filter Publishing and Presentation',
                date: 'Dec 15 2022',
                category: {
                    tag: 'Advanced-2',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1DFRhWA3imlpjh0PJQxW2kt2uZLTDkF5Z',
                name: 'Shubhi Yadav',
                about: "Being a logician, I love doing anything that makes me wanna rack my brain and spark my creative spirit. From exploring deeper into Unity and AR, interacting with the mentees, and helping them, Lean In has been a growing experience for me.",
                socialMedia: 'https://www.linkedin.com/in/shubhi-yadav-a155121b9/',
                year: "2021-22",
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1a7rrnIp9C4T4IfiQ1wkzlI4RT2_TpTUO',
                name: 'Anmol Jha',
                about: "I'm a creative and enthusiastic person who loves to learn and explore new technologies. Learning and growing together with all the mentees at LeanIn has been an amazing experience for me.",
                socialMedia: 'https://www.linkedin.com/in/anmol-jha/',
                year: "2021-22",
            },
            {
                id: 3,
                profilePic: 'https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=930&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2022-23",
            },
        ],
        feedback: []
    },
    {
        id: '7',
        name: 'IP',
        tagline: `Interview Preparation Circle provides you with a platform to practice for an actual
        job interview. Get the opportunity to polish your communication skills, and 
       prepare answers to some of the most commonly asked interview questions. 
       `,
        about: `This circle is suitable for anyone who has a thorough knowledge of Data Structures and Algorithms. Proficiency in at least one coding language is a must.
        Mentees will be provided with a question bank of important questions for every topic. In addition to monthly mock interviews, there will be weekly doubt sessions, and sessions on important topics (On-demand).
        `,
        reason: 'Do you have a fear of interviews? Do you want a chance to become job-ready by giving mock interviews? Do you want to get personalized feedback? This is the perfect platform to practice your skills and bag the job of your dreams',
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: '#',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '1 Sep',
                enddate: '31 Dec',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: 'Arrays, Strings',
                date: 'Sep 01 2022',
                category: {
                    tag: 'Basics-1',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `LinkedList, Stacks, Queues
                Recursion & BackTracking
                `,
                date: 'Sep 15 2022',
                category: {
                    tag: 'Basics-2',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `DP+arrays (Introductory)
                Maps, Priority Queues
                Greedy
                `,
                date: 'Oct 01 2022',
                category: {
                    tag: 'Intermediate',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `Greedy
                Dynamic Programming
                `,
                date: 'Nov 18 2022',
                category: {
                    tag: 'Advanced-1',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `Greedy+DP (if any)
                Trees, Graphs                
                `,
                date: 'Dec 18 2022',
                category: {
                    tag: 'Advanced-2',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=1iMPIwqrmmc9B3ito6TJmq4QLTDNOvp6T',
                name: 'Isha Dubey',
                about: "I loved mentoring my juniors and it was an enriching experience to meet and interact with so many curious minds in LeanIn. ",
                socialMedia: 'https://www.linkedin.com/in/isha-dubey-a99728192',
                year: "2021-22",
            },
            {
                id: 6,
                // profilePic: 'https://drive.google.com/uc?export=view&id=1KZdqTnBO99nqICpRHBES9qWABOqRxduX',
                profilePic: 'https://drive.google.com/uc?export=view&id=1u5N8ov6DwIwvxtLQyoQbLbSbllBjY-Kk',
                name: 'Dhriti Arora',
                about: "Curious and passionate individual towards technology and problem-solving.  Avid Learner and enjoys working on challenging projects",
                socialMedia: 'https://www.linkedin.com/in/dhritiarora24',
                year: "2021-22",
            },
            {
                id: 7,
                profilePic: 'https://drive.google.com/uc?export=view&id=1ypXnzIJdd_JZ_LgAQow23WyRKBzA9sdF',
                name: 'Yashmita',
                about: "Loved being a mentor in IP circle. It was an amazing learning experience for both me and the mentees.",
                socialMedia: 'https://www.linkedin.com/in/yashmita-balotiya/',
                year: "2021-22",
            },
            {
                id: 8,
                profilePic: 'https://drive.google.com/uc?export=view&id=1kYIEFfS1UZQTOwCBK8aJj-Ax2GZBydn4',
                name: 'Sataakshi',
                about: "Mentoring students, teaching and learning with them is always a good experience. ",
                socialMedia: 'https://www.linkedin.com/in/sataakshibhangelwal',
                year: "2021-22",
            },
            {
                id: 9,
                profilePic: 'https://drive.google.com/uc?export=view&id=1iLKsHNYrNYrFQa5pQRvleLtrLX12_ow4',
                name: 'Raksha Jain',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/raksha--jain',
                year: "2021-22",
            },
            {
                id: 10,
                profilePic: 'https://drive.google.com/uc?export=view&id=1jFsatklo4hU5561begKBXREeDaM9UciF',
                name: 'Shahina Bano',
                about: "Being the part of leanIn is one of my best experiences and I hope that this society will enlighten the path to success for many more students.",
                socialMedia: 'https://www.linkedin.com/in/shahina-bano-704970192',
                year: "2021-22",
            },
            {
                id: 11,
                profilePic: 'https://drive.google.com/uc?export=view&id=1xFNWOfoDfxvj6nmikDufMcXgYpnhwRFS',
                name: 'Sukriti Dawar',
                about: "It was great to be a part of IP circle. I loved taking sessions and mock interviews for mentees.",
                socialMedia: 'https://www.linkedin.com/in/sukriti-dawar/',
                year: "2021-22",
            },
            {
                id: 13,
                profilePic: 'https://drive.google.com/uc?export=view&id=1PkcGeun36r18WIaSeeENlwkojX4DYtf0',
                name: 'Aayushi Bansal ',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/aayushi-bansal1111',
                year: "2021-22",
            },
        ],
        feedback: [
            {
                id: 'id1',
                img: 'https://www.w3schools.com/w3images/avatar6.png',
                name: 'Anonymous',
                feedback: 'Learnt new data structures and algorithms, Learnt about various opportunities, Made connections, Gained knowledge and resources',
                designation: 'Mentee',
            },
            {
                id: 'id2',
                img: 'https://www.w3schools.com/howto/img_avatar2.png',
                name: 'Anonymous',
                feedback: 'Learnt new data structures and algorithms, Made connections, Gained knowledge and resources',
                designation: 'Mentee',
            },
            {
                id: 'id3',
                img: 'https://www.w3schools.com/w3images/avatar5.png',
                name: 'Anonymous',
                feedback: 'Learnt new data structures and algorithms, Learnt about various opportunities, Made connections, Gained knowledge and resources',
                designation: 'Mentee',
            },
            {
                id: 'id4',
                img: 'https://cdn.pixabay.com/photo/2021/02/12/07/03/icon-6007530_640.png',
                name: 'Shalini Kumari',
                feedback: 'Learnt about various opportunities, Made connections, Gained knowledge and resources, Participated in programs and events, Gained experience and confidence',
                designation: 'Mentee',
            },
            {
                id: 'id5',
                img: 'https://i.pinimg.com/originals/a6/58/32/a65832155622ac173337874f02b218fb.png',
                name: 'Anonymous',
                feedback: 'Learnt new data structures and algorithms, Made connections',
                designation: 'Mentee',
            },
        ]
    },
    {
        id: '8',
        name: 'WEBD',
        tagline: 'Web development is the building and maintenance of websites. It\'s the work that happens behind the scenes to make a website look great, work fast and perform well with a seamless user experience.',
        about: 'The topics taught in the Web Development circle are Git Github, Bootstrap, MERN Stack, HTML, Flask, CSS and  Basic JavaScript',
        reason: 'Webd circle believes in learning by doing principle. Everything is taught from scratch and demo is also provided by our able mentors who are widely experienced in the field',
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: 'https://www.google.com/',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '1 Sep',
                enddate: '31 Dec',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: 'Git GitHub, HTML, Bootsstrap, CSS',
                date: 'Sep 01 2022',
                category: {
                    tag: 'Basics-1',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Basic Javascript',
                date: 'Oct 01 2022',
                category: {
                    tag: 'Basics-2',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'MERN Stack, Flask',
                date: 'Nov 01 2022',
                category: {
                    tag: 'Advanced',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Major Project',
                date: 'Dec 01 2022',
                category: {
                    tag: 'Hands-on learning',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1yo6ax0uOAJBWcofjb2lcSAdDZzececmv',
                name: 'Jhanvee Khola',
                about: "Web Development is a field that I am highly interested in and so it was a great pleasure to be a mentor in the WebD circle. Although it didn't cover each and every aspect of web development, it sure gave the mentees a smooth kickstart into the vast field and I'm truly happy that I was able to be a part of this cohort of Lean In.",
                socialMedia: 'https://www.linkedin.com/in/jhanvee-khola',
                year: "2021-22",
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1LRP2h_Ny8yU6A0v3yToHY9rFMQP7CQIB',
                name: 'Radha Singh',
                about: "It was really an amazing experience as a Mentor in Web Dev circle. I really enjoyed giving sessions and interacting with my amazing mentees. They were all supportive and with a positive attitude of learning they always motivated me to deliver amazing content.",
                socialMedia: 'https://www.linkedin.com/in/radhasingh210/',
                year: "2021-22",
            },
            {
                id: 3,
                profilePic: 'https://drive.google.com/uc?export=view&id=1luyUdOKE3Vudp1KlxPzq6dqSBWM_4Rrh',
                name: 'Akshita singh',
                about: "web development helps us to see the realtime implementation of our code .which itself is amazing ,for the first time thinking of number of different small websites that I could make to teach students different diffrent topics was great",
                socialMedia: 'https://www.linkedin.com/in/akshita-singh-436410192',
                year: "2021-22",
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=1HQvHa2UgoECWw-GdsGMxju3jiF5CpS3x',
                name: 'Punerva Singh',
                about: "It was a great experience being a mentor in the web development circle of leanIn society. All the mentees along with fellow mentors allowed me to enhance my technical and soft skills.",
                socialMedia: 'https://www.linkedin.com/in/punerva-singh-958305204/',
                year: "2021-22",
            },
            {
                id: 5,
                profilePic: 'https://drive.google.com/uc?export=view&id=1nmj4ssOVGpZjV50MqUI6xJ5VTVEsQB4Q',
                name: 'Sanya Bansal',
                about: "",
                socialMedia: 'https://www.linkedin.com/in/bansal-sanya/',
                year: "2021-22",
            },
            {
                id: 6,
                profilePic: 'https://images.unsplash.com/photo-1516195851888-6f1a981a862e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=810&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2022-23",
            },
        ],
        feedback: [
            {
                id: 'id1',
                img: 'https://www.w3schools.com/w3images/avatar6.png',
                name: 'Vaishali',
                feedback: 'Learnt a new tech Stack, Made a project, Discovered new things about technology, Gained knowledge and resources',
                designation: 'Mentee',
            },
            {
                id: 'id2',
                img: 'https://www.w3schools.com/howto/img_avatar2.png',
                name: 'Anonymous',
                feedback: 'Learnt a new tech Stack, Learnt new data structures and algorithms, Learnt about various opportunities, Made connections, Discovered new things about technology, Gained knowledge and resources, Gained experience and confidence',
                designation: 'Mentee',
            },
            {
                id: 'id3',
                img: 'https://www.w3schools.com/w3images/avatar5.png',
                name: 'Anonymous',
                feedback: 'Learnt a new tech Stack, Learnt about various opportunities, Made connections, Gained knowledge and resources, Gained experience and confidence',
                designation: 'Mentee',
            },
        ]
    },
    {
        id: '9',
        name: 'APPD',
        tagline: `The platform that’s changing what mobile can do.
        Android app development is the process of developing an app for devices running on the Android operating system. With this, you can grab the opportunity to solve any real-world problem that will help any earth-inhabitant.
        `,
        about: `As part of this circle, you will gain exposure to a range of new technologies, software and programming languages enabling you to become an independent app developer by the end of the cohort. The circle is suitable for anyone with knowledge of basic programming as everything is taught more or less from scratch. The entire course is divided into various stages and the mentees are constantly learning new concepts while building new and inspiring projects. Major technologies that are taught in this circle are listed below:
        SOFTWARE: Android Studio
        TECH STACK: XML, Java, Kotlin, SQL
        `,
        reason: `There is no denying that today android is transforming lives at the speed of lightning. Android has become an indispensable part of every professional, industrial, and personal user’s life today. There is immense scope for android developers in current times, they are offered one of the highest-paid jobs, a ton of career opportunities and are in great demand. Apart from this, with the knowledge of app development technologies, it is easy to make the transition to other frameworks in the development field. 
        So, if you’re someone who is constantly amazed by the revolution apps are bringing into our lives, there is no better opportunity to kickstart your journey as an android developer with Lean In App Development Circle!
        `,
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: '#',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '1 Sep',
                enddate: '31 Dec',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: `Get familiar with Android Studio and XML. 
                Learn front-end components 
                `,
                date: 'Sep 01 2022',
                category: {
                    tag: 'Basics',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Dive deep into app development- Java, SQL and some OOPS Concepts',
                date: 'Sep 15 2022',
                category: {
                    tag: 'Intermediate',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Project Development Phase',
                date: 'Oct 01 2019',
                category: {
                    tag: 'Hands-on learning',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Exploring more technologies in App Development like Flutter, NodeJS, etc.',
                date: 'Nov 18 2019',
                category: {
                    tag: 'Advanced',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1kJFPN4tK0G4ZlTXYGhECiAKAFlArBycf',
                name: 'Somya Tomar',
                about: "I was happy to see an amazing level of enthusiasm for learning technology amongst mentees. This inspired me to learn more and share more as a mentor.",
                socialMedia: 'https://www.linkedin.com/in/somya-tomar-6229a8190/',
                year: "2021-22",
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=16lB7fv0qJyWGwtQe0Q5-iNNS1MtkZd6P',
                name: 'Priyanshi Sharma',
                about: "Being a mentor had been an amazing experience for me. It was grest to interact and guide such enthusiastic mentees. The enthusiasm of mentees inspired me to grow and learn more",
                socialMedia: 'https://www.linkedin.com/in/priyanshi-sharma-0918911b3',
                year: "2021-22",
            },
            {
                id: 3,
                profilePic: 'https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=930&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2022-23",
            },
        ],
        feedback: [
            {
                id: 'id1',
                img: 'https://www.w3schools.com/w3images/avatar6.png',
                name: 'Anonymous',
                feedback: 'Made a project, Gained knowledge and resources, Gained experience and confidence',
                designation: 'Mentee',
            },
            {
                id: 'id2',
                img: 'https://www.w3schools.com/howto/img_avatar2.png',
                name: 'ALKA JAISWAL',
                feedback: 'Learnt a new tech Stack, Made a project, Gained knowledge and resources',
                designation: 'Mentee',
            },
            {
                id: 'id3',
                img: 'https://www.w3schools.com/w3images/avatar5.png',
                name: 'Anonymous',
                feedback: 'Made connections, Gained knowledge and resources',
                designation: 'Mentee',
            },
        ]
    },
    {
        id: '-1',
    },
    {
        id: '11',
        name: 'IOT',
        tagline: 'Internet of Things refers to a network of individual sensors, actuators and microcontrollers with the ability to communicate with each other and perform desired actions. Iot has several applications in daily life in fields such as agriculture, manufacturing, home and factory automation, robotics and many more.',
        about: 'The circle focuses on working with a variety of sensors, collecting and processing data from them using microcontroller (Arduino Uno) and figuring out how to act on the data: whether to manipulate an actuator directly, or to transmit the data to a remote location for application.',
        reason: 'The purpose of the IoT circle is to develop confidence in mentees in their abilities to bring to life whatever project they have in mind. All the learning is done around the chosen projects of the mentees, focusing on how to implement sensors, Wi-Fi modules or any other hardware or software to create what the mentees wish to build. For students with enthusiasm for working hands-on and getting a head start in dealing with hardware, IoT is the way to go',
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: '#',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '1 Sep',
                enddate: '31 Dec',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: `Microcontrollers processors + Sensors and actuators, 
                Arduino Uno: ( 
                Pin config,
                Cable, 
                Buttons,
                Clock,
                Ic Atmega 328,
                Serial ports,
                Introduction to Tinkercad)
                `,
                date: 'Sep 01 2022',
                category: {
                    tag: 'Basics',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `Sensors - on tinkercad with some live demos:
                (PIr,
                Proximity,
                Ultrasonic,
                Photoresistor,
                IR,
                Potentiometer +PWM,
                Buttons,
                Gas Sensor,
                Flame sensor,
                Humidity,
                Moisture,
                Temperature,
                Piezoelectric),
                Actuator - also on tinkercad with some live demos:
                (Buzzer,
                Motors,
                DC,
                Servo,
                Stepper,
                Led,
                Rgb,),
                LCD,
                Keypad,
                7 segment,
                NodeMCU ESP8266 - (also tinkercad)
                `,
                date: 'Oct 01 2022',
                category: {
                    tag: 'Intermediate',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `Cloud Platform ---ThingSpeak -- tinkercad thingspeak read/write
                Project Commencement
                `,
                date: 'Nov 01 2019',
                category: {
                    tag: 'Advanced',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `Project building with mentoring`,
                date: 'Dec 01 2019',
                category: {
                    tag: 'Hands-on learning',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1G1bFLduB8jRydbNNNNu1g2CfWmVmWuz5',
                name: 'Sukritee Sharma',
                about: "I am an artist pursuing engineering to create technology by the combination of both.",
                socialMedia: 'http://linkedin.com/in/sukritee-sharma',
                year: "2021-22",
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1j3lkOh7jCVMIq6w2ZOKU9V7tumJpLry1',
                name: 'Divya Pant',
                about: "Always been passionate towards electronics and harware projects. This gave me the opprtunity to mentor my young fellows to enhance their knowledge in this field. Keep Leaning In!!",
                socialMedia: 'https://www.linkedin.com/in/divya-pant-094a0819a/',
                year: "2021-22",
            },
            {
                id: 3,
                profilePic: 'https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=930&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2022-23",
            },
        ],
        feedback: [
            {
                id: 'id1',
                img: 'https://www.w3schools.com/w3images/avatar6.png',
                name: 'Adhya',
                feedback: 'Learnt a new tech Stack, Learnt about various opportunities, Made connections, Discovered new things about technology, Gained knowledge and resources, Gained experience and confidence',
                designation: 'Mentee',
            },
            {
                id: 'id2',
                img: 'https://www.w3schools.com/howto/img_avatar2.png',
                name: 'Anonymous',
                feedback: 'Made a project, Gained knowledge and resources, Gained experience and confidence',
                designation: 'Mentee',
            },
        ]
    },
    {
        id: '12',
        name: 'ML',
        tagline: `“Predicting Future is not Magic, it's Artificial Intelligence”- DAVE WATERS.
        Machine learning is the science of getting computers to act without being explicitly programmed.
        `,
        about: `As part of this circle, you will gain exposure to a range of new technologies, software, python programming, data science(exploratory data analysis along with a mini project), OpenCV and Machine Learning Algorithm`,
        reason: `Studying Machine Learning opens a world of opportunities to develop cutting edge applications in various areas, such as cybersecurity, image recognition, medicine, and face recognition. No matter where or how it is used, businesses describe its machine learning benefits in terms of exponential gains and improvements. By allowing businesses to process and analyze data more quickly than ever before, machine learning enables rapid – even split-second – decision making.`,
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: '#',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '01 Sep',
                enddate: '31 Dec',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: 'Python Basics',
                date: 'Sep 01 2022',
                category: {
                    tag: 'Basics',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Preprocessing',
                date: 'Oct 01 2022',
                category: {
                    tag: 'Intermediate',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Machine Laerning and Deep Learning Models',
                date: 'Nov 01 2022',
                category: {
                    tag: 'Advanced',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: 'Implementing various ML applications',
                date: 'Dec 01 2022',
                category: {
                    tag: 'Hands-on learning',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1c7-U-u-GetUm3H7eh6-K0AB4OWA013Yg',
                name: 'Sristi',
                about: `Pre-Final Year CSE Student at IGDTUW. "Really glad to be a part of Lean In and mentoring such a creative, intelligent and hard-working student community."`,
                socialMedia: 'https://www.linkedin.com/in/sristi-4a680b207',
                year: "2021-22",
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=1yiqP3kBI0sIeGF4S0jf0-d8vn2KyguHG',
                name: 'Anjali Singh',
                about: "I learnt alot while mentoring the students in Machine Learning. It was an wonderful experience",
                socialMedia: 'https://www.linkedin.com/mwlite/in/anjali-singh-282001',
                year: "2021-22",
            },
            {
                id: 3,
                profilePic: 'https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=930&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2022-23",
            },
        ],
        feedback: [
            {
                id: 'id1',
                img: 'https://www.maxpixel.net/static/photo/1x/Girl-Short-Hair-Icon-Girl-Icon-Vector-Art-5887113.png',
                name: 'Palak Jain',
                feedback: 'Learnt a new tech Stack, Learnt about various opportunities, Discovered new things about technology, Gained knowledge and resources',
                designation: 'Mentee',
            },
            {
                id: 'id2',
                img: 'https://i.pinimg.com/originals/a6/58/32/a65832155622ac173337874f02b218fb.png',
                name: 'Anonymous',
                feedback: 'Learnt about various opportunities, Made connections, Gained knowledge and resources, Gained experience and confidence.',
                designation: 'Mentee',
            },
        ]
    },
    {
        id: '-1',
    },
    {
        id: '-1',
    },
    {
        id: '15',
        name: 'Basic Coding',
        tagline: 'Take your first step towards coding with Lean In IGDTUW\'s Basic Coding Circle !',
        about: 'This circle is suitable for anyone who is new to coding and wants to build a strong foundation. As a beginner you can choose between your preferred language between C++ and Java, following which you are assigned into subgroups. The course curriculum includes basics of programming, loops and functions, patterns, arrays, matrix, searching and sorting, strings, recursion, linked list, stacks and queues. The mentors explain each and every concept in detail, solving example questions in the class and providing the mentees a questionbank to practice after class. There are weekly doubt sessions and an interactive support network for collaborative learning.',
        reason: `No prequisites.
        Learn to code from scratch.
        Learn to submit problems on online judges successfully.
        Accelerate your algorithmic thinking and problem solving skills.
        `,
        dates: [
            {
                name: 'Mentor Registration',
                status: 'closed',
                startdate: '17 Aug',
                enddate: '20 Aug',
                link: '#',
                img: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Mentee Registration',
                status: 'closed',
                startdate: '25 Aug',
                enddate: '30 Aug',
                link: 'https://forms.gle/SLno2a349vj8YXuZ9',
                img: 'https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            },
            {
                name: 'Course Duration',
                status: 'check',
                startdate: '01 Sep',
                enddate: '31 Dec',
                link: '#timeline',
                img: 'https://images.unsplash.com/photo-1523289333742-be1143f6b766?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
            }
        ],
        timeline: [
            {
                text: `basic syntax
                data types
                variables
                keywords
                basic operators`,
                date: 'Jan - Feb',
                category: {
                    tag: 'Basic - 1',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `decision making
                loops
                functions`,
                date: 'Feb - March',
                category: {
                    tag: 'Basics - 2',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            },
            {
                text: `arrays,
                searching & Sorting
                Strings
                Linked list
                Stacks and Queues`,
                date: 'March - April',
                category: {
                    tag: 'Ds & Algo',
                    color: '#e17b77'
                },
                link: {
                    url: '#',
                    text: 'Check it out'
                }
            }
        ],
        mentor: [
            {
                id: 1,
                profilePic: 'https://drive.google.com/uc?export=view&id=1VYrQ-H2qiAOjxIEJwOxIdWLDIOLSWp1k',
                // profilePic: 'https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
                name: 'Anushka Singh',
                about: `I believe that having good mentors and seniors in college can have a positive impact on your college life, and that is what motivated me to join LeanIn IGDTUW. `,
                socialMedia: 'https://www.linkedin.com/in/anushka-singh-6673b2151/',
                year: "2021-22",
            },
            {
                id: 2,
                profilePic: 'https://drive.google.com/uc?export=view&id=16vhgSkwk5i91a_k4QPpdpCTBlNRI30pg',
                name: 'Kavita Meena',
                about: `I am glad that I was a part of Lean In, it helped me in exploring the mentor inside me and gave the opportunity to guide and impact young & passionate minds.`,
                socialMedia: 'https://www.linkedin.com/in/kavita-meena-5386771b7/',
                year: "2021-22",
            },
            {
                id: 3,
                profilePic: 'https://drive.google.com/uc?export=view&id=1DFfMTHHrUIhG23J3kTM57Y6ChMnCBt2L',
                name: 'Srishti Gupta',
                about: `if you have knowledge let others light their candles in it. Lean in provided me a platform to guide and mentor my juniors `,
                socialMedia: 'https://www.linkedin.com/in/srishti-gupta-7250b8203',
                year: "2021-22",
            },
            {
                id: 4,
                profilePic: 'https://drive.google.com/uc?export=view&id=17gtDu7ov2YsVSLvXUKugpKjgEJJQpDw1',
                name: 'Tanisha Kindo',
                about: `Being a mentor in LeanIn gave me an opportunity to share and impart my knowledge to my Juniors. I am glad that I could contribute a little to their learning path towards their brighter future. `,
                socialMedia: 'https://www.linkedin.com/in/tanisha-kindo-133843203',
                year: "2021-22",
            },
            {
                id: 5,
                // profilePic: 'https://drive.google.com/uc?export=view&id=1ah22G9iaMu8dCDVc7FHBvYWVvF04GaHC',
                profilePic: 'https://drive.google.com/uc?export=view&id=17wOow_jt2WGWUrsqxDgEDRjdMzTixZHj',
                name: 'Surbhi Dhir ',
                about: ``,
                socialMedia: '',
                year: "2021-22",
            },
            {
                id: 6,
                profilePic: 'https://drive.google.com/uc?export=view&id=1RR4dS8R_H70yEzmgnNnKWllRVef_4uDQ',
                name: 'Areen Kaur',
                about: '',
                socialMedia: 'https://www.linkedin.com/in/areen-kaur',
                year: "2021-22",
            },
            {
                id: 7,
                profilePic: 'https://drive.google.com/uc?export=view&id=1PkcGeun36r18WIaSeeENlwkojX4DYtf0',
                name: 'Aayushi Bansal',
                about: ``,
                socialMedia: 'https://www.linkedin.com/in/aayushi-bansal1111',
                year: "2021-22",
            },
            {
                id: 8,
                profilePic: 'https://drive.google.com/uc?export=view&id=1UpVWvUWc7wEzI5CTo-7qUtAu-PypzbjN',
                name: 'Harshita Singh',
                about: `It was a great pleasure to be a part of LeanIn as a mentor. Teaching them dsa concepts also helped me to improve my learning as well.`,
                socialMedia: '',
                year: "2021-22",
            },
            {
                id: 9,
                profilePic: 'https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=930&q=80',
                name: 'Member Name',
                about: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus aperiam recusandae provident fugit rerum, ipsam placeat cupiditate in fugiat nisi.",
                socialMedia: 'https://www.linkedin.com/',
                year: "2022-23",
            },
        ],
        feedback: []
    },
    {
        id: '-1',
    },
    {
        id: '-1',
    },
    {
        id: '-1',
    }
]

export default CircleInfo;
