import React from "react";
import "./alumni.css";
import Header from "../../components/Header/Header";
import Blog from "../../components/Blog/blog";
import Footer from "../../components/footer/Footer";
import blogData from "../../Data/blogData";
import AlumniSVG from "../../SVG/alumni/alumniSVG";

export default function Alumni() {
    return (
        <>
            <Header />
            <div className="alumni-div">
                <div className="alumni-left-div">
                    <div class="container nav_container mt-5">
                        <div class="shap one"></div>
                        <div class="shap two"></div>

                        <div class="col-md-offset-3 col-sm-10 col-md-8 text-center mauto">
                            <AlumniSVG />
                        </div>
                    </div>
                </div>
                <div className="alumni-right-div">
                    <div class="hat item">
                        <svg width="80" height="100" viewBox="1015 224 171 108" xmlns="http://www.w3.org/2000/svg">
                            <g id="Graduation" fill="none" fill-rule="evenodd" transform="translate(1020 226)">
                                <path id="hat-top" stroke="var(--font-color)" stroke-width="4" d="M80.5.266l79.903 35.556L80.5 71.38.597 35.82z" />
                                <ellipse id="hat-button" fill="red" transform="rotate(80 79.62 36.307)" cx="79.619" cy="36.307" rx="3" ry="6.5" />
                                <path id="tassel-connect" fill="red" d="M38.203 52.12l40.322-17.115 1.562 3.682-40.32 17.116z" />
                                <path d="M39.065 55.117c.397-2.083 1.584-.754 1.584 0s1.24 32.313 0 35c-1.242 2.687-6.674 4.02-8.408 0-1.735-4.02 6.425-32.917 6.823-35z" id="tassel" stroke="red" stroke-width="4" />
                                <path d="M49 58.02l31.5 13.917L112 58.02V86c0 8.05-15.632 17.92-31.68 17.925C64.88 103.932 49 94.395 49 86V58.02z" id="hat" stroke="var(--font-color)" stroke-width="4" />
                            </g>
                        </svg>
                    </div>
                    <h1 className="heading-underline underline-text alumni-heading">Alumni Insights</h1>
                    <p >Lean In brings to you the opportunity to peek into the beautiful journeys of IGDTUW alumni. Here you get a chance to learn from their lessons and experiences, get guided by their personal suggestions, discover new paths, get inspired by them and widen your horizons, whilst beholding the way their hard work, perseverance, will power, dedication and passion lead to their successes. Bridging the gap between the students and alumni, Lean In celebrates the achievements of the alumni pursuing higher studies and the career milestones they have accomplished after graduation.</p>
                </div>
            </div>
            <div className="scroll">
                <p class="ctn-icon">
                    <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                </p>
            </div>
            <Blog blog={blogData} />
            <Footer />
        </>
    );
}