import React from 'react';
import { useLocation } from "react-router";
import Footer from '../../components/footer/Footer';
import Header from '../../components/Header/Header';
import OpportunityCard from "../../components/OpportunityCard/OpportunityCard";
import sessions from "../../Data/sessionsData";
import events from "../../Data/eventsData";
import tipsNtricksData from '../../Data/tipsNtricksData';
import "./sessions.css";

export default function Sessions(props) {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  return (
    <>
      {/* To sort all the data in the latest order, add new data at the start of the array in the respective files. */}
      <Header />
      {path === '1' ? <><center>
        <h1 className="heading-underline underline-text sessions-subheading">Sessions Page</h1>
      </center>
        <div className="session-main-div">
          <OpportunityCard data={sessions} />
        </div>
      </>
        :
        path === '2' ? <><center>
          <h1 className="heading-underline underline-text sessions-subheading">Events & Collaborations Page</h1>
        </center>
          <div className="session-main-div">
            <OpportunityCard data={events} />
          </div>
        </>
          :
          <><center>
            <h1 className="heading-underline underline-text sessions-subheading">Tips and Tricks Page</h1>
          </center>
            <div className="session-main-div">
              <OpportunityCard data={tipsNtricksData} />
            </div>
          </>
      }

      <Footer />
    </>
  );
}
