import React from "react";
import "./alumniSVG.css";

export default function AlumniSVG() {
    return (
        <div class="bg-svg">
                                <svg id="friends-floating" width="100%" height="100%" viewBox="0 0 1110 651" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="floor" d="M588.429 651c287.984 0 521.441-15.67 521.441-35s-233.457-35-521.441-35c-287.985 0-521.442 15.67-521.442 35s233.457 35 521.442 35z" fill="var(--font-color)" />
                                    <g id="plant">
                                        <path id="Vector" d="M237.146 603.521c-.229-.375-5.64-9.41-7.516-28.171-1.721-17.213-.614-46.227 14.432-86.699 28.506-76.671-6.569-138.533-6.927-139.149l1.73-1.004c.091.156 9.142 15.929 14.489 41.044a179.07 179.07 0 0 1-7.416 99.807c-28.457 76.54-7.301 112.773-7.084 113.13l-1.708 1.042z" fill="var(--font-color)" />
                                        <path id="Vector_2" d="M226 345c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z" fill="red" />
                                        <path id="Vector_3" d="M267 393c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z" fill="var(--font-color)" />
                                        <path id="Vector_4" d="M239 425c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z" fill="red" />
                                        <path id="Vector_5" d="M273 452c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z" fill="red" />
                                        <path id="Vector_6" d="M229 494c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z" fill="var(--font-color)" />
                                        <path id="Vector_7" d="M245 604s-13-32 26-56l-26 56z" fill="var(--font-color)" />
                                        <path id="Vector_8" d="M229.012 603.42s-5.916-34.03-51.708-33.738l51.708 33.738z" fill="var(--font-color)" />
                                    </g>
                                    <g id="woman">
                                        <path id="Vector_9" d="M871.698 18.238c-26.542 0-48.058 23.878-48.058 53.334v37.423h11.509l6.664-13.866-1.666 13.866h73.98l6.058-12.605-1.514 12.605H927V79.611c0-33.896-24.76-61.373-55.302-61.373z" fill="#2F2E41" />
                                        <path id="Vector_10" d="M848.5 85.5s15 35-5 42l20 66 34-2 9-22-2-33-3-22s-21 5-19-32l-34 3z" fill="#FFB8B8" />
                                        <path id="Vector_11" d="M840.5 126.5s-33-3-30 21 6 29 6 29l3 36-18 11h-17s-52 12-26 25 35-10 35-10 48 0 51-8-4-104-4-104z" fill="#FFB8B8" />
                                        <path id="Vector_12" d="M904.5 116.5s31-11 33 13 2 26 2 26l6 34s12 30 12 39 9 37 9 37 12 39-1 38-15-42-15-42l-27-54-8-33-11-58z" fill="#FFB8B8" />
                                        <path id="Vector_13" d="M845.5 122.5h-8l-1 42s-13 18 3 34l4 26 73-3 5-27s10-22-4-42l-13-41h-7s16.213 59.778-18 61c-28 1-34-50-34-50z" fill="red" />
                                        <path id="Vector_14" d="M847.5 219.5l-6 20s-125 352-87 361 91 21 96 12 31-272 31-272-4 275 9 275 99 2 104-11-45-361-74-369l-8-18-65 2z" fill="#2F2E41" />
                                        <path id="Vector_15" d="M919.5 209.5l-79 3s-3.606 27 0 27c1 0 83 7 82-5s-3-25-3-25z" fill="var(--font-color)" />
                                        <path id="Vector_16" d="M863.956 96.953c15.518 0 28.097-12.58 28.097-28.097 0-15.518-12.579-28.098-28.097-28.098s-28.098 12.58-28.098 28.098c0 15.518 12.58 28.097 28.098 28.097z" fill="#FFB8B8" />
                                        <path id="Vector_17" d="M885.36 32.93a25.207 25.207 0 0 0-19.887-10.149h-.944c-18.191 0-32.939 16.462-32.939 36.768h6.096l.984-7.493 1.444 7.493h36.152l3.029-6.34-.757 6.34h7.112c3.319 16.482-1.45 32.964-14.306 49.446h12.116l6.058-12.678-1.515 12.678h23.095l4.544-29.16c0-21.84-12.689-40.358-30.282-46.906z" fill="#2F2E41" />
                                        <path id="Vector_18" d="M892 36c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18z" fill="#2F2E41" />
                                    </g>
                                    <g id="friend-1" className="vert-move">
                                        <path id="Vector_19" d="M226 257a112.993 112.993 0 0 1-47.1 91.79 111.285 111.285 0 0 1-12.47 7.8 113.298 113.298 0 0 1-77.37 10.86 110.603 110.603 0 0 1-16.42-4.88A113.01 113.01 0 1 1 226 257z" fill="var(--font-color)" />
                                        <path id="Vector_20" d="M105.873 249.631c14.353 0 25.989-11.635 25.989-25.988 0-14.354-11.636-25.989-25.989-25.989-14.353 0-25.989 11.635-25.989 25.989 0 14.353 11.636 25.988 25.989 25.988z" fill="#FFB8B8" />
                                        <path id="Vector_21" d="M95.721 243.54s3.249 15.431 3.249 17.055c0 1.624 15.431 8.934 15.431 8.934l13.806-2.437 4.873-14.618s-8.121-12.183-8.121-17.055l-29.238 8.121z" fill="#FFB8B8" />
                                        <path id="Vector_22" d="M113 370c-8.048.004-16.073-.85-23.94-2.55-.39-7.95-.65-16.71-.65-16.71l-7.92-22.98-16.44-47.67 33.85-25.84s8.38 13.65 20.56 9.59a16.804 16.804 0 0 0 11.92-15.7l40.06 14.08-6.92 56.15-.39 3.14 3.3 35.08A112.449 112.449 0 0 1 113 370z" fill="#D0CDE1" />
                                        <path id="Vector_23" d="M83.336 202.704s8.251-17.878 24.066-13.752c15.815 4.126 24.754 10.314 25.442 16.503.687 6.188-.344 15.471-.344 15.471s-1.719-12.721-12.721-9.97c-11.002 2.75-28.192.687-28.192.687l-2.75 24.754s-3.094-4.469-6.533-1.719c-3.438 2.751-9.97-26.473 1.032-31.974z" fill="#2F2E41" />
                                        <path id="Vector_24" d="M49.07 350.18l5.23-44.1c-.81-17.06 9.75-25.99 9.75-25.99h12.18l8.93 30.05-4.67 17.62-5.88 22.17-1.63 2.44s1.63 2.43.81 3.25c-.81.81-1.62 4.06-1.62 4.06s.17 1.16.47 2.89a111.976 111.976 0 0 1-23.57-12.39z" fill="#D0CDE1" />
                                        <path id="Vector_25" d="M198.87 330.45a112.789 112.789 0 0 1-19.97 18.34 37.682 37.682 0 0 1-2.78-6.17l-12.6-24.25-8.51-16.36 1.62-42.23 13.81 2.44s8.12 8.12 11.37 17.87l6.5 29.23 10.55 21.12.01.01z" fill="#D0CDE1" />
                                        <path id="Vector_26" d="M186 204c18.225 0 33-14.775 33-33s-14.775-33-33-33-33 14.775-33 33 14.775 33 33 33z" fill="red" />
                                    </g>
                                    <g id="friend-2" className="vert-move2">
                                        <path id="Vector_27" d="M511 400a113.016 113.016 0 0 1-63.93 101.82c-.01 0-.01 0-.02.01a110.061 110.061 0 0 1-10.54 4.43c-.57.22-1.15.42-1.74.61-1.2.43-2.42.82-3.65 1.19-2.53.78-5.097 1.47-7.7 2.07h-.01l-.07.02-.16.03c-.34.08-.69.16-1.04.23-1.94.43-3.9.8-5.88 1.11A112.028 112.028 0 0 1 398 513c-3.28 0-6.54-.14-9.75-.42a4.53 4.53 0 0 1-.52-.05 113.427 113.427 0 0 1-25.86-5.43c-1.24-.42-2.48-.86-3.7-1.33A113.01 113.01 0 1 1 511 400z" fill="var(--font-color)" />
                                        <path id="Vector_28" d="M384.574 401.268s26.504-11.549 38.953.651c12.448 12.199-33.331 8.183-38.953-.651z" fill="var(--font-color)" />
                                        <path id="Vector_29" d="M430.339 346.497c-8.907-15.05-26.528-15.751-26.528-15.751s-17.171-2.196-28.186 20.725c-10.267 21.364-24.437 41.991-2.281 46.993l4.002-12.456 2.478 13.383a86.64 86.64 0 0 0 9.479.162c23.727-.766 46.323.224 45.596-8.29-.967-11.318 4.01-30.285-4.56-44.766z" fill="#2F2E41" />
                                        <path id="Vector_30" d="M388.991 379.181s4.016 26.504-4.016 29.717c-8.031 3.212 16.063 16.866 16.063 16.866h13.654l8.835-18.473s-8.032-14.456-3.213-28.11c4.819-13.653-31.323 0-31.323 0z" fill="#A0616A" />
                                        <path id="Vector_31" d="M434.77 506.87v-93.96s-1.02-9.65-9.11-7.14c-.827.264-1.63.599-2.4 1a27.781 27.781 0 0 0-3.82 2.49c-.46.35-.93.73-1.42 1.14-.04.03-.08.07-.12.1-15.26 12.85-22.48 4.02-24.09 2.41-.59-.58-2.13-2.23-3.86-4.09v-.01c-.75-.8-1.53-1.64-2.27-2.46-2.29-2.47-4.31-4.68-4.31-4.68l-8.03 6.42 1.96 48.68.46 11.48v.01l.15 3.57v.02l1.25 31.14.19 4.7a85.533 85.533 0 0 0 8.27 2.18c.82.17 1.69.34 2.58.5 6.96 1.26 15.72 1.91 21.28-1.08a19.757 19.757 0 0 1 3.52-1.47 24.436 24.436 0 0 1 7.46-1.03c.54.01 1.07.03 1.6.06h.01c2.384.169 4.746.574 7.05 1.21l3.65-1.19z" fill="var(--font-color)" />
                                        <path id="Vector_32" d="M396.25 503.91a48.855 48.855 0 0 1-6.05 6.46c-.68.62-1.36 1.22-2.05 1.81-.14.12-.28.23-.42.35a113.427 113.427 0 0 1-25.86-5.43c-1.24-.42-2.48-.86-3.7-1.33a37.287 37.287 0 0 0 1.81-6.03c5.53-25.92-10.55-65.26-11.95-71.57-.51-2.27 1.37-5.42 4.38-8.77 6.57-7.29 18.52-15.53 22.93-17.73a16.552 16.552 0 0 1 10.65-.98h.01c1.117.229 2.211.557 3.27.98v.01c.01.15.12 1.29.31 3.24.1 1.07.22 2.37.37 3.89v.01c.27 2.84.61 6.38 1.01 10.42v.01c2.41 24.89 6.77 68.21 8.47 75.58.63 2.73-.77 5.89-3.18 9.08z" fill="#D0CDE1" />
                                        <path id="Vector_33" d="M434.77 506.87c.59-.19 1.17-.39 1.74-.61a110.061 110.061 0 0 0 10.54-4.43c-5.14-15.02 10.05-62.77 14.4-77.55v-.02c.51-1.73.87-3.01 1.03-3.72 1.61-7.23-22.89-14.05-23.69-15.66-.62-1.22-10.57-2.45-15.26-2.96-1.45-.16-2.4-.25-2.4-.25-1.24.96-2.19 3.72-2.93 7.73-.07.32-.13.66-.18 1-.53 3.1-.94 6.85-1.27 11.06-1.82 22.96-1.2 59.24-2.86 74.18a30.42 30.42 0 0 0 .41 9.25c.182.988.416 1.966.7 2.93.349 1.256.77 2.492 1.26 3.7 1.98-.31 3.94-.68 5.88-1.11.35-.07.7-.15 1.04-.23l.16-.03.07-.02h.01c2.6-.6 5.167-1.29 7.7-2.07l3.65-1.19z" fill="#D0CDE1" />
                                        <path id="Vector_34" d="M428.747 371.551c.887 0 1.606-1.438 1.606-3.212 0-1.775-.719-3.213-1.606-3.213-.887 0-1.606 1.438-1.606 3.213 0 1.774.719 3.212 1.606 3.212z" fill="#A0616A" />
                                        <path id="Vector_35" d="M381.361 371.551c.887 0 1.607-1.438 1.607-3.212 0-1.775-.72-3.213-1.607-3.213s-1.606 1.438-1.606 3.213c0 1.774.719 3.212 1.606 3.212z" fill="#A0616A" />
                                        <path id="Vector_36" d="M434.77 506.87c.59-.19 1.17-.39 1.74-.61a110.061 110.061 0 0 0 10.54-4.43.03.03 0 0 0 .002.016c.001.005.004.01.008.014a.063.063 0 0 1 .01-.04c6-31.68 12.36-65.57 14.38-77.54v-.02c.35-2.08.57-3.49.63-4.12.8-8.03-19.28-8.03-19.28-8.03l-3.2 16.17-4.83 24.43-1.66 8.39-8.77 44.33-.28 1.42-.65 3.28h.01c2.6-.6 5.167-1.29 7.7-2.07l3.65-1.19z" fill="#D0CDE1" />
                                        <path id="Vector_37" d="M387.73 512.53a113.427 113.427 0 0 1-25.86-5.43c-.63-2.43-1.26-4.89-1.89-7.36-7.81-30.65-15.16-63.08-15.16-69.16 0-5.03 3.51-8.66 7.59-11.18a43.11 43.11 0 0 1 12.49-4.88s2.56 10.65 6.24 26.01c1.97 8.2 4.25 17.74 6.62 27.72v.01c2.76 11.58 5.65 23.74 8.33 35.1.43 1.84.857 3.653 1.28 5.44v.04a.112.112 0 0 1 .01.04l.24.99c.18.78.36 1.55.53 2.31.04.13.07.26.1.4a4.53 4.53 0 0 1-.52-.05z" fill="#D0CDE1" />
                                        <path id="Vector_3" opacity=".2" d="M369.314 438.213l18.071 70.67s-8.433-61.836-18.071-70.67z" fill="#000" />
                                        <path id="Vector_3" opacity=".2" d="M439.6 428.28l-4.83 24.43-1.66 8.39-8.76 44.33h-.01l-.27 1.42h-.01l-.64 3.28h-.01l-.07.02-.16.03-1.04.2c.09-1.12.2-2.32.32-3.59.04-.47.08-.95.13-1.45 1.88-20.43 5.89-55.72 12.18-70.5 1.48-3.48 3.09-5.82 4.83-6.56z" fill="#000" />
                                        <path id="Vector_4" d="M405.054 392.031c12.864 0 23.292-10.427 23.292-23.291 0-12.863-10.428-23.291-23.292-23.291-12.863 0-23.291 10.428-23.291 23.291 0 12.864 10.428 23.291 23.291 23.291z" fill="#A0616A" />
                                        <path id="Vector_41" d="M426.401 345.046l-17.409-9.119-24.041 3.73-4.974 21.969 12.382-.476 3.459-8.071v7.938l5.713-.22 3.316-12.85 2.073 13.679 20.31-.414-.829-16.166z" fill="#2F2E41" />
                                        <path id="Vector_42" d="M476 349c18.225 0 33-14.775 33-33s-14.775-33-33-33-33 14.775-33 33 14.775 33 33 33z" fill="red" />
                                        <path id="Vector_43" d="M434.77 506.87c.59-.19 1.17-.39 1.74-.61l-.13-.18-1.61-.09-10.42-.56h-.01l-1.75-.09-8.29-.45-18.05-.98-9.67-.52-.49-.03h-.12l-6.81-.37-2.22-.12-2.11 7.74c4.258.896 8.566 1.537 12.9 1.92.172.027.346.043.52.05 3.21.28 6.47.42 9.75.42 6.117.006 12.224-.489 18.26-1.48 1.98-.31 3.94-.68 5.88-1.11.35-.07.7-.15 1.04-.23l.16-.03.07-.02h.01c2.6-.6 5.167-1.29 7.7-2.07l3.65-1.19z" fill="#2F2E41" />
                                        <path id="Vector_44" opacity=".2" d="M416.26 511.52l5.88-1.14v.03c-1.94.43-3.9.8-5.88 1.11z" fill="#000" />
                                    </g>
                                    <g id="friend-3" className="vert-move3">
                                        <path id="Vector_45" d="M778 192a112.91 112.91 0 0 1-54.27 96.55c-2 1.22-4.04 2.37-6.12 3.47-3.1 1.64-6.29 3.14-9.55 4.47v.01c-1.04.43-2.08.84-3.13 1.23-.49.19-.99.38-1.49.56A113.257 113.257 0 0 1 665 305a113.485 113.485 0 0 1-25.57-2.9 111.51 111.51 0 0 1-12.85-3.8h-.01a112.601 112.601 0 0 1-15.5-6.98c-1.3-.7-2.58-1.43-3.84-2.19-.88-.52-1.74-1.05-2.6-1.59a113.01 113.01 0 0 1-36.064-154.459A113.007 113.007 0 0 1 778 192z" fill="var(--font-color)" />
                                        <path id="Vector_46" d="M651.974 175.504s.669 17.392-3.344 21.405c-4.014 4.014 20.067 18.061 20.067 18.061l14.047-22.074s-5.351-9.365-4.013-17.392h-26.757z" fill="#A0616A" />
                                        <path id="Vector_47" d="M663.68 185.203c13.3 0 24.081-10.781 24.081-24.081 0-13.299-10.781-24.08-24.081-24.08-13.299 0-24.081 10.781-24.081 24.08 0 13.3 10.782 24.081 24.081 24.081z" fill="#A0616A" />
                                        <path id="Vector_48" d="M704.49 292.15c.15 1.86.297 3.72.44 5.58-.49.19-.99.38-1.49.56A113.257 113.257 0 0 1 665 305a113.485 113.485 0 0 1-25.57-2.9 111.51 111.51 0 0 1-12.85-3.8l-.01.09a.139.139 0 0 1 0-.09c.153-2.04.536-4.056 1.14-6.01v-.01c.56-2.06 1.32-4.44 2.19-7.07.94-2.81 1-8.95.8-14.72-.02-.44-.03-.89-.05-1.32-.08-1.87-.18-3.66-.28-5.25-.23-3.59-.47-6.14-.47-6.14l-14.72-39.47 35.55-25.96c2.1 6.81 6.6 6.57 6.6 6.57 19.08 1.31 22.59-11.87 22.7-12.3v-.01l34.15 22.34-14.04 34.11s.57 5.78 1.38 14.6c.12 1.3.24 2.67.38 4.1.78 8.66 1.73 19.45 2.59 30.39z" fill="#D0CDE1" />
                                        <path id="Vector_49" d="M631.57 264.13l-.7 5.08-.17 1.28-2.97 21.7-.02.09v.01l-1.13 6.01-.01.09a.139.139 0 0 1 0-.09 112.601 112.601 0 0 1-15.5-6.98c-1.3-.7-2.58-1.43-3.84-2.19a69.559 69.559 0 0 1 2.94-7.6l2.04-15.88.63-4.86 17.53 3.13 1.2.21z" fill="#A0616A" />
                                        <path id="Vector_50" d="M624.883 217.311l-9.699 1.003s-6.354 47.158-4.348 47.158c2.007 0 24.75 5.352 25.419 4.014.669-1.338-11.372-52.175-11.372-52.175z" fill="#D0CDE1" />
                                        <path id="Vector_51" d="M645.1 136.438l-4.341-1.579s9.077-9.076 21.705-8.287l-3.552-3.552s8.682-3.157 16.575 5.131c4.149 4.356 8.949 9.477 11.942 15.245h4.649l-1.94 3.881 6.79 3.88-6.97-.697c.66 3.35.433 6.815-.659 10.05l-1.579 4.341s-6.314-12.628-6.314-14.206v3.946s-4.341-3.552-4.341-5.92l-2.367 2.763-1.184-4.341-14.602 4.341 2.368-3.552-9.076 1.184 3.551-4.341s-10.26 5.13-10.655 9.471c-.394 4.341-5.525 9.866-5.525 9.866l-2.367-3.946s-3.552-17.759 7.892-23.678z" fill="#2F2E41" />
                                        <path id="Vector_52" d="M723.73 288.55c-2 1.22-4.04 2.37-6.12 3.47-3.1 1.64-6.29 3.14-9.55 4.47v.01c-1.04.43-2.08.84-3.13 1.23-.49.19-.99.38-1.49.56l1.05-6.14-4.76-30.25-.6-3.79 2.39-.45 22.36-4.23v27.43s.06 2.97-.15 7.69z" fill="#A0616A" />
                                        <path id="Vector_53" d="M701.139 207.277l13.044 1.672s15.719 47.828 11.706 49.834c-4.014 2.007-30.101 3.345-30.101 3.345l5.351-54.851z" fill="#D0CDE1" />
                                        <path id="Vector_54" d="M747 145c18.225 0 33-14.775 33-33s-14.775-33-33-33-33 14.775-33 33 14.775 33 33 33z" fill="red" />
                                    </g>
                                </svg>
                            </div>
    );
}