const sessionsData = [
    {
        img: "https://drive.google.com/uc?export=view&id=12_KbXtvoc1xw8NGcAKEEYF7KQEFnr8On",
        date: "13 September 2020",
        category: "session",
        name: "INTRO TO COMPETITIVE PROGRAMMING",
        company: "Lean In IGDTUW",
        desc: "Know the tips on how to start with introduction to competitive programming by Jayant Sharma, software engineer at Google.",
        link: "https://www.youtube.com/watch?v=mepAHvEa5p8",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1xQTc6sCMm-sqIz0rQRB6KC2DoQVVW409",
        date: "25 August 2020",
        category: "session",
        name: "Tips on how to get Google STEP internship",
        company: "Lean In IGDTUW",
        desc: "Want to know how to get into Google in second year? Know the tips on how to get Google STEP internship by Ananya Jain and Karishma Singh Chauhan.",
        link: "https://www.youtube.com/watch?v=QMPqNr-EaVk&t=787s",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1VqFcA5UbaR10cvHwqrb4vMD2g0evQVsT",
        date: "23 January 2021",
        category: "session",
        name: "Session on Women Techmakers Engineering Fellows Program",
        company: "Lean In IGDTUW",
        desc: "Know the tips to get the WTEF Scholarship by Sukriti Dawar and Aditi Tiwari.",
        link: "https://www.youtube.com/watch?v=fAxBF9CLhYA",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=138j0pgHEzpXqd72CZylDu06zdILP7i7G",
        date: "7 March 2021",
        category: "session",
        name: "Session on Generation Google Scholarship",
        company: "Lean In IGDTUW",
        desc: "Know the tips to get the Generation Google Scholarship by Apoorva Mittal and Pakhi Bansal.",
        link: "https://www.youtube.com/watch?v=gK_fwmJIPaM&t=4s",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=17LHEru2pYL4gHJC_ELuWAarzkgja7z2p",
        date: "15 September 2021",
        category: "session",
        name: "Session on 𝐌𝐢𝐭𝐚𝐜𝐬 𝐆𝐥𝐨𝐛𝐚𝐥𝐢𝐧𝐤 𝐑𝐞𝐬𝐞𝐚𝐫𝐜𝐡 𝐈𝐧𝐭𝐞𝐫𝐧𝐬𝐡𝐢𝐩",
        company: "Lean In IGDTUW",
        desc: "Know the tips to get the 𝐌𝐢𝐭𝐚𝐜𝐬 𝐆𝐥𝐨𝐛𝐚𝐥𝐢𝐧𝐤 𝐑𝐞𝐬𝐞𝐚𝐫𝐜𝐡 𝐈𝐧𝐭𝐞𝐫𝐧𝐬𝐡𝐢𝐩 by Muskan Lalit and Adya Bansal.",
        link: "https://www.youtube.com/watch?v=wOtwE1dYJd0",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1i6jnkRlB1TmE_42hR3RDX8uGs_QF9BuP",
        date: "10 October 2021",
        category: "session",
        name: "🔆Level Up Yourselves with Vision Mastermind🔆",
        company: "Lean In IGDTUW",
        desc: "Know the tips on time management by Vidhi Shah",
        link: "https://www.youtube.com/watch?v=RzSxyE-C4t0",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1NtEWuy-PSEhiaMv1nqkIYmF1EGK6etvj",
        date: "13 October 2021",
        category: "session",
        name: "Introduction to Open Source and Hacktoberfest 2021",
        company: "Lean In IGDTUW",
        desc: "Hands-on session on opensource along with a walkthrough of Hacktoberfest '21, a month-long celebration of open source software run by DigitalOcean.",
        link: "https://www.youtube.com/watch?v=SKXUFnaeOxA",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1C40fsNcbVaTf07zsUPtNQb9y_Wq0E74T",
        date: "28 November 2021",
        category: "session",
        name: "Career Journey From SDET To Product Manager",
        company: "Lean In IGDTUW",
        desc: "Insights on what it’s like to work in the corporate sector and how one can switch their career from being a Software Development Engineer to exploring the dynamic domain of Product Management.",
        link: "https://www.youtube.com/watch?v=Ip6PzT-o9VY",
    },
    {
        img: "https://drive.google.com/uc?export=view&id=1WAFGRsNhR9zRasFh3fUtx3VP62Z6Wx0V",
        date: "4 December 2021",
        category: "session",
        name: "APIs 101 with Postman",
        company: "Lean In IGDTUW",
        desc: "API literacy session with hands-on workshop with Postman.",
        link: "https://www.youtube.com/watch?v=KvkWRWtxe4Q",
    },
]

export default sessionsData;