import React from "react";
import "./opportunityTargetSVG.css";

export default function OpportunityTargetSVG() {
    return (
        <svg className="arrowHit" id="icon-target" xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88">
            <g id="target">
                <ellipse cx="37.9" cy="52.1" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" rx="28.7" ry="38.3" transform="rotate(-45.001 37.862 52.05)" />
                <ellipse cx="39.8" cy="50.1" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" rx="18" ry="23.9" transform="rotate(-45.001 39.778 50.136)" />
                <ellipse cx="41.7" cy="48.2" fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" rx="7.2" ry="9.6" transform="rotate(-45.001 41.693 48.22)" />
            </g>
            <g id="arrow">
                <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M43.6 46.3L62 28" />
                <path fill="none" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3" d="M69.4 27.1L62 28l.8-7.4L77.4 6.1l-.3 6.8 6.9-.3z" />
            </g>
        </svg>
    );
}