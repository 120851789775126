import React from "react";
import { useLocation } from "react-router";
import "./SingleHackathon.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/footer/Footer";
import Timeline from "../../components/timeline/Timeline";
import hackathonData from "../../Data/hackathonData";
import trophy from "./trophy.png";
import HSBar from "react-horizontal-stacked-bar-chart";
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import PushPinIcon from '@mui/icons-material/PushPin';
import Filter1Icon from '@mui/icons-material/Filter1';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupsIcon from '@mui/icons-material/Groups';
import Winners from "../../components/Winner/winner";
import TeamMemberCard from "../../components/TeamMember/TeamMemberCard";


export default function SingleHackathon() {
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://apply.devfolio.co/v2/sdk.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        }
    }, []);

    return (
        <>
            <Header />
            {hackathonData.map((hackathon) => {
                if (hackathon.id === path) {
                    let judges_len = hackathon.judges.length;
                    let criteria_len = hackathon.criteria.length;
                    let winners_len = hackathon.winners.length;
                    let prizes_len = hackathon.prizes.length;
                    let sponsors_len = hackathon.sponsors.length;
                    let organisers_len = hackathon.organisers.length;
                    let gold_len = hackathon.GoldSponsors.length;
                    let silver_len = hackathon.SilverSponsors.length;
                    let bronze_len = hackathon.BronzeSponsors.length;
                    

                    return (
                        <>
                            <div className="try">
                                <div className="hackathon-top-header flex">
                                    <div className="flex-left">
                                        <center>
                                            <img className="h-img" src={hackathon.img} alt="hackathon-img" />
                                        </center>
                                    </div>
                                    <div className="flex-right">
                                        <center>
                                            <h1 className="underline-txt hackathon-header-title">{hackathon.name}</h1>
                                            <p className="hackathon-header-title"><PushPinIcon className="hackathon-pin-icon" />
                                                <b> Duration: {hackathon.start_date} - {hackathon.end_date}</b>
                                            </p>
                                            <p className="hackathon-header-title">
                                                {(() => {
                                                    switch (hackathon.register_link) {
                                                        case 'closed':   return <Button variant="contained" color="error" style={{ cursor: "not-allowed" }}>
                                                                                    <span>Registration Closed</span>
                                                                                </Button>;
                                                        case 'opening soon': return <Button variant="contained" color="error" style={{ cursor: "not-allowed" }}>
                                                                                        <span>Registrations Opening Soon</span>
                                                                                    </Button>;
                                                        default:      return <div 
                                                                                class="apply-button" 
                                                                                data-hackathon-slug="leanin-hacks-4" 
                                                                                data-button-theme="light"
                                                                                style={{height: 44, width: 312}}
                                                                            ></div>;
                                                        // <a href={hackathon.register_link}>
                                                        //                         <Button variant="contained" color="error" endIcon={<SendIcon />}>
                                                        //                             <span>Register Now</span>
                                                        //                         </Button>
                                                        //                     </a>;
                                                    }
                                                })()}
                                            </p>
                                        </center>
                                    </div>
                                </div>
                                <div className="hscroll">
                                    <div class="scroll-container">
                                        <div class="scroller"></div>
                                    </div>
                                </div>
                                <div className="container-hackathon">
                                    <div className="hackathon-tabs">
                                        <a href="#about">
                                            <TextSnippetIcon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">About</span>
                                        </a>
                                        <a href="#timeline">
                                            <CalendarMonthIcon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">Timeline</span>
                                        </a>
                                        {prizes_len > 0 && (
                                        <a href="#prizes">
                                            <MilitaryTechIcon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">Prizes</span>
                                        </a>
                                        )}
                                        {((sponsors_len > 0) || (gold_len > 0) || (silver_len > 0) || (bronze_len > 0)) && (
                                        <a href="#sponsors">
                                            <HandshakeIcon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">Sponsors</span>
                                        </a>
                                        )}
                                        {organisers_len > 0 && (
                                        <a href="#organisers">
                                            <GroupsIcon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">Organisers</span>
                                        </a>
                                        )}
                                        <a href="#faq">
                                            <HelpOutlineIcon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">FAQ</span>
                                        </a>
                                        {judges_len > 0 && (
                                        <a href="#judges">
                                            <PersonOutlineIcon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">Judges</span>
                                        </a>
                                        )}
                                        {criteria_len > 0 && (
                                        <a href="#criteria">
                                            <Filter1Icon className="hackathon-nav-icon" />
                                            <span className="single-hackathon-tab-content">Criteria</span>
                                        </a>
                                        )}
                                        {winners_len > 0 && (
                                            <a href="#winner">
                                                <StarHalfIcon className="hackathon-nav-icon" />
                                                <span className="single-hackathon-tab-content">Winners</span>
                                            </a>
                                        )}
                                        <span className="glider"></span>
                                    </div>
                                </div>
                                <div className="content">

                                    <div id="about" className="hackathon-about">
                                        <h2 className="single-hackathon-heading heading-underline">About</h2>
                                        <p className="hackathon-subheading">Theme</p>
                                        <p className="hackathon-about-content">{hackathon.theme}</p>
                                        <p className="hackathon-subheading">Objective</p>
                                        <p className="hackathon-about-content">{hackathon.objective}</p>
                                    </div>
                                    <div id="timeline" className="timeline">
                                        <h2 className="single-hackathon-heading heading-underline">Timeline</h2>
                                        <Timeline timeline={hackathon.timeline} />
                                    </div>
                                    {/* {prizes_len > 0 && (
                                    <div id="prizes" className="prizes">
                                        <h2 className="single-hackathon-heading heading-underline">Prizes</h2>
                                        <div className="dates-cards">
                                            {hackathon.prizes.map((p) => {
                                                return (
                                                    <div className="hackathon-prizes">
                                                        <div className="hackathon-prizes-img-div">
                                                            <img className="hackathon-prizes-img" src={trophy} alt="hackathon-prizes-img" />
                                                        </div>
                                                        <div className="inner-div-card">
                                                            <p className="hackathon-card-title">{p.position}</p>
                                                            <p className="card-duration-para">{p.winning_cost}, {p.goodies[0]}, {p.goodies[1]}, and {p.goodies[2]}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    )} */}
                                    {prizes_len > 0 && (
                                    <div id="prizes" className="prizes">
                                        <h2 className="single-hackathon-heading heading-underline">Prizes</h2>
                                        <div className=" hackathon-prizes-cards">
                                            {hackathon.prizes.map((p) => {
                                                return (
                                                    <div className="hackathon-prizes-card-main-container" style={{height: 370, width: 300}}>
                                                        <div className="hackathon-prizes-card-img" style={{width: 200, height: 200, objectFit: 'cover'}}>
                                                            <img className="hackathon-prizes-img"   src={p.img} alt="hackathon-prizes-img" />
                                                        </div>
                                                        {/* <div className="inner-div-card"> */}
                                                            <div className="prizes-card-title"><h5>{p.name}</h5>
                                                            <div className="prizes-card-title-2">{p.theme}</div>
                                                            </div>
                                                            
                                                        {/* </div> */}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    )}
                                    
                                    {((sponsors_len > 0) || (gold_len > 0) || (silver_len > 0) || (bronze_len > 0)) && (
                                    <div id="sponsors" className="sponsors">
                                    <h2 className="single-hackathon-heading heading-underline">Sponsors</h2>
                                    {gold_len > 0 && (
                                        <div>
                                        <h2 className="sponsor-tier-heading">GOLD SPONSORS</h2>
                                            <div className="sponsors-cards">
                                            {hackathon.GoldSponsors.map((s) => {
                                                return (
                                                    <div className="sponsors-card-container grow">
                                                        <div className="flex">
                                                            <div className="flex-left">
                                                            <a href={s.link} target="_blank" rel="noreferrer">
                                                                <img className="sponsors-img" src={s.image} alt="sponsors-img" />
                                                            </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    )}
                                    {silver_len > 0 && (
                                        <div>
                                        <h2 className="sponsor-tier-heading">SILVER SPONSORS</h2>
                                            <div className="sponsors-cards">
                                            {hackathon.SilverSponsors.map((s) => {
                                                return (
                                                    <div className="sponsors-card-container grow">
                                                        <div className="flex">
                                                            <div className="flex-left">
                                                            <a href={s.link} target="_blank" rel="noreferrer">
                                                                <img className="sponsors-img" src={s.image} alt="sponsors-img" />
                                                            </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    )}
                                    {bronze_len > 0 && (
                                        <div>
                                        <h2 className="sponsor-tier-heading">BRONZE SPONSORS</h2>
                                            <div className="sponsors-cards">
                                            {hackathon.BronzeSponsors.map((s) => {
                                                return (
                                                    <div className="sponsors-card-container grow">
                                                        <div className="flex">
                                                            <div className="flex-left">
                                                            <a href={s.link} target="_blank" rel="noreferrer">
                                                                <img className="sponsors-img" src={s.image} alt="sponsors-img" />
                                                            </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    )}
                                    {sponsors_len > 0 && (
                                        <div>
                                            <div className="sponsors-cards">
                                            {hackathon.sponsors.map((s) => {
                                                return (
                                                    <div className="sponsors-card-container grow">
                                                        <div className="flex">
                                                            <div className="flex-left">
                                                            <a href={s.link} target="_blank" rel="noreferrer">
                                                                <img className="sponsors-img" src={s.image} alt="sponsors-img" />
                                                            </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                    )}
                                    {organisers_len > 0 && (
                                    <div id="organisers" className="organisers">
                                    <h2 className="single-hackathon-heading heading-underline">Organisers</h2>
                                    <>
                                    <TeamMemberCard item={hackathon.organisers}/>
                                    </>
                                    </div>
                                    )}
                                    <div id="faq" className="faq">
                                        <h2 className="single-hackathon-heading heading-underline">FAQ</h2>
                                        {hackathon.faq.map((p) => {
                                            return (
                                                <div className="Collapsible">
                                                    <Accordion style={{ backgroundColor: "var(--blog-background)", color: "var(--font-color)" }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon className="expand-hackathon-icon" />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>{p.ques}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                {p.ans}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {judges_len > 0 && (
                                    <div id="judges" className="judges">
                                        <h2 className="single-hackathon-heading heading-underline">Judges</h2>
                                        <div className="judges-cards">
                                            {hackathon.judges.map((j) => {
                                                return (
                                                    <div className="judges-card-container">
                                                        <div className="flex">
                                                            <div className="flex-left">
                                                                <img className="judges-img" src={j.img} alt="judges-img" />
                                                            </div>
                                                            <div className="flex-right judges-right-div">
                                                                <h2>{j.name}</h2>
                                                                <p>{j.designation}</p>
                                                                <p>{j.company}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    )}
                                    {criteria_len > 0 && (
                                    <div id="criteria" className="points-criteria">
                                        <h2 className="single-hackathon-heading heading-underline">Judging Criteria</h2>
                                        <HSBar
                                            height={40}
                                            showTextUp
                                            id="new_id"
                                            fontColor="rgb(50,20,100)"
                                            data={hackathon.criteria} />
                                    </div>
                                    )}
                                    {winners_len > 0 && (
                                    <Winners hackathon={hackathon} />
                                    )}
                                </div>
                            </div>
                        </>
                    )
                } else {
                    return <></>
                }
            })}
            <Footer />
            {/* <h1>Single Hackathon Page</h1> */}
        </>
    );
}
