import React from "react";
import "./home.css";
import Footer from "../../components/footer/Footer";
import Slider from "../../components/feedback-slider/Slider";
import TopCarousel from "../../components/top-carousel/TopCarousel";
import Header from "../../components/Header/Header";
import sliderFeedback from "../../Data/sliderFeedback";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default function Home() {

  return (
    <div className="home-main">
      <Header />
      <div className="background-sep1">
        {/* Top Carousel */}
        <div className="top-carousel">
          <TopCarousel />
        </div>

        {/* About section */}
        <div className="about">
          <div className="about-content-div">
            <h1 className="home-heading heading-underline">About Us</h1>
            <p>
              Lean In IGDTUW is the first Lean In Chapter in India, and among the first five chapters of the World.  Lean In IGDTUW with the vision “We rise by lifting others”, was established in 2014.  We introduce Circles for learning different Tech Stacks. These circles not just promote learning, but bridge the gap between the Seniors and Juniors on campus.
            </p>
            <hr style={{ margin: "15px 0" }} />
            <p>
              But that’s not it… We also conduct Events, Interview Sessions, where we invite accomplished speakers, Hackathons, and much more to celebrate the Amazing and Worthy women all around. Our vision is to encourage women of our university to believe in themselves, raise their hands, sit at the table and do what they would do if they weren’t afraid.
            </p>
          </div>

          {/* Can provide statistics of last year */}
          <div className="stats">
            <div className="stat-row">
              <div>
                <i class="fas fa-graduation-cap stat-icon"></i>
                {/* https://github.com/joshwnj/react-visibility-sensor */}
                <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                  {/* https://www.npmjs.com/package/react-countup */}
                  {({ isVisible }) => (
                    <p className="stat-count">{isVisible ? <CountUp end={700} /> : null}+</p>
                  )}
                </VisibilitySensor>
                <p>Mentees (each year)</p>
              </div>
              <div>
                <i className="fab fa-discord stat-icon"></i>
                <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                  {({ isVisible }) => (
                    <p className="stat-count">{isVisible ? <CountUp end={1100} /> : null}+</p>
                  )}
                </VisibilitySensor>
                <p>Discord Family</p>
              </div>
              <div>
                <i className="fas fa-users stat-icon"></i>
                <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                  {({ isVisible }) => (
                    <p className="stat-count">{isVisible ? <CountUp end={900} /> : null}+</p>
                  )}
                </VisibilitySensor>
                <p>Bulletin Subscribers</p>
              </div>
              <div>
                {/* <i class="fas fa-eye stat-icon"></i> */}
                <i class="fab fa-youtube stat-icon"></i>
                <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
                  {({ isVisible }) => (
                    <p className="stat-count">{isVisible ? <CountUp end={10000} /> : null}+</p>
                  )}
                </VisibilitySensor>
                <p>Youtube Views</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="background-sep2">
      {/* Mission Section */}
      <div className="mission">
        <h1 className="home-heading heading-underline">Mission</h1>
        <p className="mission-div">
          Our mission is to tap into the incredibly powerful repository of knowledge of our peers so that each one of us gets better equipped with the skillset needed to stand apart from the crowd.
          We strive to establish a legacy of women helping out one another and achieving all their dreams together. After all, there is no power greater than women coming together.
        </p>
      </div>
        {/* Feedback Section */}
        <div className="feedback">
          <h1 className="home-heading heading-underline">Feedback</h1>
          <Slider slides={sliderFeedback} />
          <div className="arrow-div"></div>
          <div className="circle6"></div>

        </div>
      </div>

      <div className="circle1"></div>
      <div className="circle2"></div>
      <div className="circle3"></div>
      <div className="circle4"></div>
      <div className="circle5"></div>
      <Footer />
    </div>
  );
}
