import "./Cards.css";
import Card from "./Card";


export default function Cards({ data }) {
  return (
    <div class="hackathon_cards">
      {data.map((c) => {
        return (
          <>
            <Card prop={c} />
          </>
        )
      })}
    </div>
  );
};
