const OpportunityDetails = [
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "30 Days of Google Cloud by Google",
        company: "Google",
        desc: "30 Days of Google Cloud program will provide you an opportunity to kickstart your career in cloud and get hands-on practice on Google Cloud - the tool that powers apps like Google Search, Gmail and YouTube. Along the way, you will learn & practice concepts like computing, application development, big data & machine learning using cloud.",
        link: "https://events.withgoogle.com/30daysofgooglecloud/#content",
    },
    {
        img: "https://blog.adobe.com/default-meta-image.png?width=1200&format=pjpg&optimize=medium",
        date: "",
        category: "competition",
        name: "Adobe Analytics Challenge",
        company: "Adobe",
        desc: "The Adobe Analytics Challenge is a unique analytics-focused business case competition where university students are given the opportunity to use Adobe’s industry-leading analytics products and access to real-world data from leading organizations.",
        link: "https://adobeanalyticschallenge.com/",
    },
    {
        img: "https://blog.adobe.com/default-meta-image.png?width=1200&format=pjpg&optimize=medium",
        date: "",
        category: "scholarship",
        name: "Adobe India Women-in-Technology Scholarship",
        company: "Adobe",
        desc: "Adobe India Women-in-Technology Scholarship is for Indian females enrolled in a formal technology program in computer science, computer engineering, or a closely related technical field. The scholarship offered by Adobe Research aims to recognize outstanding female students in the field of technology.",
        link: "https://research.adobe.com/adobe-india-women-in-technology-scholarship/",
    },
    {
        img: "https://images.unsplash.com/photo-1624521793559-136bfe16fc86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1700&q=800",
        date: "",
        category: "competitons",
        name: "Alexa Prize by Amazon",
        company: "Amazon",
        desc: "The Alexa Prize, launched in 2016, is a competition for university students dedicated to advancing the field of conversational AI. Teams are challenged to design socialbots that Alexa customers can interact with via Alexa-enabled devices. ",
        link: "https://www.amazon.science/alexa-prize",
    },
    {
        img: "https://images.unsplash.com/photo-1624521793559-136bfe16fc86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1700&q=800",
        date: "",
        category: "Program",
        name: "Amazon WOW",
        company: "Amazon",
        desc: "Amazon WoW is a networking platform for all women engineering students in India that connects them to Amazon leaders, recruiters, and the broader Amazon community. Amazon WoW is open to all women students across engineering campuses in India.",
        link: "https://amazonwowindia.splashthat.com/",
    },
    {
        img: "https://images.livemint.com/img/2022/05/16/1600x900/Screenshot_2022-05-16_at_5.27.46_PM_1652702278338_1652702290176.png",
        date: "",
        category: "competition",
        name: "Capgemini Tech Challenge",
        company: "Capgemini",
        desc: "Capgemini Tech Challenge is one of the largest hackathons in India. Over 7.5 Lakh people have been a part of this challenge in the last seven years, 35% of them being women. Last year, only the finest lot of the registered participants made it to the finale.",
        link: "https://techchallenge.in.capgemini.com/",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "Code Jam by Google",
        company: "Google",
        desc: "Google Code Jam is an international programming competition hosted and administered by Google. The competition consists of a set of algorithmic problems which must be solved in a fixed amount of time. Competitors may use any programming language and development environment to obtain their solutions.",
        link: "https://codingcompetitions.withgoogle.com/codejam",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "Code Jam to I/O for Women",
        company: "Google",
        desc: "Code Jam to I/O for Women is one way to bring women (students and professionals) from around the globe together, working to solve tough algorithmic challenges in a 2.5 hour, single-round coding competition. The top 150 on the scoreboard will receive a ticket and a stipend to participate in virtual Google I/O.",
        link: "https://codingcompetitions.withgoogle.com/codejamio",
    },
    {
        img: "https://www.insightssuccess.in/wp-content/uploads/2018/07/TECHGIG.jpg",
        date: "",
        category: "competition",
        name: "Code Gladiators",
        company: "TechGig",
        desc: "Code Gladiators is a TechGig hackathon presented by Cognizant. This hackathon continues for 4 months where an individual can write, compile and run code in a live coding environment provided by the application. The total prize pool at Code Gladiators worths Rs 50 Lakh. ",
        link: "https://www.techgig.com/codegladiators",
    },
    {
        img: "https://images.yourstory.com/cs/images/companies/codechef-1592821643695.jpg?fm=auto&ar=1:1&mode=fill&fill=solid&fill-color=fff",
        date: "",
        category: "competition",
        name: "Codechef SnackDown",
        company: "CodeChef",
        desc: "SnackDown is a global programming event that invites programmers from all over the world to take part in India’s most prestigious multi-round programming competition. Hosted by CodeChef, SnackDown is open to anyone with a knack for programming and began in the year 2010.  ",
        link: "https://snackdown.codechef.com/",
    },
    {
        img: "https://media.geeksforgeeks.org/wp-content/uploads/20210101144014/gfglogo.png",
        date: "",
        category: "competition",
        name: "Coderita 2.0 by GeeksforGeeks",
        company: "GeeksforGeeks",
        desc: "Coderita 2.0 is a 1- Day online coding contest that is designed only for the female coders (Yes! only for Women) to appreciate their programming skills. Every year this contest helds on Women's Day.",
        link: "https://www.geeksforgeeks.org/coderita-online-coding-competition-for-women-by-geeksforgeeks/",
    },
    {
        img: "https://cdn-images-1.medium.com/fit/t/1600/480/1*NK2hmuq_GaccMB-mA_6pyw.jpeg",
        date: "11 May 2022",
        category: "competition",
        name: "Code for Good",
        company: "JPMorgan",
        desc: "Use your coding skills to make a difference! In Code for Good hackathon, you'll collaborate with other coders to develop innovative technology solutions for not-for-profit organizations. You'll also learn about starting a Technology career with JP Morgan while being guided by the sharpest minds in their industry.",
        link: "https://careers.jpmorgan.com/us/en/students/programs/code-for-good",
    },
    {
        img: "https://blog.adobe.com/default-meta-image.png?width=1200&format=pjpg&optimize=medium",
        date: "",
        category: "competition",
        name: "Codiva by Adobe",
        company: "Adobe",
        desc: "Codiva is a hackathon conducted by Adobe exclusively for women. Students who are in their final or pre-final years can be a part of this. The top-performing candidates are eligible for an interview for either an internship or full-time employment. ",
        link: "https://www.hackerrank.com/adobe-codiva/",
    },
    {
        img: "https://images.yourstory.com/cs/images/companies/codechef-1592821643695.jpg?fm=auto&ar=1:1&mode=fill&fill=solid&fill-color=fff",
        date: "",
        category: "competition",
        name: "Cook-off by CodeChef",
        company: "CodeChef",
        desc: "Cook-off is an amazing 2.5 hours long coding competition held by Codechef every month. This competition helps improve your analytical and problem solving skills.",
        link: "https://www.codechef.com/contests/",
    },
    {
        img: "https://www.edgeforwomen.org/wp/wp-content/uploads/2021/05/sponsorlogo_deshaw.png",
        date: "",
        category: "Program",
        name: "DESIS ASCEND EDUCARE",
        company: "D.E. Shaw",
        desc: "It is a program for women in tech that focuses on helping participants enhance their skills from D.E. Shaw Private Limited. Those who take part can receive mentorship in various technical and non-technical areas from subject matter experts, and get first-hand industry exposure, networking opportunities, and much more.",
        link: "https://www.deshawindia.com/desis-ascend-educare/about.pdf",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "DevFest India by Google Developers Group",
        company: "Google",
        desc: "DevFest India is the annual developer’s fest organized by Google Developers Group. While learning about different technologies at DevFest India 2021 and getting job-ready, you can enlighten your mind with flash talks, create your profile for peer learning, make your own badges, and win exciting gifts!",
        link: "https://devfestindia.com/",
    },
    {
        img: "https://st1.latestly.com/wp-content/uploads/2022/05/IIT-Bombay-784x441.jpg",
        date: "",
        category: "competition",
        name: "e-Yantra Robotic Competition",
        company: "IIT Bombay",
        desc: "The e-Yantra Robotics Competition (eYRC) is the flagship initiative of the e-Yantra project. The competition is open to students from an engineering or polytechnic background and comprises 2 stages spanning over 6-7 months.",
        link: "https://portal.e-yantra.org/",
    },
    {
        img: "https://www.medianews4u.com/wp-content/uploads/2021/12/Tier-3-customers-vernacular-language-tech-and-hyperlocal-services-are-driving-e-commerce-growth-in.jpg",
        date: "",
        category: "competition",
        name: "Flipkart GRiD",
        company: "Flipkart",
        desc: "GRiD is Flipkart’s Flagship Engineering Campus Challenge which provides you the opportunity to apply your technical knowledge and skills, to compete and complete key challenges.It brings Live Problem Statements from the world of E-Commerce.",
        link: "https://unstop.com/festival/flipkart-grid-30-flipkart-12310",
    },
    {
        img: "https://www.medianews4u.com/wp-content/uploads/2021/12/Tier-3-customers-vernacular-language-tech-and-hyperlocal-services-are-driving-e-commerce-growth-in.jpg",
        date: "",
        category: "Program",
        name: "Flipkart Runway",
        company: "Flipkart",
        desc: "Flipkart Runway is Flipkart's early career program for women engineers. It aims at laying an early foundation for women in engineering by giving them early exposure to developmental opportunities through challenging & real-time problem statements, mentoring, and networking opportunities.",
        link: "https://unstop.com/festival/flipkart-grid-30-flipkart-12310",
    },
    {
        img: "https://www.noticebard.com/wp-content/uploads/2021/10/Generation-Google-Scholarship.jpg",
        date: "17 December 2022",
        category: "scholarship",
        name: "Generation Google Scholarship(APAC)",
        company: "Google",
        desc: "Generation Google Scholarship: for Women in computer science was established to help students pursuing computer science degrees excel in technology and become leaders in the field. Selected students receives $1,000 USD for the respected school year.",
        link: "https://buildyourfuture.withgoogle.com/scholarships/generation-google-scholarship-apac",
    },
    {
        img: "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210406180435/What-is-GirlScript-Summer-of-Code-and-How-to-Participate.png",
        date: "",
        category: "openSource",
        name: "GirlScript Summer of Code",
        company: "GirlScript",
        desc: "GirlScript Summer of Code is the 3 month long Open Source program during summers conducted by GirlScript Foundation, started in 2018, with an aim to help beginners get started with Open Source Development while encouraging diversity. Top participants get exciting goodies and opportunities.",
        link: "https://gssoc.girlscript.tech/",
    },
    {
        img: "https://gwoc.girlscript.tech/assets/home-design.png",
        date: "",
        category: "openSource",
        name: "GirlScript Winter of Contributing",
        company: "GirlScript",
        desc: "GirlScript Winter of Contributing is a three-month newly established initiative by GirlScript Foundation to be conducted during winters. GWOC encourages individuals to share their knowledge and ideas to develop technical skills and gain valuable experience in the field of tech education. ",
        link: "https://gwoc.girlscript.tech/",
    },
    {
        img: "https://github.blog/wp-content/uploads/2016/06/20381f30-3860-11e6-8c19-15670c871644.png",
        date: "",
        category: "Program",
        name: "Github Campus Expert",
        company: "Github",
        desc: "Campus Experts are student leaders that strive to build diverse and inclusive spaces to learn skills, share their experiences, and build projects together. They can be found across the globe leading in-person and online conferences, meetups, and hackathons, and maintaining open source projects.",
        link: "https://education.github.com/experts",
    },
    {
        img: "https://www.temenos.com/wp-content/uploads/2019/07/client-credit-suisse-landscape-image-2019-jul-12.jpg",
        date: "",
        category: "competition",
        name: "Global Coding Challenge",
        company: "Credit Suisse",
        desc: "The Global Coding Challenge is an online coding competition between participants across the globe. Around 3 weeks, users will be able to attempt solutions to nine coding problems. Participants can improve their code as many times as they like during the competition. ",
        link: "https://www.credit-suisse.com/pwp/hr/en/codingchallenge/#/",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "Program",
        name: "GoogleCloudReady Facilitator Program",
        company: "Google",
        desc: "The GoogleCloudReady Facilitator program will provide you an opportunity to kickstart your career in cloud and get hands on practice on Google Cloud - the tool that powers apps like Google Search, Gmail and YouTube.",
        link: "https://events.withgoogle.com/googlecloudready-facilitator-program/",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "Google Capture The Flag",
        company: "Google",
        desc: "The world’s best cyber hacking competition GoogleCTF. This contest consists of two events where the first event is a qualifier round. Here they cover a wide area of security exercises to test the security skills of the participants. The top 10 teams invited to the finals, compete onsite for a prize pool of more than USD $31,337.",
        link: "https://capturetheflag.withgoogle.com/",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "Program",
        name: "Google Developer Student Clubs",
        company: "Google",
        desc: "Helping students bridge the gap between theory and practice Google Developer Student Clubs are community groups for college and university students interested in Google developer technologies. Students from all undergraduate or graduate programs with an interest in growing as a developer are welcome.",
        link: "https://developers.google.com/community/gdsc",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "Internship",
        name: "Google STEP Internship",
        company: "Google",
        desc: "STEP (Student Training in Engineering Program), formerly known as Engineering Practicum, is a 12-week internship for first and second-year undergraduate students with a passion for computer science.",
        link: "https://buildyourfuture.withgoogle.com/programs/step",
    },
    {
        img: "https://miro.medium.com/max/800/1*ORXgIa-wjtulBmdiXlmDzA.png",
        date: "7 February 2022",
        category: "openSource",
        name: "Google summer of code",
        company: "Google",
        desc: "Google Summer of Code is a global, online program focused on bringing new contributors into open source software development. GSoC Contributors work with an open source organization on a 12+ week programming project under the guidance of mentors.",
        link: "https://summerofcode.withgoogle.com/",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "Internship",
        name: "Google SWE Internship",
        company: "Google",
        desc: "This is 12-13 weeks internship, wherein students work alongside other STEP interns on a specific SWE project, attend skills-based and professional development training and are mentored by Google engineers.",
        link: "https://buildyourfuture.withgoogle.com/internships",
    },
    {
        img: "https://newsroom.unl.edu/announce/files/file163724.png",
        date: "20 March 2022",
        category: "scholarship",
        name: "Grace Hopper Celebration",
        company: "AnitaB.org",
        desc: "AnitaB.org produces Grace Hopper Celebration (GHC) incollaboration with the Association for ComputingMachinery (ACM). GHC is the largest gathering of women in the world and non-binary technologists. Those who attend GHC gain access to career and academic workshops, inspirtional role models and memories that will last a lifetime!",
        link: "https://ghc.anitab.org/attend/scholarships/academics/",
    },
    {
        img: "https://logowik.com/content/uploads/images/994_facebook.jpg",
        date: "",
        category: "competition",
        name: "HackerCup",
        company: "Facebook",
        desc: "Hacker Cup is Facebook's annual open programming competition. Open to participants around the world, participants are invited to apply problem-solving and algorithmic coding skills to advance through each year’s online rounds, win prizes, and have a chance to make it to the global finals and win the grand prize.",
        link: "https://www.facebook.com/codingcompetitions/hacker-cup",
    },
    {
        img: "https://i.pcmag.com/imagery/reviews/06IGOgIipf2dmkiXBsUunYT-7..v1569474162.jpg",
        date: "",
        category: "openSource",
        name: "Hacktoberfest",
        company: "DigitalOcean",
        desc: "Hacktoberfest is the month-long event held in October, to celebrate open source software. For the past seven years DigitalOcean along with its partners (including DEV) has run the campaign to promote opensource.",
        link: "https://hacktoberfest.digitalocean.com/",
    },
    {
        img: "https://thebengalurulive.com/wp-content/uploads/2020/10/106713296_10163764764320481_7752686091122400611_n.png",
        date: "",
        category: "competition",
        name: "HackwithInfy",
        company: "Infosys",
        desc: "HackWithInfy is a coding competition for final-year engineering students across India. This program is specially designed to inculcate a culture of rapid problem-solving and innovative thinking early in academic life.",
        link: "https://www.infosys.com/careers/hackwithinfy.html",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "Hash Code",
        company: "Google",
        desc: "Hash Code is a team programming competition, organized by Google, for students and professionals around the world. You pick your team and programming language and we pick an engineering problem for you to solve. Top teams will then be invited to compete from our virtual World Finals.",
        link: "https://codingcompetitions.withgoogle.com/hashcode",
    },
    {
        img: "https://images.unsplash.com/photo-1640763502425-7668dc1e4023?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "Imagine Cup",
        company: "Microsoft",
        desc: "Imagine Cup is an annual competition sponsored and hosted by Microsoft Corp. It is considered as \"Olympics of Technology\" by computer science and engineering and is considered one of the top competitions and awards related to technology and software design.",
        link: "https://imaginecup.microsoft.com/en-us/Events",
    },
    {
        img: "https://cdn.rit.edu/images/news/2019-11/ICPC.jpg",
        date: "",
        category: "competition",
        name: "International Collegiate Programming Contest",
        company: "icpc.foundation",
        desc: "The International Collegiate Programming Contest is an algorithmic programming contest for college students. Teams of three, representing their university, work to solve the most real-world problems, fostering collaboration, creativity, innovation, and the ability to perform under pressure.",
        link: "https://icpc.global/",
    },
    {
        img: "https://crispidea.com/wp-content/uploads/edd/2021/07/intuit.png",
        date: "",
        category: "Program",
        name: "Intuit Augment",
        company: "Intuit",
        desc: "Intuit is inviting young women technologists to be part of a mentorship program. This is in line with our commitment to power prosperity amongst young engineers to be industry-ready with guidance from domain experts.",
        link: "https://www.surveymonkey.com/r/INTUIT_2020",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "Community",
        name: "Kaggle",
        company: "Google",
        desc: "Kaggle is an online community of data scientists and machine learning practitioners. Kaggle allows users to find and publish data sets, explore and build models in a web-based data-science environment, etc.",
        link: "https://www.kaggle.com/",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "Kick Start",
        company: "Google",
        desc: "Kick Start is a global online coding competition, consisting of three-hour rounds of a variety of algorithmic challenges designed by Google engineers. Participants can compete in one or all online rounds held throughout the year.",
        link: "https://codingcompetitions.withgoogle.com/kickstart",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "Program",
        name: "Learn to Earn Cloud Challenge",
        company: "Google",
        desc: "To get hands-on practice with core cloud concepts. Build your skills with the Learn to Earn Cloud Challenge. You'll get free access to 32+ hands-on labs, plus an opportunity to earn prizes, grow your network, and showcase your skills.",
        link: "https://go.qwiklabs.com/learn-to-earn",
    },
    {
        img: "https://www.inovex.de/wp-content/uploads/2020/12/communities-linux-foundation.png",
        date: "",
        category: "scholarship",
        name: "LiFT Scholar!",
        company: "The Linux Foundation",
        desc: "The Linux Foundation Training (LiFT) Scholarship Program to provide opportunities to up-and-coming developers and sysadmins who show promise for helping shape the future of Linux and open source software but do not otherwise have the ability to attend training courses or take certification exams.",
        link: "https://linuxfoundation.org/diversity-inclusivity/lift-scholarships/",
    },
    {
        img: "https://images.yourstory.com/cs/images/companies/codechef-1592821643695.jpg?fm=auto&ar=1:1&mode=fill&fill=solid&fill-color=fff",
        date: "",
        category: "competition",
        name: "Lunchtime",
        company: "Codechef",
        desc: "Lunchtime is an amazing 3 hours long coding competition held at the end of every month to help in improving your analytical and problem solving skills.",
        link: "https://www.codechef.com/contests/",
    },
    {
        img: "https://avatars.githubusercontent.com/u/5752708?s=280&v=4",
        date: "11 May 2022",
        category: "openSource",
        name: "Major League Hacking",
        company: "MLH",
        desc: "MLH is the official student hackathon league. Each year, over 200 weekend-long invention competitions are held that inspire innovation, cultivate communities and teach computer science skills to more than 65,000 students around the world.",
        link: "https://mlh.io/",
    },
    {
        img: "https://newsroom.ibm.com/image/IBM+Logo_Social.jpg",
        date: "",
        category: "competition",
        name: "Master the Mainframe",
        company: "IBM",
        desc: "Master the Mainframe is the world’s largest student mainframe competition. This unique, virtual contest is open globally to high school and college students to progressively teach mainframe skills in a real-world enterprise computing environment.",
        link: "https://www.ibm.com/it-infrastructure/z/education/zxplore",
    },
    {
        img: "https://images.unsplash.com/photo-1640763502425-7668dc1e4023?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "Community",
        name: "Microsoft Codess",
        company: "Microsoft",
        desc: "Codess is a community for female coders initiated by Microsoft. Codess was established to explore ways to promote gender diversity in the engineering field.Codess aims to inspire female coders and help them achieve their professional goals. Through networking events, mentoring and sharing advice and experiences.",
        link: "https://careers.microsoft.com/us/en/codess",
    },
    {
        img: "https://i0.wp.com/tnpofficer.com/wp-content/uploads/2022/04/Microsoft.png?resize=500%2C300&ssl=1",
        date: "21 December 2022",
        category: "competition",
        name: "Microsoft Engage",
        company: "Microsoft",
        desc: "Intern Engage is a program for engineering students across India in their second year. It is designed to provide a platform for engineering students to accelerate their growth and foster industry-relevant skills through mentorship, coding challenges, workshops and networking opportunities.",
        link: "https://acehacker.com/microsoft/engage2022/",
    },
    {
        img: "https://miro.medium.com/max/450/1*-iWcUbquAdt1uQZahiuDIg.jpeg",
        date: "11 May 2022",
        category: "scholarship",
        name: "Microsoft Learn Student Ambassador",
        company: "Microsoft",
        desc: "The Microsoft Learn Student Ambassadors program is aglobal group of on-campus ambassadors who are eager tohelp students and their communities, lead in their localtech circles, and develop technical and career skills for thefuture.",
        link: "https://studentambassadors.microsoft.com/",
    },
    {
        img: "https://pbs.twimg.com/profile_images/462361481602818048/AJ4xWbsF_400x400.png",
        date: "",
        category: "Internship",
        name: "Mitacs Globalink Research Internship",
        company: "Mitacs",
        desc: "Mitacs Globalink Research Internship is a competitive initiative for international undergraduates from the following countries and regions: Australia, Brazil, China, Colombia, France, Germany, Hong Kong, India, Mexico, Pakistan, Taiwan, Tunisia, Ukraine, United Kingdom and the United States.",
        link: "https://www.mitacs.ca/en/programs/globalink/globalink-research-internship",
    },
    {
        img: "https://avatars.githubusercontent.com/u/5752708?s=280&v=4",
        date: "",
        category: "Internship",
        name: "MLH Fellowship",
        company: "MLH",
        desc: "Major League Hacking Fellowship is a 12-week remote program, where students can participate from their homes. This program has options for full-time and part-time participation. Full time requires 30-40 hours per week whereas, part-time requires 10-20 hours per week.",
        link: "https://fellowship.mlh.io/",
    },
    {
        img: "https://pixelbag.net/wp-content/uploads/2022/02/myntra-logo-768x768.jpg",
        date: "",
        category: "competition",
        name: "Myntra HackerRamp: WeForShe",
        company: "Myntra",
        desc: "Myntra HackerRamp is an initiative by Myntra which extends an opportunity for all women coders from engineering colleges across the country to create and innovate through technology. This challenge is conducted in 4 phases. The first being the ideation phase, second being the implementation phase ,third being the Pre- Finale followed by the Finale.",
        link: "https://dare2compete.com/hackathon/myntra-hackerramp-weforshe-myntra-151942",
    },
    {
        img: "https://saudiarabia.governmentjobsak.com/wp-content/uploads/2022/05/Nutanix-Careers.png",
        date: "11 May 2022",
        category: "scholarship",
        name: "Nutanix.heart WIT",
        company: "Nutanix",
        desc: "Nutanix believes in the power of diversity and realizes that a key component to driving innovation is cultivating diverse teams. They offer up to eleven scholarships to students in the United States, India, United Kingdom, Serbia and Australia who want to apply their passion for technology.",
        link: "https://www.nutanix.com/scholarships",
    },
    {
        img: "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210414121548/Outreachy-Internship-Program.png",
        date: "11 May 2022",
        category: "Internship",
        name: "Outreachy",
        company: "Outreachy",
        desc: "Outreachy provides internships in open source to people subject to systemic bias and impacted byunderrepresentation in the technical industry where theyare living. Outreachy interns work with experiencedmentors from open source communities.",
        link: "https://www.outreachy.org/",
    },
    {
        img: "https://i0.wp.com/tnpofficer.com/wp-content/uploads/2022/04/Microsoft.png?resize=500%2C300&ssl=1",
        date: "",
        category: "Internship",
        name: "Research Intern",
        company: "Microsoft",
        desc: "During the internship, students are paired with mentors and expected to collaborate with other interns and researchers, present findings, and contribute to the vibrant life of the community. MSR India conducts research in a wide range of areas and Internships are available in all of them.",
        link: "https://careers.microsoft.com/us/en/job/931311/Research-Intern",
    },
    {
        img: "https://static.theprint.in/wp-content/uploads/2022/03/Ministry_of_Chemicals_and_Fertilizerskshndcjz_OwkAfZl_0bALQby20211222151337_12_PsIFnMO.jpg",
        date: "",
        category: "competition",
        name: "Smart India Hackthon",
        company: "Govt. of India",
        desc: "Smart India Hackathon is a nationwide initiative to provide students a platform to solve some of pressing problems we face in our daily lives, and thus inculcate a culture of product innovation and a mindset of problem solving. The last edition of the hackathon saw over 5 million+ students from various engineering colleges compete for the top prize at 35+ locations.",
        link: "https://www.sih.gov.in/",
    },
    {
        img: "https://images.unsplash.com/photo-1624521793559-136bfe16fc86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1700&q=800",
        date: "",
        category: "Internship",
        name: "Software Development Engineer Intern",
        company: "Amazon",
        desc: "As an intern, you will be matched to a manager and a mentor. Your design, code, and raw smarts will contribute to solving some of the most complex technical challenges in the areas of distributed systems, data mining, automation, optimization, scalability, and security – just to name a few.",
        link: "https://www.amazon.jobs/en-gb/jobs/1227693/software-development-engineer-intern",
    },
    {
        img: "https://images.unsplash.com/photo-1529612700005-e35377bf1415?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
        date: "",
        category: "competition",
        name: "Solution Challenge",
        company: "Google",
        desc: "The mission of the 2022 Solution Challenge is to solve for one or more of the United Nations 17 Sustainable Development Goals using Google technology. The Solution Challenge is open to members of Google Developer Student Clubs.",
        link: "https://developers.google.com/community/gdsc-solution-challenge",
    },
    {
        img: "https://mma.prnewswire.com/media/1477373/TCS_Logo.jpg?p=facebook",
        date: "",
        category: "competition",
        name: "TCS CodeVita",
        company: "TCS",
        desc: "TCS CodeVita, the largest global computer programming competition, is a 24-hour online programming contest where a participant can log in from anywhere, any time. Coding enthusiasts can sharpen their programming skills through a series of intriguing real-life challenges across a stretch of 3 Rounds.",
        link: "https://www.tcscodevita.com/",
    },
    {
        img: "https://i02.appmifile.com/228_bbs_en/31/03/2021/0765c7a902.jpg",
        date: "",
        category: "Internship",
        name: "Test Intern",
        company: "Xiaomi",
        desc: " Provide technical support on project testing around the preparation of test coverage, scripts preparationWork on defect template setup, requirement traceability configuration, and any other configuration requirements around the testingWork on defect life cycle management within the project",
        link: "https://xiaomi.app.param.ai/jobs/test-intern-880/",
    },
    {
        img: "https://i.ytimg.com/vi/L86sCLr9zUo/maxresdefault.jpg?v=61805406",
        date: "",
        category: "competition",
        name: "TopCoder Open",
        company: "TCO21",
        desc: "The Topcoder Open (TCO) is an annual online and onsite tournament to celebrate and reward the community. TCO encompasses every day competitions at Topcoder and adds points to them. While you’re competing, you’re earning TCO points and more potential prizes as well as trips to the TCO Finals held in the United States!",
        link: "https://tco21.topcoder.com/",
    },
    {
        img: "https://images.unsplash.com/photo-1615929361868-2e41ea1befaf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
        date: "",
        category: "competition",
        name: "Uber HackTag",
        company: "Uber",
        desc: "Uber HackTag is organized by Uber Technologies. In this competition both students and professionals can participate. Uber HackTag is open to all working professionals and engineering students pursuing a full-time B.Tech./ B.E./ M.Tech./ M.E. (all years) degrees across branches.",
        link: "https://unstop.com/competition/uber-hacktag-uber-technologies-inc-148162",
    },
    {
        img: "https://pbs.twimg.com/profile_images/1382687864773820416/J0tArhv7_400x400.jpg",
        date: "",
        category: "Program",
        name: "Uplift Project",
        company: "GirlScript Foundation",
        desc: "The Uplift Project is a global-remote initiative by GirlScript Foundation. For people worldwide who want to talk about something fruitful. The program created to help people who cannot find like-minded friends or mentors. It aims to help everyone from English learning to Machine Learning.",
        link: "https://uplift.girlscript.tech/",
    },
    {
        img: "https://www.highereducationdigest.com/wp-content/uploads/2021/03/Women-Engineers-Talent-Sprint-550x330.jpg",
        date: "20 February 2022",
        category: "scholarship",
        name: "Women Engineer Scholarship",
        company: "Google",
        desc: "WE offered by TalentSprint and supported by Google is a two year experiential interactive online program thatselects, trains and nurtures capable first year womenengineering students to reach their true potential as worldclass software engineers.",
        link: "https://we.talentsprint.com/",
    },   
]

export default OpportunityDetails;