import React from "react";
import "./circles.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import CircleInfo from "../../Data/CircleInfo";

export default function Circles() {

  return (
    <div>
      <Header />
      <div className="circles-main-div">
        <h1 className="leanIn-circles underline-text">LeanIn Circles</h1>
        <p className="circles-content">Lean In Circles are a place where women can be unapologetically ambitious. Where mentees can give voice to their aspirations and find the encouragement to start pursuing them. Where mentors can enhance their leadership skills, practice interpersonal skills and get challenged. Circles are a place for sharing ideas, gaining skills, seeking advice, and showing solidarity. Mentees and mentors work together to overcome fear, move into action, and take risks. Most of all, they’re a place where students can help each other become their very best selves. 
          <br />
          Lean In IGDTUW currently boosts 8 active technical circles, namely DSA, AR/VR, IP, WebD, AppD, IOT, ML and Basic Coding, with 700+ mentees every year.
          <br />
          <div class="flash">
            <i class="fas fa-fw fa-arrow-left fa-2x circles-arrow"></i>
          </div>
        </p>
      </div>
      <div className="Circles-div">
        {CircleInfo.map((c) => {
          if (c.id === '-1') {
            return (
              <div className="active-circle disappear" style={{ backgroundColor: "var(--inactive-circle)", cursor: "not-allowed", border: "2px solid #f1f3de" }}></div>
            )
          } else {
            return (
              <div className="active-circle">
                <Link className="link" to={`/singleCircle/${c.id}`}>
                  <p className="circle-name">{c.name}</p>
                  {/* <p className="circle-name">{c.id}</p> */}
                </Link>
              </div>
            )
          }
        })}
      </div>
    </div>
  );
}